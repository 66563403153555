import { useState } from 'react'
import { Button, FormGroup, Input, Modal, ModalBody } from 'reactstrap'

const ModalReceive = ({ isOpen, toggle, handleSubmit }) => {
  const [isCheck, setIsCheck] = useState(false)

  return (
    <>
      <Modal className="p-5" isOpen={isOpen} toggle={toggle} centered>
        <ModalBody>
          <div className="w-100">
            <h6 className="mb-3 fw-bold">
              Transaksi untuk barang yang sudah diterima
            </h6>
            <p>
              Setelah penjual melakukan konfirmasi, transaksi akan selesai
              otomatis. Anda tidak perlu melakukan konfirmasi pesanan lagi.
            </p>
            <FormGroup>
              <Input
                type="checkbox"
                onChange={() => setIsCheck(!isCheck)}
                checked={isCheck}
                className="me-2"
              />
              Jangan tampilkan lagi
            </FormGroup>
          </div>

          <div className="d-flex justify-content-between gap-3 w-100">
            <Button
              onClick={() => {
                handleSubmit()
                toggle()
                if (isCheck) {
                  localStorage.setItem('PMB_modalReceived', isCheck)
                }
              }}
              block
              color="primary"
              type="submit"
            >
              Saya Mengerti
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModalReceive
