import { createAppSlice } from 'app/createAppSlice'
import { getDetailPayable, getPendingPayable } from './payableAction'

const initialState = {
  isOpenBanner: false,
  isOpenSidebar: false,
  tag: {
    size: 10,
    page: 0,
  },
  isOpenModalCategory: false,
  isOpenModalDelete: false,
  isOpenModalAdjust: false,
  detail: null,
  loading: false,
  isOpenModalContacts: false,
  isOpenModalAdjustContacts: false,
  isOpenModalDeleteContact: false,
  isOpenModalConfirm: false,
  bannerMsg: '',
  pendingPayable: [],
}

export const payableNotes = createAppSlice({
  name: 'payableNotes',
  initialState,
  reducers: (create) => ({
    toggleBanner: create.reducer((state) => {
      state.isOpenBanner = !state.isOpenBanner
    }),
    toggleSidebar: create.reducer((state) => {
      state.isOpenSidebar = !state.isOpenSidebar
    }),
    toggleModalCategory: create.reducer((state) => {
      state.isOpenModalCategory = !state.isOpenModalCategory
    }),
    toggleModalDelete: create.reducer((state) => {
      state.isOpenModalDelete = !state.isOpenModalDelete
    }),
    toggleModalAdjust: create.reducer((state) => {
      state.isOpenModalAdjust = !state.isOpenModalAdjust
    }),
    toggleModalContacts: create.reducer((state) => {
      state.isOpenModalContacts = !state.isOpenModalContacts
    }),
    toggleModalAdjustContacts: create.reducer((state) => {
      state.isOpenModalAdjustContacts = !state.isOpenModalAdjustContacts
    }),
    toggleModalDeleteContact: create.reducer((state) => {
      state.isOpenModalDeleteContact = !state.isOpenModalDeleteContact
    }),
    toggleModalConfirm: create.reducer((state) => {
      state.isOpenModalConfirm = !state.isOpenModalConfirm
    }),
    setBannerMsg: create.reducer((state, { payload }) => {
      state.bannerMsg = payload
    }),
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size
      state.tag.page = payload.page
    }),
  }),
  extraReducers: (builder) => {
    builder
      .addCase(getDetailPayable.pending, (state) => {
        state.loading = true
      })
      .addCase(getDetailPayable.fulfilled, (state, { payload }) => {
        state.loading = false
        state.detail = payload
      })
      .addCase(getDetailPayable.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(getPendingPayable.pending, (state) => {
        state.loading = true
      })
      .addCase(getPendingPayable.fulfilled, (state, { payload }) => {
        state.loading = false
        state.pendingPayable = payload
      })
      .addCase(getPendingPayable.rejected, (state, { payload }) => {
        state.loading = false
      })
  },
})

export const {
  toggleBanner,
  toggleSidebar,
  setFetchOption,
  toggleModalCategory,
  toggleModalDelete,
  toggleModalAdjust,
  toggleModalContacts,
  toggleModalAdjustContacts,
  toggleModalDeleteContact,
  toggleModalConfirm,
  setBannerMsg,
} = payableNotes.actions

export default payableNotes.reducer
