/* eslint-disable eqeqeq */
import InputCurrency from 'components/InputCurrency'
import InputJumlah from 'components/InputJumlah'
import { useEffect, useState } from 'react'
import { HelpCircle } from 'react-feather'
import {
  Alert,
  Button,
  Card,
  CardText,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
// api
import {
  useCreateAutoRepoMutation,
  useGetUnitsQuery,
  useGetRepoTopThreeQuery,
} from '../commodityRepoApiSlice'
import useGetCommodityQuery from 'hooks/useGetCommodityQuery'

import Select from 'react-select'
import InputCurrencyWithLimit from 'components/InputCurrencyWithLimit'
import CardTop from '../components/CardTop'
import ModalConfirmSave from '../components/ModalConfirmSave'

const RepoCommodityForm = ({ invoiceLinks }) => {
  const getCommodity = useGetCommodityQuery()
  const getUnits = useGetUnitsQuery()
  const [{ isLoading }] = useCreateAutoRepoMutation()
  const getTopThree = useGetRepoTopThreeQuery()

  // Options
  const [commodity, setCommodity] = useState([])
  const [units, setUnits] = useState([])
  // List Payload
  const [saveData, setSaveData] = useState(true)
  const [name, setName] = useState('')
  const [sku, setSku] = useState('')
  const [invoiceDate, setInvoiceDate] = useState('')
  const [unitAmount, setUnitAmount] = useState('')
  const [unitName, setUnitName] = useState('')
  const [amountInvoice, setAmountInvoice] = useState(0)
  const [amountRequested, setAmountRequested] = useState(0)
  const [repoNotes, setRepoNotes] = useState('')
  let invLink = invoiceLinks.map((obj) => obj.url).join(',')

  // State Confirm
  const [isConfirm, setIsConfirm] = useState(false)

  const handleSubmit = () => {
    if (saveData) {
      setIsConfirm(true)
    } else {
      setIsConfirm(true)
    }
  }

  const handleInputFormTopThree = (payload) => {
    let date = new Date(payload?.invoiceDate)

    let year = date.getFullYear()
    let month = String(date.getMonth() + 1).padStart(2, '0')
    let day = String(date.getDate()).padStart(2, '0')

    let thisCommodity = getCommodity?.data?.items.filter(
      (i) => i.value === payload?.SKU,
    )

    setName(payload?.name)
    setCommodity([
      { value: thisCommodity[0]?.value, label: thisCommodity[0]?.nameItem },
    ])
    setSku(payload?.SKU)

    setUnits([{ value: payload?.unitName, label: payload?.unitName }])
    setUnitName(payload?.unitName)

    setInvoiceDate(`${year}-${month}-${day}`)
    function formatCurrency(amount) {
      // Format the currency using Indonesian locale (id-ID)
      let formattedAmount = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      })
        .formatToParts(amount)
        .slice(1)
        .map((part) => part.value)
        .join('')

      if (formattedAmount.includes(',')) {
        let parts = formattedAmount.split(',')
        // If decimal part is zero, return only the integer part
        if (parts[1] == '00') {
          return parts[0]
        } else {
          // Otherwise, return the formatted amount
          return formattedAmount
        }
      } else {
        // Remove the decimal separator
        return formattedAmount.replace('.00', '')
      }
    }
    setUnitAmount(formatCurrency(payload?.unitAmount))
    setAmountInvoice(formatCurrency(payload?.amountInvoice))
    setAmountRequested(formatCurrency(payload?.amountRequested))
  }

  function convertStrToNum(amountReq, amountInv) {
    let req = amountReq?.toString()
    req = parseInt(req?.replace(/\./g, ''))

    let inv = amountInv?.toString()
    inv = parseInt(inv?.replace(/\./g, ''))

    if (req > inv) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (amountRequested !== undefined && amountInvoice !== undefined) {
      convertStrToNum(amountRequested, amountInvoice)
    }
  }, [amountInvoice, amountRequested])

  return (
    <>
      <Card body className="my-3" style={{ padding: 32 }}>
        <CardText className="text-md fw-bold">Repo Komoditas</CardText>
        <Alert
          color="success"
          className="text-xs fw-semibold"
          style={{ backgroundColor: '#ECFDF3' }}
        >
          <HelpCircle size={16} className="me-2" style={{ color: '#027A48' }} />
          <span>
            Pastikan informasi yang diisi sama dengan dokumen repo yang di
            unggah
          </span>
        </Alert>

        {/* Start Top Three */}
        <Row>
          {getTopThree?.data?.map((topThree, i) => (
            <Col key={i} lg={4} className="mb-3">
              <CardTop data={topThree} callBack={handleInputFormTopThree} />
            </Col>
          ))}
        </Row>
        {/* End Top Three */}
        <Form>
          {/* name */}
          <FormGroup>
            <Label for="name" className="text-sm">
              Nama usaha penjamin repo
            </Label>
            <Input
              id="name"
              name="name"
              placeholder="Masukkan nama usaha"
              type="text"
              autoComplete="off"
              className="text-sm"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </FormGroup>

          {/* SKU */}
          <FormGroup>
            <Label for="name" className="text-sm">
              Komoditas yang di repo
            </Label>
            <Select
              id="nameItem"
              name="nameItem"
              placeholder="Pilih komoditas"
              options={getCommodity?.data?.items ?? []}
              onChange={(e) => {
                setCommodity([{ value: e.SKU, label: e.label }])
                setSku(e.SKU)
              }}
              value={commodity ?? []}
            />
          </FormGroup>

          {/* invoiceDate */}
          <FormGroup>
            <Label for="name" className="text-sm">
              Pilih tanggal komoditas akan dibayarkan oleh penjamin repo
            </Label>
            <Input
              id="name"
              name="name"
              placeholder="Pilih komoditas"
              type="date"
              autoComplete="off"
              className="text-sm"
              onChange={(e) => setInvoiceDate(e.target.value)}
              value={invoiceDate}
            />
          </FormGroup>
          {/* multi form */}
          <Row>
            <Col sm={12} md={12} lg={6}>
              <FormGroup>
                <Label for="name" className="text-sm">
                  Jumlah
                </Label>
                <InputJumlah
                  id="jumlah"
                  name="jumlah"
                  setValue={(e) => setUnitAmount(e)}
                  value={unitAmount}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <FormGroup>
                <Label for="name" className="text-sm">
                  Satuan
                </Label>
                <Select
                  id="unit"
                  name="unit"
                  placeholder="Pilih Satuan"
                  options={getUnits?.data ?? []}
                  onChange={(e) => {
                    setUnits([{ value: e.value, label: e.label }])
                    setUnitName(e.value)
                  }}
                  value={units ?? []}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <FormGroup>
                <Label for="name" className="text-sm">
                  Harga total komoditas
                </Label>
                <InputCurrency
                  value={amountInvoice}
                  setValue={(e) => {
                    if (
                      amountInvoice !== 0 &&
                      amountRequested !== 0 &&
                      amountInvoice <= amountRequested
                    ) {
                      setAmountRequested(0)
                    }
                    setAmountInvoice(e)
                  }}
                  name="totalPrice"
                  id="totalPrice"
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <FormGroup>
                <Label for="name" className="text-sm">
                  Permintaan untuk limit talangin
                </Label>
                <InputCurrencyWithLimit
                  maxAmount={amountInvoice}
                  value={amountRequested}
                  name="amountRequested"
                  id="amountRequested"
                  setValue={(e) => setAmountRequested(e)}
                />
                <span style={{ color: '#DC6803' }} className="text-sm">
                  Harga permintaan harus dibawah harga total komoditas
                </span>
              </FormGroup>
            </Col>

            <Col sm={12}>
              <FormGroup>
                <Label for="catatan" className="text-sm">
                  Catatan
                </Label>
                <Input
                  type="textarea"
                  value={repoNotes}
                  name="notes"
                  id="notes"
                  onChange={(e) => setRepoNotes(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm={12} md={12} lg={6}>
              <div className="d-flex flex-column text-wrap w-auto">
                <div className="mb-1 p-2">
                  <Input
                    type="checkbox"
                    checked={saveData}
                    className="me-2"
                    onChange={(e) => setSaveData(e.target.checked)}
                  />
                  <span className="text-sm">
                    Simpan Data Ini Untuk Repo Berikutnya
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
        <hr />
        <Row className="">
          <Col className="d-flex align-items-center">
            <span>
              <span className="fw-bold">Harga permintaan Repo:</span> Rp
              {`${amountRequested}`}
            </span>
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            <Button
              color="primary"
              disabled={
                !sku ||
                invLink === '' ||
                !invoiceDate ||
                !name ||
                !unitName ||
                amountInvoice == '0' ||
                amountRequested == '0' ||
                unitAmount == '0' ||
                isLoading ||
                convertStrToNum(amountRequested, amountInvoice)
              }
              style={{ padding: '16px 28px' }}
              onClick={handleSubmit}
            >
              Ajukan Permintaan Repo
            </Button>
          </Col>
        </Row>
      </Card>
      <ModalConfirmSave
        isOpen={isConfirm}
        toggle={() => setIsConfirm(false)}
        payload={{
          SKU: sku,
          amountInvoice,
          amountRequested,
          invoiceLinks: invLink,
          invoiceDate,
          name: name.trim(),
          unitName,
          unitAmount,
          save: saveData,
          notes: repoNotes,
        }}
      />
    </>
  )
}

export default RepoCommodityForm
