import { useUploadImageMutation } from 'features/private-views/settings/settingApiSlice'
import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CardText, Input } from 'reactstrap'

export default function UploadImageCard({ onChangeDataStock, uploadedImage }) {
  const [upload, { isLoading: uploadLoading }] = useUploadImageMutation()
  const fileInputRef = useRef(null)
  const dispatch = useDispatch()
  const [visibleErrorMsg, setVisibleErrorMsg] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleUploadClick = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = (event) => {
    fnCloseErrorMsg()
    const file = event?.target?.files[0]
    if (file === undefined) {
      setErrorMessage('Anda belum menambahkan foto.')
    }

    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']
    if (!allowedTypes.includes(file.type)) {
      setVisibleErrorMsg(true)
      return setErrorMessage(`Hanya file JPG dan PNG yang diperbolehkan.`)
    }

    const maxSizeMB = 4
    const maxSizeBytes = maxSizeMB * 1024 * 1024
    if (file.size > maxSizeBytes) {
      setVisibleErrorMsg(true)
      return setErrorMessage(`Ukuran file melebihi batas ${maxSizeMB}MB.`)
    }
    const reader = new FileReader()
    reader.onload = () => {}
    reader.readAsDataURL(file)

    event.target.value = null
    fnUploadFile(file)
  }

  const fnUploadFile = async (dataImg) => {
    if (dataImg === null) {
      setErrorMessage('Anda belum menambahkan foto.')
      setVisibleErrorMsg(true)
    }

    try {
      const data = await upload(dataImg).unwrap()
      onChangeDataStock('linkFoto', data?.url)
      if (data?.error) {
        setErrorMessage(data?.payload)
        setVisibleErrorMsg(true)
      }
    } catch (error) {
      setErrorMessage(error?.data?.meta?.message)
      setVisibleErrorMsg(true)
    }
  }

  const fnCloseErrorMsg = () => {
    setVisibleErrorMsg(false)
    setErrorMessage(null)
  }

  return (
    <>
      <div className="d-flex align-items-center mt-2">
        {uploadedImage ? (
          <img
            className="button"
            src={`${uploadedImage}?userId=637`}
            style={{ width: 60, height: 60, borderRadius: 99 }}
          />
        ) : (
          <img
            className="button"
            src={require('../../../../../assets/images/default-item.png')}
            style={{ width: 60, height: 60, borderRadius: 99 }}
          />
        )}

        <div
          className="bordered-primary mt-2"
          style={{ marginLeft: 10, borderColor: '#D0D5DD' }}
        >
          <Input
            ref={fileInputRef}
            onClick={() => {
              handleUploadClick()
            }}
            style={{ borderColor: '#D0D5DD', color: '#344054' }}
            className="border-input fw-semibold"
            type="button"
            value="Ubah Gambar"
          ></Input>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept=".jpg, .png"
        />
      </div>
      {visibleErrorMsg ? (
        <div className="mt-2">
          <CardText style={{ color: '#D92D20' }}>{errorMessage}</CardText>
        </div>
      ) : null}
    </>
  )
}
