import LogoUangBelanja from 'assets/icons/uang-belanja-wallet.png'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useIntl } from 'react-intl'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Spinner,
} from 'reactstrap'
import FooterRollover from './FooterRollover'
import { useDispatch, useSelector } from 'react-redux'
import { getTenors } from '../talanginInvoiceAction'
import {
  setSelectedTenor,
  toggleSidebarTalangin,
} from '../talanginInvoiceSlice'
import { getBalance } from 'features/private-views/dashboard/dashboardAction'
import { URL_406 } from 'utils/pathUrl'
import { useAbilityAccess } from 'hooks/useAbilityAccess'

const Rollover = ({ action, subject }) => {
  const intl = useIntl()
  const abilityCreate = useAbilityAccess({ action, subject })

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation
  const dataState = location.state
  const [tenor, setTenor] = useState('')
  const { balance } = useSelector((s) => s.dashboard)
  const { selectedRow, tenors, selectedTenor } = useSelector(
    (s) => s.talanginInvoice,
  )

  const amountToPay =
    selectedRow?.status === 'PAIDOFF'
      ? 0
      : selectedRow?.netOutstanding
        ? selectedRow?.netOutstanding
        : selectedRow?.amount +
          selectedRow?.fineData?.totalFine -
          selectedRow?.discountData?.discount

  const handleNext = () => {
    navigate('/perpanjang-tempo-pembayaran-konfirmasi')
  }

  useEffect(() => {
    if (!selectedRow) {
      dispatch(toggleSidebarTalangin())
      navigate('/tagihan-talangin')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow])

  useEffect(() => {
    if (selectedRow) {
      dispatch(getTenors({ id: selectedRow?.id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow])

  useEffect(() => {
    if (dataState?.refetchSaldo) {
      dispatch(getBalance())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataState])

  if (!abilityCreate) {
    return <Navigate to={URL_406} replace={true} />
  }

  return (
    <div className="w-100 d-flex flex-column justify-content-between">
      <Container className="custom-container p-3">
        <Card>
          <CardHeader className="p-4" style={{ backgroundColor: '#ECFAFF' }}>
            <img
              alt="Uang Belanja"
              src={LogoUangBelanja}
              style={{ maxWidth: '3%', maxHeight: '3%' }}
            />{' '}
            Uang Belanja: Rp
            {intl.formatNumber(balance?.uangBelanja ?? 0, {
              useGrouping: 'always',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </CardHeader>
          <CardBody className="p-4">
            <CardTitle className="fw-semibold">
              Pilih tempo pembayaran
            </CardTitle>
            <div
              style={{ backgroundColor: '#FFFAEB' }}
              className="rounded border border-warning"
            >
              <span className="m-2 d-flex gap-2 align-items-center">
                <AlertCircle size={20} /> Anda hanya bisa memperpanjang sebanyak
                satu kali.
              </span>
            </div>
            {tenors ? (
              tenors?.rolloverTenors?.map((e, index) => {
                return (
                  <Card
                    key={index + 1}
                    className={`${!e?.enabled ? 'opacity-50' : ''} my-3`}
                    onClick={() => {
                      if (e?.enabled) {
                        setTenor(index + 1)
                        dispatch(setSelectedTenor(e))
                      }
                    }}
                    style={{
                      borderStyle: tenor === index + 1 ? 'solid' : '',
                      borderColor: tenor === index + 1 ? '#006386' : '',
                      backgroundColor: !e?.enabled ? '#F9FAFB' : '',
                    }}
                  >
                    <CardHeader
                      style={{
                        backgroundColor:
                          tenor === index + 1 ? '#ECF9FE' : 'transparent',
                      }}
                      className="fw-bold"
                    >
                      {e?.loanDays} Hari
                    </CardHeader>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <span>Jatuh tempo:</span>
                        <span>
                          {moment(tenors?.dueDate)
                            .add(e?.loanDays, 'days')
                            .locale('id')
                            .format('DD MMMM YYYY')}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span>Total pembayaran talangin:</span>
                        <span className="fw-bold">
                          Rp
                          {intl.formatNumber(e?.amountPlusInterest, {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                )
              })
            ) : (
              <div className="d-flex justify-content-center p-2">
                <Spinner />
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      <FooterRollover
        buttonOnly="true"
        handleClick={() => {
          handleNext()
        }}
        isDisable={!selectedTenor || !tenor}
      />
    </div>
  )
}

export default Rollover
