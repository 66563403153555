import { createAppSlice } from 'app/createAppSlice'
import { getPricingDisbursment } from './purchaseAction'

const initialState = {
  numberStep: 1,
  total: 0,
  isLoading: false,
  pricing: [],
}

export const commodityPurchaseSlice = createAppSlice({
  name: 'comodity-purchase-admin',
  initialState,
  reducers: (create) => ({
    setNumberStep: create.reducer((state, { payload }) => {
      state.numberStep = payload
    }),
    setTotal: create.reducer((state, { payload }) => {
      state.total = payload
    }),
  }),
  extraReducers: (builder) => {
    builder
      .addCase(getPricingDisbursment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPricingDisbursment.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.pricing = payload
      })
      .addCase(getPricingDisbursment.rejected, (state, { payload }) => {
        state.isLoading = false
      })
  },
})

export const { setNumberStep, setTotal } = commodityPurchaseSlice.actions

export default commodityPurchaseSlice.reducer
