import { useFetchNotifQuery } from 'features/private-views/dashboard/dashboardApiSlice'
import { useEffect, useState } from 'react'
import { Bell } from 'react-feather'
import { Link } from 'react-router-dom'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  NavbarBrand,
  UncontrolledDropdown,
} from 'reactstrap'
import PmgLogo from '../../assets/images/pmg-logo-beta.png'
import UserDropdown from './UserDropdown'

import ICPembelian from 'assets/icons/Pembelian.png'

const NavbarDashboard = (args) => {
  const [page, setPage] = useState(0)
  const [notifs, setNotifs] = useState([])
  const [isEndOfList, setIsEndOfList] = useState(false)

  const { data, isLoading, isSuccess } = useFetchNotifQuery(page)

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom && !isLoading && !isEndOfList) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  useEffect(() => {
    if (isSuccess && data?.items) {
      if (data.items.length === 0) {
        setIsEndOfList(true)
      } else {
        setNotifs((prevNotifs) => [...prevNotifs, ...data.items])
      }
    }
  }, [data, isSuccess])

  return (
    <>
      <Navbar {...args} className="border">
        <NavbarBrand tag={Link} to="/">
          <div className="d-flex flex-column">
            <img
              alt="PasarMIKRO logo"
              src={PmgLogo}
              style={{ width: '100%', maxWidth: '177px', height: 'auto' }}
            />
            <span className="text-xs text-center mb-1">
              Versi: {process.env.REACT_APP_VERSION}
            </span>
          </div>
        </NavbarBrand>
        {/* <NavbarToggler onClick={toggle} children={<AlignLeft />} /> */}
        <div className="d-flex justify-content-end">
          {/* <Nav className="me-auto" navbar>
            {privateRouter.map((item, idx) => (
              <Can I={item.action} this={item.subject} key={idx}>
                <NavItem className="d-flex align-items-center">
                  <NavLinkDom
                    to={item.path ?? item.pathUrl}
                    aria-current="page"
                    className={({ isActive }) => {
                      if (isActive) {
                        return `nav-link active-link`
                      } else {
                        return `nav-link`
                      }
                    }}
                  >
                    {item.name}
                  </NavLinkDom>
                  {item.subject === BUSINESS_MYAPPROVAL && (
                    <span
                      style={{
                        height: 22,
                        width: 22,
                        textDecoration: 'none',
                      }}
                      className="rounded-circle badge-cancel flex-centered text-sm"
                    >
                      {isSuccess
                        ? data?.data
                            ?.map((e) => e.total)
                            ?.reduce((sum, e) => sum + e, 0)
                        : isLoading
                          ? 0
                          : 0}
                    </span>
                  )}
                </NavItem>
              </Can>
            ))}
          </Nav> */}

          <UncontrolledDropdown group>
            <DropdownToggle className="bg-transparent border-0">
              <div className="d-flex position-relative">
                <div className="d-flex flex-centered">
                  <Bell size={26} className="text-secondary" />
                </div>
                {/* <span
                  style={{
                    position: 'absolute',
                    top: -2,
                    right: -2,
                    height: 16,
                    width: 'auto',
                    textDecoration: 'none',
                    backgroundColor: '#C4320A',
                    fontSize: 10,
                    padding: '0px 4px 0px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  className="rounded-circle text-white text-sm"
                >
                  5
                </span> */}
              </div>
            </DropdownToggle>
            <DropdownMenu
              style={{
                width: '500px',
                maxHeight: '357px',
                overflowY: 'hidden',
              }}
            >
              <DropdownItem
                header
                className="fw-semibold text-dark"
                style={{
                  backgroundColor: 'white',
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                }}
              >
                Notifikasi
              </DropdownItem>
              <hr style={{ marginTop: 7, marginBottom: 0 }} />
              <div
                style={{ maxHeight: '300px', overflowY: 'auto' }}
                onScroll={handleScroll}
              >
                {notifs.map((e, index) => (
                  <div id="notif-item" className="notif-item" key={index}>
                    <DropdownItem key={index} text>
                      <div className="d-flex align-items-start gap-2 my-1">
                        <img
                          style={{ width: '24px', height: '24px' }}
                          alt="foto"
                          src={ICPembelian}
                        />
                        <div className="d-flex align-items-center">
                          {e?.message}
                        </div>
                      </div>
                    </DropdownItem>
                  </div>
                ))}
                {isLoading && (
                  <DropdownItem text>
                    <div className="d-flex justify-content-center">
                      Loading...
                    </div>
                  </DropdownItem>
                )}
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>

          <UserDropdown profile={args.profile} />
        </div>
      </Navbar>
    </>
  )
}

export default NavbarDashboard
