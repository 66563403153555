import { createApiSlice } from 'app/createApiSlice'

export const receivableApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'catatan/piutang',

  endpoints(builder) {
    return {
      postReceivable: builder.mutation({
        query(payload) {
          return {
            url: `/api/v1/bussines/receiveable/manual`,
            method: 'POST',
            body: payload,
          }
        },
      }),
      postPayReceivable: builder.mutation({
        query({ payableId, amountPaid, notes, manual, payerName }) {
          return {
            url: `/api/v1/bussines/receivable/pay-manual`,
            method: 'POST',
            body: {
              payableId,
              amount: amountPaid,
              notes,
              manual,
              payerName,
            },
          }
        },
      }),
    }
  },
})

export const { usePostReceivableMutation, usePostPayReceivableMutation } =
  receivableApiSlice
