import { useEffect } from 'react'
import { ArrowDownCircle, FileText, PlusCircle } from 'react-feather'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
// Redux
import { useFetchSaldoQuery } from '../dashboardApiSlice'
import { persistor } from 'app/store'
//
import ICUangBelanja from 'assets/icons/uang-belanja-icon.svg'
import ICUangUsaha from 'assets/icons/uang-usaha-icon.svg'
import ICTalangin from 'assets/icons/talangin-icon.svg'
import CardBankAccount from 'components/CardBankAccount'
import { generateGreetings } from 'utils/generateGreetings'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFetchWithdrawalQuery } from 'features/private-views/withdraw/withdrawApiSlice'
import { addBalance, toggleSidebarWithdraw } from '../dashboardSlice'
import LoadingAnimation from 'components/LoadingAnimation'
import {
  setAlertMsg,
  toggleAlert,
} from 'features/public-views/login/loginSlice'

const SectionCards = () => {
  const dispatch = useDispatch()
  const { role } = useSelector((s) => s.auth)
  const { isLoading, isSuccess, data, isError } = useFetchSaldoQuery()

  const profile = useOutletContext()
  const {
    data: dataWithdraw,
    isLoading: isLoadingWithdraw,
    isSuccess: isSuccessWithdraw,
  } = useFetchWithdrawalQuery()

  const navigate = useNavigate()
  const { isProcess } = useSelector((e) => e.withdraw)
  const toggle = () => dispatch(toggleSidebarWithdraw())

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(addBalance(data))
    }
    if (isError) {
      persistor.purge()
      navigate('/login')
      dispatch(toggleAlert(true))
      dispatch(setAlertMsg('Ada masalah koneksi, silakan login kembali'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, navigate, data, dispatch, isSuccess])

  const status = dataWithdraw?.items
    ?.map((item) => {
      if (item.status === 'Dalam Proses') {
        return 'Penarikan dalam proses'
      } else if (
        role === 'Staff' &&
        item.status === 'Menunggu Approval Admin'
      ) {
        return 'Penarikan menunggu approval admin'
      } else if (
        role === 'Admin' &&
        item.status === 'Menunggu Approval Admin'
      ) {
        return 'Ada penarikan membutuhkan approval'
      }
      return null // Return null for other cases
    })
    .filter((message) => message !== null)

  return (
    <Card className="w-100 mt-4">
      <CardTitle tag="h4" className="m-4">
        {generateGreetings()}
        {profile?.isLoading ? (
          <LoadingAnimation size="sm" />
        ) : profile?.isSuccess ? (
          <>
            {`, `}
            {profile?.data?.makerData?.name}!
          </>
        ) : (
          <LoadingAnimation size="sm" />
        )}
      </CardTitle>
      <CardBody>
        <Row>
          <Col lg="4" sm="12">
            <CardBankAccount
              iconCard={ICTalangin}
              title="Talangin"
              btnTitle="Lihat Tagihan"
              btnIcon={<FileText size={18} />}
              bankAmount={data?.talangin}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isDisable={false}
              handleClick={() => navigate('/tagihan-talangin')}
            />
          </Col>
          <Col lg="4" sm="12">
            <CardBankAccount
              iconCard={ICUangBelanja}
              title="Uang Belanja"
              btnTitle="Tambah"
              btnIcon={<PlusCircle size={18} />}
              bankAmount={data?.wallet1}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isDisable={profile?.data?.ownerData?.status !== 'VERIFIED'}
              handleClick={() => navigate('/topup')}
            />
          </Col>
          <Col lg="4" sm="12">
            <CardBankAccount
              iconCard={ICUangUsaha}
              title="Uang Usaha"
              btnTitle="Lihat Uang Usaha"
              btnIcon={<ArrowDownCircle size={18} />}
              bankAmount={data?.wallet2}
              isLoading={isLoadingWithdraw}
              isSuccess={isSuccessWithdraw}
              isDisable={false}
              handleClick={() => {
                if (
                  dataWithdraw?.items?.some(
                    (wd) =>
                      wd?.status === 'Dalam Proses' ||
                      (role === 'Staff' &&
                        wd?.status === 'Menunggu Approval Admin') ||
                      (role === 'Admin' &&
                        wd?.status === 'Menunggu Approval Admin'),
                  )
                ) {
                  toggle()
                } else {
                  navigate('/penarikan-uang')
                }
              }}
              isProcess={
                isProcess ||
                dataWithdraw?.items?.some(
                  (wd) =>
                    wd?.status === 'Dalam Proses' ||
                    (role === 'Staff' &&
                      wd?.status === 'Menunggu Approval Admin') ||
                    (role === 'Admin' &&
                      wd?.status === 'Menunggu Approval Admin'),
                )
              }
              processMsg={status}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default SectionCards
