import { Box, MapPin } from 'react-feather'
import { Card, CardText } from 'reactstrap'

export default function RemoveStockCard({ item, onSelectStock, selectedList }) {
  const isSelected = selectedList?.includes(item.id)
  return (
    <Card
      className="small-padding"
      onClick={onSelectStock}
      style={{
        borderColor: isSelected ? '#8ABBCC' : null,
        backgroundColor: isSelected ? '#ECFAFF' : null,
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <img src={item?.item?.linkFoto} style={{ width: 80, height: 80 }} />
          <div className="pl-4">
            <CardText className="no-padding fw-semibold">
              {item?.item?.nameItem}
            </CardText>
            <div className="d-flex align-items-center mt-2">
              <Box size={15} />
              <div className="pl-2">
                <CardText className="no-padding" style={{ fontSize: 12 }}>
                  {item?.amount} {item?.unit}
                </CardText>
              </div>
            </div>
            {item?.city || item?.district || item?.province ? (
              <div className="d-flex align-items-center mt-2">
                <MapPin size={15} />
                <CardText className="pl-2" style={{ fontSize: 13 }}>
                  {item?.city || item?.district || item?.province}
                </CardText>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Card>
  )
}
