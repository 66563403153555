import { ButtonIcon } from 'components/Buttons'
import { useState } from 'react'
import DataTable from 'react-data-table-component'
import { ChevronDown } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card, CardBody, CardFooter, Spinner } from 'reactstrap'
import { setFetchOption } from './farmersPendingSlice'
import { useFetchFarmersQuery } from '../farmers-list/farmersListApiSlice'

const FarmersPendingList = () => {
  const { tag } = useSelector((s) => s.farmersList)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  let [page, setPage] = useState({ page: tag.page })
  const { data, isLoading } = useFetchFarmersQuery({
    page: page?.page,
    status: 'PENDING',
  })

  const columns = [
    {
      name: 'Nama',
      cell: (row) => {
        return <span>{row?.name}</span>
      },
    },
    {
      name: 'Nomor Telepon',
      cell: (row) => {
        return <span>{row?.phoneNumber}</span>
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {},
    },
    rows: {
      style: {},
    },
  }

  return (
    <div className="w-100 px-3 py-3">
      <Card>
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.data?.items}
            progressPending={isLoading}
            responsive
            persistTableHead
            paginationTotalRows={data?.data?.totalItems}
            progressComponent={<Spinner />}
            sortIcon={<ChevronDown />}
            sortServer
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                Belum ada pengguna yang didaftarkan
              </p>
            }
            subHeader
            subHeaderAlign="left"
            subHeaderComponent={
              <>
                <h5 className="fw-semibold">Menunggu Konfirmasi Anggota</h5>
                <p>
                  Anggota yang sudah memiliki akun perlu melakukan konfirmasi
                  via WhatsApp agar dapat bergabung sepenuhnya. Anda tetap bisa
                  menambahkan anggota lainnya sambil menunggu.
                </p>
              </>
            }
            pagination
            paginationServer
            paginationComponent={(props) => {
              return (
                <CardFooter
                  className="border border-top-0"
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-sm">
                      {`Halaman ${page.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                    </div>
                    <div className="d-flex flex-centered gap-1">
                      <ButtonIcon
                        btnTitle="Sebelumnya"
                        btnProps={{
                          disabled: page.page === 0,
                          size: 'sm',
                          color: 'secondary',
                          onClick: () => {
                            setPage((prev) => ({ page: prev.page - 1 }))
                            dispatch(
                              setFetchOption({
                                page: page.page - 1,
                              }),
                            )
                          },
                        }}
                        btnTitleProps={{
                          className: 'text-sm',
                        }}
                      />
                      <ButtonIcon
                        btnTitle="Selanjutnya"
                        btnProps={{
                          disabled:
                            page.page ===
                            Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                          size: 'sm',
                          color: 'secondary',
                          onClick: () => {
                            setPage((prev) => ({ page: prev.page + 1 }))
                            dispatch(
                              setFetchOption({
                                page: page.page + 1,
                              }),
                            )
                          },
                        }}
                        btnTitleProps={{
                          className: 'text-sm',
                        }}
                      />
                    </div>
                  </div>
                </CardFooter>
              )
            }}
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default FarmersPendingList
