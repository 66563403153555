import AmountInput from 'components/AmountInput'
import { useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
} from 'reactstrap'
import ModalPasswordInvoice from './ModalPasswordInvoice'
import { toggleModalPartial, toggleModalPassword } from './talanginInvoiceSlice'

const ModalPartialPayment = ({ row, amountToPay, toggleSidebar }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [amount, setAmount] = useState(0)
  const [errMsg, setErrMsg] = useState('')

  const { isOpenModalPassword, isOpenModalPartial } = useSelector(
    (s) => s.talanginInvoice,
  )

  const handleSubmit = () => {}

  return (
    <>
      <ModalPasswordInvoice
        isOpen={isOpenModalPassword}
        toggle={() => dispatch(toggleModalPassword())}
        toggleSidebar={toggleSidebar}
        row={row}
        amountToPay={Number(
          amount?.replace?.(/\./g, '')?.replace?.(/\,/g, '.')?.slice?.(0, 15),
        )}
      />
      <Modal
        className="p-5 w-100"
        isOpen={isOpenModalPartial}
        toggle={() => {
          dispatch(toggleModalPartial())
        }}
        centered
      >
        <ModalBody>
          <div className="mb-3 w-100">
            <h6 className="mb-3 fw-bold">Bayar Tagihan</h6>
            <div
              className="rounded p-3 mb-3"
              style={{ backgroundColor: '#ECFAFF' }}
            >
              Kamu bisa membayarkan tagihan kamu dengan cara dicicil selama masa
              tenor yang kamu pilih atau membayar penuh untuk tagihan ini.
            </div>
            <FormGroup>
              <Label>Jumlah</Label>
              <InputGroup>
                <InputGroupText className="bg-transparent">Rp</InputGroupText>
                <AmountInput
                  max={amountToPay}
                  cb={(res) => setAmount(res)}
                  placeholder="0"
                />
              </InputGroup>
              <Label className="fw-light">
                Dari total tagihan: Rp{' '}
                {intl.formatNumber(amountToPay, {
                  useGrouping: 'always',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </Label>
            </FormGroup>
            {errMsg && (
              <FormGroup>
                <small className="text-danger">
                  <AlertCircle size={15} /> {errMsg}
                </small>
              </FormGroup>
            )}
          </div>
          <div className="d-flex justify-content-between gap-3 w-100">
            <Button
              onClick={() => {
                dispatch(toggleModalPartial())
                setErrMsg('')
              }}
              block
              outline
              color="secondary"
            >
              Batal
            </Button>
            <Button
              onClick={() => {
                dispatch(toggleModalPassword())
                dispatch(toggleModalPartial())
              }}
              block
              color="primary"
              disabled={!amount}
              type="submit"
            >
              Bayar
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModalPartialPayment
