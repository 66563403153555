import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { Button, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import { useSelector } from 'react-redux'
import {
  useLazyGetCategoriesCashFlowQuery,
  usePostCategorCashFlowMutation,
} from './cashFlowApiSlice'
import { useEditCategoryMutation } from '../payable-notes/payableNotesApiSlice'

const ModalCategory = ({ isOpen, toggle, row, isIncome }) => {
  const [errMsg, setErrMsg] = useState('')
  const [category, setCategory] = useState('')

  const { ownerData } = useSelector((s) => s.dashboard)
  const [editCategory, result] = useEditCategoryMutation()
  const [postCategory, result2] = usePostCategorCashFlowMutation()
  const [getCategories] = useLazyGetCategoriesCashFlowQuery()

  const handleSubmit = async () => {
    try {
      let body = {}
      if (row) {
        body = {
          label: category,
          id: row?.id,
        }
      } else {
        body = {
          label: category,
          incomeorexpense: isIncome ? 'income' : 'expense',
        }
      }
      const resp = row
        ? await editCategory(body).unwrap()
        : await postCategory(body).unwrap()
      if (!resp?.data?.error) {
        toggle()
        getCategories({
          type: isIncome ? 'income' : 'expense',
          active: 1,
          userId: ownerData?.userId,
        })
      } else {
        throw resp
      }
    } catch (error) {
      setErrMsg(error?.data?.message ?? error?.message)
      console.log(error)
    }
  }

  useEffect(() => {
    if (row && isOpen) {
      setCategory(row?.label)
    } else {
      setCategory(null)
    }
  }, [row, isOpen])

  return (
    <Modal
      className="p-4"
      centered
      isOpen={isOpen}
      toggle={() => {
        toggle()
        setErrMsg('')
      }}
      autoFocus={false}
    >
      <ModalBody>
        <h5>{row ? 'Edit' : 'Tambah'} kategori</h5>
        <FormGroup className="mt-3">
          <Label>Nama kategori</Label>
          <Input
            autoFocus={true}
            value={category ?? ''}
            onChange={(e) => {
              setCategory(e.target.value)
              setErrMsg('')
            }}
            placeholder="Cth: Beli pupuk"
          />
        </FormGroup>
        {errMsg && (
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        )}
        <div className="d-flex gap-3 mt-4">
          <Button
            onClick={() => {
              toggle()
              setErrMsg('')
            }}
            disabled={result?.isLoading || result2?.isLoading}
            color="primary"
            outline
            block
          >
            Batal
          </Button>
          <Button
            disabled={!category || result?.isLoading || result2?.isLoading}
            onClick={handleSubmit}
            color="primary"
            block
          >
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalCategory
