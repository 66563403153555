import LogoPasarMikro from 'assets/images/pmg-logo-beta.png'
import LoadingAnimation from 'components/LoadingAnimation'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'reactstrap'
import { getTnc } from './tncAction'

const Tnc = () => {
  const dispatch = useDispatch()
  const { tnc, loading } = useSelector((s) => s.tnc)

  useEffect(() => {
    dispatch(getTnc())
  }, [])

  return (
    <>
      <Container>
        <div
          className="flex-centered flex-column m-0 m-auto"
          style={{
            overflow: 'auto',
            paddingLeft: '15px',
            paddingRight: '15px',
            height: '80vh',
          }}
        >
          <div className="d-flex justify-content-center align-items-center mt-4">
            <img
              style={{ width: '100%', maxWidth: '177px', height: 'auto' }}
              src={LogoPasarMikro}
              alt="PasarMIKRO logo"
            />
          </div>
          {loading ? (
            <LoadingAnimation size="sm" />
          ) : (
            <div
              className="w-75 overflow-auto my-2"
              style={{ maxHeight: '60vh' }}
            >
              <div dangerouslySetInnerHTML={{ __html: tnc }} />
            </div>
          )}
        </div>
      </Container>
    </>
  )
}

export default Tnc
