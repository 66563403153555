import { useEffect, useMemo, useState } from 'react'
import {
  useGetSizeCommodityQuery as getSize,
  useGetCommodityQuery as getCommodity,
} from 'features/private-views/commodity-repo/commodityRepoApiSlice'

const useGetCommodityQuery = (arg) => {
  let [size, setSize] = useState(null)

  const teamResult = getSize(arg)
  const result = getCommodity(size, {
    skip: size === null || size === undefined,
  })

  const data = useMemo(() => {
    let finalData = {
      ...result,
      data: {
        ...result?.data,
        items: result?.data?.items?.map((e) => e),
      },
    }

    return finalData
  }, [result])

  useEffect(() => {
    setSize(teamResult?.data?.totalItems)
  }, [teamResult?.data?.totalItems])

  return data
}

export default useGetCommodityQuery
