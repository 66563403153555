import moment from 'moment-timezone'
import { Card, CardText } from 'reactstrap'

export default function DetailModal({ selectedStock }) {
  return (
    <>
      <div>
        <Card className="basic-card">
          <CardText className="no-padding fw-semibold">Komoditas</CardText>
          <CardText>{selectedStock?.item?.nameItem}</CardText>
        </Card>
        <Card className="basic-card mt-3">
          <CardText className="no-padding fw-semibold">Stok Komoditas</CardText>
          <CardText>
            {selectedStock?.amount}{' '}
            {selectedStock?.unit || selectedStock?.item?.unit}
          </CardText>
        </Card>
        <Card className="basic-card mt-3">
          <CardText className="no-padding fw-semibold">Lokasi</CardText>
          {selectedStock?.district ||
          selectedStock?.subDistrict ||
          selectedStock?.city ||
          selectedStock?.province ? (
            <CardText>
              {selectedStock?.district || selectedStock?.subDistrict}
              {selectedStock?.district || selectedStock?.subDistrict ? ',' : ''}
              {selectedStock?.city || selectedStock?.province}
            </CardText>
          ) : (
            <CardText>-</CardText>
          )}
        </Card>
        <Card className="basic-card mt-3">
          <CardText className="no-padding">Foto Komoditas</CardText>
          <img
            src={selectedStock?.item?.linkFoto}
            style={{
              width: 60,
              height: 60,
              marginBottom: 5,
              paddingTop: 5,
              borderRadius: 99,
            }}
          />
        </Card>
        <Card className="basic-card mt-3">
          <CardText className="no-padding">
            Tanggal pembuatan komoditas
          </CardText>
          <CardText className="fw-semibold">
            {moment(selectedStock?.createdAt).format('DD MMMM YYYY')}
          </CardText>
        </Card>
        <Card className="basic-card mt-3">
          <CardText className="no-padding">Catatan tambahan</CardText>
          <CardText className="fw-semibold">
            {selectedStock?.description ? selectedStock?.description : '-'}
          </CardText>
        </Card>
      </div>
    </>
  )
}
