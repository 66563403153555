import axios from 'axios'
import Map from 'components/Map'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { apiEndpoint } from 'utility/Utils'
const authToken = process.env.REACT_APP_AUTH_TOKEN

const LandPlotView = ({ code, ...props }) => {
  const { code: codeParams, lat, long } = useParams()
  const [defaultCenterMap, setDefaultCenterMap] = useState({
    lat: -6.26055485,
    lng: 106.82934195,
  })
  const [plottingPoints, setPlottingPoints] = useState([])
  const [loadingPlotting, setLoadingPlotting] = useState(false)

  useEffect(() => {
    if (code || codeParams) {
      getPlottingPoint(code ?? codeParams, 0)
    }
  }, [code, codeParams])

  useEffect(() => {
    if (lat && long) {
      let locationPoint = {
        type: 'Point',
        coordinates: [Number(long), Number(lat)],
      }
      setDefaultCenterMap({
        lat: Number(lat),
        lng: Number(long),
      })
      onSelectedList({ locationPoint: locationPoint })
    }
  }, [lat, long])

  const onSelectedList = (item) => {
    let points = [
      {
        plottingPoint: {
          coordinates: item?.locationPoint?.coordinates,
        },
      },
    ]
    setTimeout(() => {
      setPlottingPoints(points)
    }, 500)
  }

  const getPlottingPoint = async (code, page) => {
    try {
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/page/plottingpoint?code=${code}&sortby=id&sortdir=asc&page=${page}&size=150`,
        {
          headers: {
            Authorization: authToken,
          },
        },
      )
      const newData = data.data.items
      if (data?.data?.totalItems === 1) {
        setTimeout(() => {
          setPlottingPoints((prevData) =>
            Array.from(
              new Set([...prevData, ...newData].map(JSON.stringify)),
            ).map(JSON.parse),
          )
        }, 500)
      } else {
        setPlottingPoints((prevData) =>
          Array.from(
            new Set([...prevData, ...newData].map(JSON.stringify)),
          ).map(JSON.parse),
        )
      }
      if (data?.data?.centerPoint?.coordinates?.length > 0) {
        setDefaultCenterMap({
          lat: data?.data?.centerPoint?.coordinates[1],
          lng: data?.data?.centerPoint?.coordinates[0],
        })
      }

      if (page < data.data.totalPages) {
        await getPlottingPoint(code ?? codeParams, page + 1)
      } else {
        setLoadingPlotting(false)
      }
    } catch (error) {
      setLoadingPlotting(false)
    }
  }

  return (
    <div style={{ display: 'flex', height: '100vh', width: '100%' }} {...props}>
      <div
        style={{
          width: '100%',
          transition: 'width 0.1s ease',
        }}
      >
        {loadingPlotting ? <p>loading</p> : null}

        <div
          style={{
            height: '100%',
            width: '100%',
            opacity: loadingPlotting ? 0.5 : 1,
          }}
        >
          <Map data={plottingPoints} center={defaultCenterMap} />
        </div>
      </div>
    </div>
  )
}

export default LandPlotView
