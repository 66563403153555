import PlaceholderPDF from 'assets/images/placeholder_pdf.png'
import { X } from 'react-feather'
import { Card } from 'reactstrap'

const CardPDF = ({ fileIndex, name = '', callBack }) => {
  return (
    <Card style={{ position: 'relative' }}>
      <X
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
          cursor: 'pointer',
          zIndex: 2,
        }}
        size={24}
        onClick={() => callBack(fileIndex)}
      />

      <div className="d-flex flex-column align-items-center">
        <img
          src={PlaceholderPDF}
          alt="place holder"
          className="p-2"
          width={150}
          height={150}
        />
        <div className="border-top w-100 text-center">
          {name.length > 20 ? `${name?.substring(0, 17)}...` : name}
        </div>
      </div>
    </Card>
  )
}

export default CardPDF
