import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Row } from 'reactstrap'
import { setOrderBuyer, toggleModal } from '../purchaseCommoditySlice'
import { useEffect } from 'react'
import { createOrderStaff } from '../purchaseCommodityAction'
import { useIntl } from 'react-intl'

const TotalAmountInfo = ({ isLoading, isSuccess, data }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { orders } = useSelector((s) => s.purchaseCommmodity)

  const total = orders.item
    ?.map((e) => (!e.totalPrice ? 0 : e.totalPrice))
    ?.reduce((sum, e) => sum + e, 0)

  let checkUnits = orders?.item?.map((i, idx) => {
    let arr = []
    Object.values(orders?.item[idx]).forEach((i) => {
      if (i === undefined || i === '') {
        arr.push(true)
      } else {
        arr.push(false)
      }
    })
    if (arr.includes(true)) {
      return true
    } else {
      return false
    }
  })

  const payloadOrderModified = (orders) => {
    return {
      ...orders,
      item: orders?.item?.map((e) => {
        return {
          ...e,
          price: Number(
            e?.price
              ?.replace?.(/\./g, '')
              ?.replace?.(/\,/g, '.')
              ?.slice?.(0, 15),
          ),
          amount: Number(
            e?.amount
              ?.replace?.(/\./g, '')
              ?.replace?.(/\,/g, '.')
              ?.slice?.(0, 15),
          ),
        }
      }),
    }
  }

  useEffect(() => {
    if (isSuccess)
      dispatch(
        setOrderBuyer({
          fullName: data?.ownerData?.name,
          phoneNumber: data?.ownerData?.phoneNumber,
          userId: data?.ownerData?.userId,
        }),
      )
  }, [dispatch, data, isSuccess])

  return (
    <div
      className="p-3 border-top border-2 w-100"
      style={{ backgroundColor: 'white' }}
    >
      <Col className="custom-container m-auto">
        <div className="flex-centered justify-content-between">
          <div className="">
            <div className="">
              <span className="fw-bold">Total yang dibayar</span>: Rp
              {intl.formatNumber(total, {
                useGrouping: 'always',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
            {total > 0 && total < 50000 && (
              <div className="text-sm text-danger">(Minimal Rp50.000)</div>
            )}
          </div>
          <Button
            size="sm"
            color="primary"
            className="p-2"
            onClick={() => {
              let payloadOrder = { ...orders }
              delete payloadOrder.step
              delete payloadOrder.isLoading
              dispatch(createOrderStaff(payloadOrderModified(orders)))
              dispatch(toggleModal())
            }}
            disabled={
              total === 0 ||
              isLoading ||
              !orders.seller ||
              checkUnits.includes(true) ||
              total < 50000
            }
          >
            Buat Transaksi
          </Button>
        </div>
      </Col>
    </div>
  )
}

export default TotalAmountInfo
