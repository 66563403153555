import { useEffect, useState } from 'react'
import { X } from 'react-feather'
import { CardText, CardTitle, Input, Modal } from 'reactstrap'

export default function ItemModal({
  isOpen,
  onClose,
  itemsList,
  onSelectItem,
}) {
  const [filteredCommodity, setFilteredCommodity] = useState(itemsList)

  useEffect(() => {
    if (!filteredCommodity.length) {
      setFilteredCommodity(itemsList)
    }
  }, [itemsList])

  const onSearchCommodity = (value) => {
    if (!value) {
      setFilteredCommodity(itemsList)
    } else {
      let newList = itemsList.filter((com) =>
        com?.nameItem?.toLowerCase().includes(value.toLowerCase()),
      )
      setFilteredCommodity(newList)
    }
  }

  const onSelectItemStock = (data) => {
    onSelectItem(data)
    onCloseModal()
  }

  const onCloseModal = () => {
    setFilteredCommodity(itemsList)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} centered>
      <div className="regular-padding border-bottom d-flex justify-content-between align-items-center">
        <CardTitle className="fw-semibold">Pilih Komoditas</CardTitle>
        <X className="button" onClick={onCloseModal} />
      </div>
      <div className="regular-padding primary-bg-25">
        <CardText style={{ fontSize: 14 }}>
          Pilih komoditas yang ingin dicatat.
        </CardText>
      </div>
      <div className="regular-padding">
        <Input
          placeholder="Cari komoditas"
          autoComplete="off"
          name="dob"
          id="dob"
          onChange={(text) => onSearchCommodity(text.target.value)}
        />
      </div>
      <div className="regular-padding-horizontal small-scrollable-container">
        {filteredCommodity?.map((el, i) => {
          return (
            <div
              className="regular-padding-bottom border-bottom regular-padding-top button"
              onClick={() => onSelectItemStock(el)}
            >
              <CardText>{el?.nameItem}</CardText>
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
