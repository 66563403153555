import React from 'react'
import PayableNotes from '../payable-notes'
import { useSelector } from 'react-redux'

const ReceivableNotes = () => {
  const { tag, isOpenBanner, isOpenSidebar } = useSelector(
    (s) => s.receivableNotes,
  )

  return (
    <PayableNotes
      dataReceivable={true}
      isOpenSidebarReceivable={isOpenSidebar}
      isOpenBannerReceivable={isOpenBanner}
    />
  )
}

export default ReceivableNotes
