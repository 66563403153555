import { useState } from 'react'
import { Alert, Button, Modal, ModalBody } from 'reactstrap'

// components
import ICXAlert from 'assets/icons/ic-x-alert.svg'
import './modalStyle.scss'
import { useUpdateAvatarUserMutation } from '../settingApiSlice'
import useGetMyProfileAvaQuery from 'hooks/useGetMyProfileAvaQuery'

const ModalConfirmasi = ({
  modal,
  toggle,
  dataName,
  setIsSuccessUploadAva,
  refetchAvatar,
  ...args
}) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [visibleErrorMsg, setVisibleErrorMsg] = useState(false)

  const [updateAvatarUrl, { isLoading }] = useUpdateAvatarUserMutation()
  const profile = useGetMyProfileAvaQuery({ skip: false })

  const resetState = () => {
    setErrorMessage(null)
    setVisibleErrorMsg(false)
  }

  const fnCloseErrorMsg = () => {
    setVisibleErrorMsg(false)
    setErrorMessage(null)
  }

  const fnUploadFile = async () => {
    try {
      const updateUser = await updateAvatarUrl({
        profilepicUrl: null,
        name: dataName,
      }).unwrap()
      setIsSuccessUploadAva(updateUser?.message)
      profile.refetch()
      resetState()
      refetchAvatar()
      setTimeout(toggle, 300)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal isOpen={modal} toggle={toggle} {...args} style={{ maxWidth: 400 }}>
      <ModalBody className="p-4">
        <div>
          <h5 className="text-left fw-bold">Hapus Foto Profile</h5>
          <p className="text-left text-sm">
            Anda yakin ingin menghapus foto profile?
          </p>
        </div>
        {/* Error message */}
        {errorMessage !== null && (
          <Alert
            isOpen={visibleErrorMsg}
            toggle={fnCloseErrorMsg}
            color="danger"
            className="d-flex align-items-center gap-2 my-4"
          >
            <img src={ICXAlert} alt="alert icon" width={18} height={18} />
            <span>{errorMessage}</span>
          </Alert>
        )}

        <div className="d-flex w-100 gap-2">
          <Button
            color="secondary"
            outline
            className="w-50 text-sm"
            disabled={isLoading}
            onClick={() => {
              resetState()
              setTimeout(toggle, 300)
            }}
          >
            Batal
          </Button>{' '}
          <Button
            color="primary"
            className="w-50 text-sm"
            onClick={fnUploadFile}
            disabled={isLoading}
          >
            Hapus Foto
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalConfirmasi
