import { Alert, Container } from 'reactstrap'
import SectionCards from './SectionCards'
import TableDashboard from './TableDashboard'
import { useEffect, useState } from 'react'
import SidebarConfirm from 'private-views/dashboard/SidebarConfirm'
import { useLocation } from 'react-router-dom'
import SidebarWithdraw from './SidebarWithdraw'
import { toggleBanner, toggleSidebarWithdraw } from './dashboardSlice'
import { useDispatch, useSelector } from 'react-redux'
import { setInitStatePurchase } from '../purchase-commodity/purchaseCommoditySlice'
import { setInitStateSell } from '../sell-commodity/sellCommoditySlice'
import { AlertCircle, CheckCircle } from 'react-feather'
import { setSuccessPindahUangUsaha } from '../withdraw/withdrawSlice'
import { setInitStateTopup } from '../topup/topupSlice'
import { setInitAlert } from 'features/public-views/login/loginSlice'

const DashboardView = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const dataState = location.state
  const [isOpen, setIsOpen] = useState(false)
  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  const pindahUangUsaha = useSelector((s) => s.withdraw)
  const { isOpenSidebarWithdraw, isOpenBanner, bannerMessage, bannerType } =
    useSelector((e) => e.dashboard)

  useEffect(() => {
    localStorage.removeItem('userData')
    dispatch(setInitStatePurchase())
    dispatch(setInitStateSell())
    dispatch(setInitStateTopup())
    dispatch(setInitAlert())
  }, [dataState, dispatch])

  return (
    <>
      <SidebarWithdraw
        open={isOpenSidebarWithdraw}
        toggle={() => dispatch(toggleSidebarWithdraw())}
      />
      <SidebarConfirm open={isOpen} toggleSidebar={toggleSidebar} />
      <div className="w-100 px-3">
        <Alert
          isOpen={
            pindahUangUsaha?.handlePindahUangUsaha?.message === ''
              ? false
              : true
          }
          className="mt-3"
          color="success"
          toggle={() => dispatch(setSuccessPindahUangUsaha({ message: '' }))}
        >
          <CheckCircle className="text-success me-2" size={16} />
          <span>{pindahUangUsaha?.handlePindahUangUsaha?.message}</span>
        </Alert>

        <Alert
          className="mt-3"
          color={bannerType}
          isOpen={isOpenBanner}
          toggle={() => dispatch(toggleBanner())}
        >
          {bannerType === 'success' ? (
            <CheckCircle className="text-success me-2" size={16} />
          ) : (
            <AlertCircle className="text-danger me-2" size={16} />
          )}
          <span>{bannerMessage}</span>
        </Alert>

        <SectionCards />
        <div className="my-4">
          <TableDashboard />
        </div>
      </div>
    </>
  )
}

export default DashboardView
