import { createApiSlice } from 'app/createApiSlice'

export const commodityRepoApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'repo/api',
  endpoints: (builder) => ({
    getUnits: builder.query({
      query: () => `/api/v1/public/version`,
      transformResponse(response, meta, arg) {
        const unitList = response?.unitList?.split(',')
        return unitList?.map((e) => ({
          value: e,
          label: e,
        }))
      },
    }),
    getSizeCommodity: builder.query({
      query: () => `/api/v1/bussines/items?sortby=nameItem&sortdir=asc`,
    }),
    getCommodity: builder.query({
      query: (size) =>
        `/api/v1/bussines/items?sortby=nameItem&sortdir=asc&size=${size}`,
      transformResponse(response, meta, arg) {
        return {
          ...response,
          items: response?.items?.map((i) => ({
            ...i,
            label: i.nameItem,
            value: i.SKU,
          })),
        }
      },
    }),
    getRepoList: builder.query({
      query: (args) => {
        const { type, size, page } = args
        return {
          url: '/api/v1/bussines/autorepo/list',
          params: { type, size, page },
        }
      },
    }),
    getRepoTopThree: builder.query({
      query: () => {
        return {
          url: `/api/v1/bussines/autorepo/topthree`,
        }
      },
    }),
    uploadRepoDoc: builder.mutation({
      query: (doc) => {
        const file = new FormData()
        file.append('file', doc)
        return {
          url: `/api/v1/upload`,
          method: 'POST',
          body: file,
        }
      },
    }),
    createAutoRepo: builder.mutation({
      query: (payload) => {
        return {
          url: `/api/v1/bussines/autorepo`,
          method: 'POST',
          body: payload,
        }
      },
    }),
    approveAutoRepoByUser: builder.mutation({
      query: (payload) => {
        return {
          url: `/api/v1/bussines/autorepo/approve`,
          method: 'POST',
          body: payload,
        }
      },
    }),
    rejectAutoRepoByUser: builder.mutation({
      query: (payload) => {
        return {
          url: `/api/v1/bussines/autorepo/reject`,
          method: 'POST',
          body: payload,
        }
      },
    }),
  }),
})

export const {
  useGetUnitsQuery,
  useGetSizeCommodityQuery,
  useGetCommodityQuery,
  useGetRepoListQuery,
  useUploadRepoDocMutation,
  useCreateAutoRepoMutation,
  useGetRepoTopThreeQuery,
  useRejectAutoRepoByUserMutation,
  useApproveAutoRepoByUserMutation,
} = commodityRepoApiSlice
