import { Button, Modal, ModalHeader, ModalBody, Input } from 'reactstrap'
import { useCreateAutoRepoMutation } from '../commodityRepoApiSlice'
import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'

function ModalConfirmSave({ payload: data, isOpen, toggle, ...args }) {
  const [isAgree, setIsAgree] = useState(true)
  const [setSuccessTrx] = useOutletContext()

  const [createRepo, { isLoading }] = useCreateAutoRepoMutation()

  const makeRepo = async () => {
    let payload = { ...data }

    payload.unitAmount = parseFloat(
      payload.unitAmount?.replace(/\./g, '')?.replace(',', '.'),
    )
    payload.amountRequested = parseFloat(
      payload.amountRequested?.replace(/\./g, '')?.replace(',', '.'),
    )
    payload.amountInvoice = parseFloat(
      payload.amountInvoice?.replace(/\./g, ''),
    )

    try {
      await createRepo(payload).unwrap()
      setSuccessTrx(true)
    } catch (error) {
      console.log(error?.response)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      style={{ maxWidth: '400px' }}
      centered={true}
      keyboard={false}
      {...args}
    >
      <ModalHeader className="d-flex column border-0 w-100">
        <div className="w-100">Ajukan Repo</div>
        <div className="w-100 text-sm" style={{ color: '#475467' }}>
          Pastikan data yang kamu masukkan sudah benar.
        </div>
      </ModalHeader>

      <ModalBody className="mb-1 d-flex align-items-center w-100">
        <Input
          type="checkbox"
          checked={isAgree}
          className="me-2"
          onChange={(e) => setIsAgree(e.target.checked)}
        />
        <span className="text-sm">
          Saya secara sadar memahami dan menyetujui pengajuan repo ini
          berdasarkan syarat dan ketentuan yang berlaku di PasarMIKRO.
        </span>
      </ModalBody>
      <div className="p-2 d-flex">
        <Button
          className="w-100 me-1"
          color="secondary"
          onClick={toggle}
          style={{ backgroundColor: '#fff', color: '#344054' }}
          disabled={isLoading}
        >
          Periksa Lagi
        </Button>
        <Button
          className="w-100"
          color="primary"
          onClick={() => makeRepo()}
          disabled={isLoading || !isAgree}
          style={{ cursor: !isAgree ? 'not-allowed' : 'pointer' }}
        >
          Lanjutkan
        </Button>
      </div>
    </Modal>
  )
}

export default ModalConfirmSave
