import { useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, FormGroup, Modal, ModalBody } from 'reactstrap'
import {
  toggleBannerDetail,
  toggleModalChangeProfile,
} from '../detail/detailSlice'
import {
  useLazyFetchFarmersDetailQuery,
  useLazyFetchFarmersQuery,
} from '../farmers-list/farmersListApiSlice'
import { toggleBanner } from '../farmers-list/farmersListSlice'
import {
  useChangeProfileMutation,
  useRegisterFarmerMutation,
} from './formApiSlice'
import { toggleModal } from './formSlice'

const ModalConfirmation = ({ row }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isOpenModal, payload } = useSelector((s) => s.farmersForm)
  const { tag } = useSelector((s) => s.farmersList)
  let [page, setPage] = useState({ page: tag.page })
  const [register, result] = useRegisterFarmerMutation()
  const [editProfile, res] = useChangeProfileMutation()
  const [fetchDetailFarmers] = useLazyFetchFarmersDetailQuery()
  const [fetchFarmers] = useLazyFetchFarmersQuery()
  const [fetchPendingFarmers] = useLazyFetchFarmersQuery()
  const [errMsg, setErrMsg] = useState('')

  const handleSubmit = async () => {
    try {
      const obj = {
        ...payload,
        province: payload?.province?.value,
        city: payload?.city?.value,
        district: payload?.district?.value,
        subDistrict: payload?.subDistrict?.value,
        postalCode: payload?.postalCode?.value,
      }

      const res = row
        ? await editProfile({ payload: obj, id: row?.userId })
        : await register(obj)
      if (res?.error) {
        setErrMsg(res?.error?.data?.meta?.message)
      } else if (!row) {
        fetchFarmers({ page: page?.page, status: 'JOIN' })
        fetchPendingFarmers({ page: page?.page, status: 'PENDING' })
        dispatch(toggleBanner())
        navigate('/farmers')
      } else if (row) {
        fetchDetailFarmers({ id: row?.userId })
        dispatch(toggleModal())
        dispatch(toggleModalChangeProfile())
        dispatch(toggleBannerDetail())
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      className="p-4"
      centered
      isOpen={isOpenModal}
      toggle={() => {
        dispatch(toggleModal())
        setErrMsg('')
      }}
    >
      <ModalBody>
        <h6>Konfirmasi {row ? 'Pengubahan' : 'Pendaftaran'}</h6>
        <p className="text-muted">
          Pastikan semua data yang dimasukkan sudah benar dan sesuai.
        </p>
        {errMsg && (
          <FormGroup>
            <small className="text-danger">
              <AlertCircle size={15} /> {errMsg}
            </small>
          </FormGroup>
        )}
        <div className="d-flex gap-3">
          <Button
            onClick={() => {
              dispatch(toggleModal())
              setErrMsg('')
            }}
            color="primary"
            outline
            block
          >
            Batal
          </Button>
          <Button onClick={handleSubmit} color="primary" block>
            {row ? 'Ubah' : 'Daftarkan'}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalConfirmation
