import { createAppSlice } from 'app/createAppSlice'
import { getTnc } from './tncAction'

const initialState = {
  tnc: '',
  loading: false,
}

export const tncSlice = createAppSlice({
  name: 'tnc',
  initialState,
  reducers: (create) => ({}),
  extraReducers: (builder) => {
    builder
      .addCase(getTnc.pending, (state) => {
        state.loading = true
      })
      .addCase(getTnc.fulfilled, (state, { payload }) => {
        state.loading = false
        state.tnc = payload
      })
      .addCase(getTnc.rejected, (state, { payload }) => {
        state.loading = false
      })
  },
})

export const {} = tncSlice.actions

export default tncSlice.reducer
