import { Card, CardBody, CardTitle } from 'reactstrap'
import LogoPasarMikro from 'assets/images/pmg-logo-beta.png'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setData } from 'features/public-views/connect-account/connectAccountSlice'

const SuccessVerifyAccount = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setData(null))
  }, [])

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center min-vh-100 bg-light">
        <Card style={{ maxWidth: '600px', width: '100%' }}>
          <CardBody>
            <div className="d-flex justify-content-center align-items-center mb-3">
              <img
                src={LogoPasarMikro}
                alt="PasarMIKRO logo"
                style={{ width: '100%', maxWidth: '177px', height: 'auto' }}
              />
            </div>
            <CardTitle
              className="d-flex justify-content-center align-items-center mb-4 text-center"
              tag="h2"
            >
              Anda akan diberikan akses ke PasarMIKRO Business melalui email,
              silakan cek secara berkala.
            </CardTitle>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default SuccessVerifyAccount
