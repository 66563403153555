import moment from 'moment-timezone'
import { useState } from 'react'
import { ArrowLeft } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, Spinner } from 'reactstrap'
import { URL_UTANG } from 'utils/pathUrl'
import ModalConfirm from './ModalConfirm'
import { toggleModalConfirm } from './payableNotesSlice'

const ApprovalList = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const dispatch = useDispatch()
  const [selectedRow, setSelectedRow] = useState(null)
  const [isDelete, setIsDelete] = useState(false)

  const { pendingPayable, loading } = useSelector((s) => s.payableNotes)

  return (
    <div className="w-100 px-3">
      <ModalConfirm row={selectedRow} isDelete={isDelete} />
      <Card className="my-4">
        <CardBody className="d-flex flex-column">
          <h5 className="fw-semibold">
            <ArrowLeft
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(URL_UTANG)}
            />{' '}
            Konfirmasi Catatan Utang
          </h5>
          <div>
            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner />{' '}
              </div>
            ) : pendingPayable?.totalCount > 0 ? (
              pendingPayable?.data?.map((e, i) => {
                return (
                  <div key={i}>
                    <Card className="mt-2">
                      <CardBody>
                        <div className="d-flex justify-content-between mt-3">
                          <span className="fw-600">{e?.name}</span>
                          <span className="text-primary fw-700">
                            Rp{' '}
                            {intl.formatNumber(e?.amount ?? 0, {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                          </span>
                        </div>
                        <div className="d-flex gap-2">
                          <div className="text-gray-500">
                            {moment(e?.createdAt).format('Do MMMM YYYY, HH:mm')}
                          </div>
                          <div className="text-gray-500">•</div>
                          <div className="text-primary-600 fw-500">
                            Kategori: {e?.categoryName}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="fw-600">
                            Catatan: {e?.notes ?? '-'}
                          </span>
                          <div className="d-flex gap-2">
                            <Button
                              onClick={() => {
                                setSelectedRow(e)
                                setIsDelete(true)
                                dispatch(toggleModalConfirm())
                              }}
                              color="light"
                              className="border"
                            >
                              Tolak
                            </Button>
                            <Button
                              onClick={() => {
                                setSelectedRow(e)
                                setIsDelete(false)
                                dispatch(toggleModalConfirm())
                              }}
                              color="primary"
                            >
                              Terima
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                )
              })
            ) : (
              <div className="border rounded p-2 text-center">
                Belum ada data.{' '}
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default ApprovalList
