import { ButtonIcon } from 'components/Buttons'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import {
  ArrowRightCircle,
  CheckCircle,
  ChevronDown,
  Edit2,
} from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Col,
  Row,
  Spinner,
} from 'reactstrap'
import { clearState } from '../forms/formSlice'
import { useFetchFarmersQuery } from './farmersListApiSlice'
import { setFetchOption, toggleBanner, toggleModal } from './farmersListSlice'
import ModalChangeName from './ModalChangeName'

const FarmersTable = () => {
  const { tag, isOpenBanner } = useSelector((s) => s.farmersList)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  let [page, setPage] = useState({ page: tag.page })

  const { data, isLoading } = useFetchFarmersQuery({
    page: page?.page,
    status: 'JOIN',
  })
  const { data: dataPending, isLoading: loadingPending } = useFetchFarmersQuery(
    {
      page: page?.page,
      status: 'PENDING',
    },
  )

  const columns = [
    {
      name: 'Nama',
      cell: (row) => {
        return <span>{row?.name}</span>
      },
    },
    {
      name: 'Nomor Telepon',
      cell: (row) => {
        return <span>{row?.phoneNumber}</span>
      },
    },
    {
      name: 'Status',
      cell: (row) => {
        return (
          <Badge
            className={
              row?.status === 'VERIFIED' ? 'badge-verify' : 'badge-danger'
            }
          >
            {row?.status === 'VERIFIED'
              ? 'Terverifikasi'
              : 'Belum Terverfikasi'}
          </Badge>
        )
      },
    },
    {
      name: '',
      cell: (row) => {
        return (
          <span
            style={{ cursor: 'pointer' }}
            className="fw-semibold text-center"
            onClick={() => navigate(`/farmers/${row?.userId}`)}
          >
            Lihat Detail
          </span>
        )
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {},
    },
    rows: {
      style: {},
    },
  }

  useEffect(() => {
    dispatch(clearState())
  }, [])

  return (
    <div className="w-100 px-3">
      <ModalChangeName />
      <Card className="mt-4">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.data?.items}
            progressPending={isLoading}
            responsive
            persistTableHead
            paginationTotalRows={data?.data?.totalItems}
            progressComponent={<Spinner />}
            sortIcon={<ChevronDown />}
            sortServer
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                Belum ada pengguna yang didaftarkan
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => {
              return (
                <CardFooter
                  className="border border-top-0"
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-sm">
                      {`Halaman ${page.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                    </div>
                    <div className="d-flex flex-centered gap-1">
                      <ButtonIcon
                        btnTitle="Sebelumnya"
                        btnProps={{
                          disabled: page.page === 0,
                          size: 'sm',
                          color: 'secondary',
                          onClick: () => {
                            setPage((prev) => ({ page: prev.page - 1 }))
                            dispatch(
                              setFetchOption({
                                page: page.page - 1,
                              }),
                            )
                          },
                        }}
                        btnTitleProps={{
                          className: 'text-sm',
                        }}
                      />
                      <ButtonIcon
                        btnTitle="Selanjutnya"
                        btnProps={{
                          disabled:
                            page.page ===
                            Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                          size: 'sm',
                          color: 'secondary',
                          onClick: () => {
                            setPage((prev) => ({ page: prev.page + 1 }))
                            dispatch(
                              setFetchOption({
                                page: page.page + 1,
                              }),
                            )
                          },
                        }}
                        btnTitleProps={{
                          className: 'text-sm',
                        }}
                      />
                    </div>
                  </div>
                </CardFooter>
              )
            }}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  {isLoading ? (
                    <Spinner color="primary" />
                  ) : (
                    <h5 className="fw-semibold">
                      {data?.data?.items[0]?.groupname ?? 'Grup Saya'}{' '}
                      <Edit2
                        size={16}
                        className="text-secondary"
                        style={{ cursor: 'pointer' }}
                        onClick={() => dispatch(toggleModal())}
                      />{' '}
                    </h5>
                  )}

                  <Button
                    onClick={() => navigate('/farmers/check')}
                    size="sm"
                    color="primary"
                  >
                    + Tambah Anggota
                  </Button>
                </div>
                <div>
                  <Alert
                    isOpen={isOpenBanner}
                    toggle={() => dispatch(toggleBanner())}
                    color="success"
                    className="d-flex align-items-center mt-4 w-100"
                  >
                    <span>
                      <CheckCircle size={17} /> Anggota berhasil ditambahkan.
                    </span>
                  </Alert>
                </div>
                <Row className="d-flex flex-row justify-content-start mb-3 align-items-stretch">
                  <Col lg="2" md="6" sm="12" className="d-flex">
                    <Card body className="w-100">
                      <CardText tag="small" className="fw-semibold">
                        Jumlah Anggota
                      </CardText>
                      <CardText tag="h3" className="text-primary mt-2">
                        {isLoading ? (
                          <Spinner color="primary" />
                        ) : (
                          data?.data?.items.length
                        )}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg="2" md="6" sm="12" className="d-flex">
                    <Card
                      onClick={() => navigate('/farmers/pending')}
                      body
                      style={{ cursor: 'pointer' }}
                      className="w-100"
                    >
                      <CardText tag="small" className="fw-semibold">
                        Menunggu Konfirmasi
                      </CardText>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <div className="text-primary h3">
                          {loadingPending ? (
                            <Spinner color="primary" />
                          ) : (
                            dataPending?.data?.items?.length
                          )}
                        </div>
                        <div>
                          <ArrowRightCircle size={20} />
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            }
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default FarmersTable
