import CheckIcon from 'assets/icons/check-circle-success.svg'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, CardTitle, Container, Input } from 'reactstrap'
import whatsApp from 'assets/icons/WhatsApp.png'
import uangBelanja from 'assets/icons/uang-belanja.png'
import uangUsaha from 'assets/icons/uang-usaha.png'
import { apiEndpoint } from '../../../utility/Utils'
import Total from '../Total'
import { setNumberStep, setTotal } from '../commodityPurchaseSlice'

const Payment = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()
  const { token } = useSelector((e) => e.auth)
  const { cashPayment } = useSelector((e) => e.dashboard)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [userData, setUserData] = useState({})
  const [isNotRegis, setisNotRegis] = useState(false)
  const [saldo, setSaldo] = useState({})
  const setNumber = (e) => dispatch(setNumberStep(e))
  const setTotalAmount = (e) => dispatch(setTotal(e))

  const handleClickPayment = (data) => {
    setPaymentMethod(data)
  }

  const handleSubmit = async () => {
    try {
      if (paymentMethod === 0) {
        const obj = {
          totalAmount: userData?.dataComodities?.reduce(
            (sum, e) => sum + e.totalPrice,
            0,
          ),
          loanDays: 0,
          creditRequested: 0,
          item: userData?.dataComodities?.map((e) => {
            return {
              comodity: e?.comodity,
              SKU: e?.comodity?.sku,
              amount: e?.quantity,
              price:
                typeof e?.price === 'string'
                  ? Number(
                      e?.price?.replace(/[^\d,]/g, '')?.replace?.(/\./g, ''),
                    )
                  : e?.price,
              unit: e?.unit?.value,
            }
          }),
        }
        const resp = await axios.post(
          `${apiEndpoint}/api/v1/bussines/transaction/calculate`,
          obj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        if (resp.status === 200) {
          navigate('/transaksi-pembelian-pembayaran-uang-belanja-konfirmasi')
          localStorage.setItem(
            'userData',
            JSON.stringify({
              ...userData,
              ...resp.data,
            }),
          )
        }
      } else {
        navigate('/transaksi-pembelian-pembayaran-talangin')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getSaldo = async () => {
    try {
      const { data } = await axios.get(`${apiEndpoint}/api/v1/bussines/saldo`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setSaldo(data)
      const userData = JSON.parse(localStorage.getItem('userData'))
      const obj = {
        ...userData,
        ...data,
      }
      localStorage.setItem('userData', JSON.stringify(obj))
    } catch (error) {
      console.log(error)
    }
  }

  const getTransaction = async (id) => {
    try {
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/transaction?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      if (data?.items?.[0]?.status === 'Menunggu Pembayaran Pembeli') {
        setisNotRegis(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const total = userData?.dataComodities?.reduce(
    (sum, e) => sum + e.totalPrice,
    0,
  )

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'))
    setUserData(userData)
    getSaldo()
    if (
      (userData?.dataSeller?.status === 'UNREGISTERED' ||
        userData?.dataSeller?.profileStatus === 'UNVERIFIED' ||
        !userData?.dataSeller?.isLogin) &&
      !userData?.alreadyReceived
    ) {
      setisNotRegis(true)
    }
    if (userData?.trxId) {
      getTransaction(userData?.trxId)
    }
    setNumber(2)
    setTotalAmount(
      userData?.dataComodities?.reduce((sum, e) => sum + e.totalPrice, 0),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Container fluid className="custom-container">
        {isNotRegis && (
          <Card className="mb-4">
            <CardBody>
              <div
                style={{
                  backgroundColor: '#ECFAFF',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: '#8ABBCC',
                }}
                className=" d-flex flex-row justify-content-between align-items-center p-3 rounded mb-3 "
              >
                <div className="d-flex flex-row gap-2 align-items-center">
                  <img
                    style={{ height: '32px', width: '32px' }}
                    src={whatsApp}
                    alt="whatsapp"
                  />
                  <span>
                    Sebelum anda melanjutkan pembayaran, penjual harus melakukan
                    konfirmasi di WhatsApp terlebih dahulu.
                  </span>
                </div>
              </div>
              <p className="text-primary fw-bold">
                Menunggu konfirmasi penjual
              </p>
              <div style={{ height: '7px' }} className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: '15%' }}
                  aria-valuenow="15"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </CardBody>
          </Card>
        )}
        <Card style={{ opacity: isNotRegis ? '40%' : '' }} className="p-2">
          <CardBody>
            <div className="mb-5">
              <div className="mb-3 d-flex justify-content-between">
                <span className="fw-bold">Daftar Pembelian</span>
                {userData?.alreadyReceived ? (
                  <>
                    {' '}
                    <div className="text-success-700 rounded bg-success-50 p-1">
                      {' '}
                      <small className="fw-500 mx-1">
                        <img src={CheckIcon} alt="check-icon" /> Barang sudah
                        diterima
                      </small>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {userData?.dataComodities?.map((e, index) => {
                return (
                  <div
                    key={index}
                    className="d-flex flex-row gap-3 align-items-center mb-3"
                  >
                    <img
                      src={e?.comodity?.img}
                      className="rounded float-left"
                      style={{ width: '60px', height: '60px' }}
                      alt="komoditas"
                    />
                    <div className="d-flex flex-column">
                      <span>
                        {e?.comodity?.label} {e?.quantity} {e?.unit?.label}
                      </span>
                      <span style={{ fontWeight: 'bold' }}>
                        Rp
                        {intl.formatNumber(e?.totalPrice, {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
            <div>
              <CardTitle
                style={{ fontWeight: 'bold' }}
                tag="h6"
                className="mb-3"
              >
                Pilih metode pembayaran
              </CardTitle>
              <div
                style={{
                  backgroundColor:
                    (userData?.dataSeller?.status === 'UNREGISTERED' ||
                      userData?.dataSeller?.profileStatus === 'UNVERIFIED') &&
                    userData?.alreadyReceived
                      ? '#F9FAFB'
                      : paymentMethod === 0
                        ? '#ECFAFF'
                        : '',
                  opacity:
                    (userData?.dataSeller?.status === 'UNREGISTERED' ||
                      userData?.dataSeller?.profileStatus === 'UNVERIFIED') &&
                    userData?.alreadyReceived
                      ? '50%'
                      : '',
                }}
                className={`border d-flex flex-row justify-content-between align-items-center p-3 rounded mb-3 ${
                  paymentMethod === 0 ? 'border-primary' : ''
                }`}
                onClick={() => {
                  if (!isNotRegis && total <= saldo?.wallet1) {
                    handleClickPayment(0)
                  }
                }}
              >
                <div className="d-flex flex-row gap-2">
                  <img
                    style={{ height: '32px', width: '32px' }}
                    src={uangBelanja}
                    alt="talangin"
                  />
                  <div className="d-flex flex-column">
                    <span style={{ fontWeight: 'bold' }}>Uang Belanja</span>
                    <span
                      className={total > saldo?.wallet1 ? 'text-danger' : ''}
                    >
                      {total > saldo?.wallet1 ? 'Kurang' : 'Tersedia'}: Rp
                      {intl.formatNumber(
                        total > saldo?.wallet1
                          ? total - saldo?.wallet1
                          : (saldo?.wallet1 ?? 0),
                        {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        },
                      )}
                    </span>
                  </div>
                </div>
                {total > saldo?.wallet1 ? (
                  <Button
                    outline
                    color="primary"
                    size="sm"
                    onClick={() =>
                      navigate('/topup', {
                        state: { trx: true },
                      })
                    }
                  >
                    Tambah Uang Belanja
                  </Button>
                ) : (
                  <Input
                    type="checkbox"
                    checked={paymentMethod === 0}
                    onChange={() => {
                      if (!isNotRegis && total <= saldo?.wallet1) {
                        handleClickPayment(0)
                      }
                    }}
                    disabled={
                      (userData?.dataSeller?.status === 'UNREGISTERED' ||
                        userData?.dataSeller?.profileStatus === 'UNVERIFIED') &&
                      userData?.alreadyReceived
                    }
                  />
                )}
              </div>
              <div
                style={{
                  backgroundColor: !saldo?.talangin
                    ? '#F9FAFB'
                    : paymentMethod === 1
                      ? '#ECFAFF'
                      : '',
                  opacity: !saldo?.talangin ? '50%' : '',
                }}
                className={`border d-flex flex-row justify-content-between align-items-center p-3 rounded mb-3 ${
                  paymentMethod === 1 ? 'border-primary' : ''
                }`}
                onClick={() => {
                  if (!isNotRegis && saldo?.talangin) {
                    handleClickPayment(1)
                  }
                }}
              >
                <div className="d-flex flex-row gap-2">
                  <img
                    style={{ height: '32px', width: '32px' }}
                    src={uangUsaha}
                    alt="talangin"
                  />
                  <div className="d-flex flex-column">
                    <span style={{ fontWeight: 'bold' }}>
                      Talangin{' '}
                      <small
                        style={{ backgroundColor: '#FEDF89' }}
                        className="badge rounded-pill text-dark"
                      >
                        LEBIH HEMAT
                      </small>{' '}
                    </span>
                    <span>
                      Tersedia: Rp
                      {intl.formatNumber(saldo?.talangin ?? 0, {
                        useGrouping: 'always',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  </div>
                </div>
                <Input
                  type="checkbox"
                  checked={paymentMethod === 1}
                  onChange={() => {
                    if (!isNotRegis && saldo?.talangin) {
                      handleClickPayment(1)
                    }
                  }}
                  disabled={!saldo?.talangin}
                />
              </div>

              {cashPayment || userData?.alreadyReceived ? (
                <span className="text-primary-600 fw-500">
                  Pembayaran tunai atau sudah bayar?{' '}
                  <u
                    onClick={() =>
                      navigate('/transaksi-pembelian-cash', {
                        state: {
                          totalAmount: userData?.dataComodities?.reduce(
                            (sum, e) => sum + e.totalPrice,
                            0,
                          ),
                          item: userData?.dataComodities?.map((e) => {
                            return {
                              comodity: e?.comodity,
                              amount: e?.quantity,
                              price: e?.price,
                              unit: e?.unit?.value,
                            }
                          }),
                        },
                      })
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    Klik di sini
                  </u>
                </span>
              ) : (
                <></>
              )}
            </div>
          </CardBody>
        </Card>
      </Container>
      <Total
        text="Lanjutkan Pembayaran"
        isDisable={isNotRegis || paymentMethod === null}
        handleClick={handleSubmit}
        amount={total}
      />
    </div>
  )
}

export default Payment
