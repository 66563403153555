import IPhoto from 'assets/icons/Pak-Tani.png'
import { ButtonIcon } from 'components/Buttons'
import LoadingAnimation from 'components/LoadingAnimation'
import { useState } from 'react'
import DataTable from 'react-data-table-component'
import { ChevronDown } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, CardBody, CardFooter, Container } from 'reactstrap'

import { setPage as setPageRedux } from '../settingSlice'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { Navigate } from 'react-router-dom'
import { URL_406 } from 'utils/pathUrl'
import useGetTeamsAvatarQuery from 'hooks/useGetTeamsAvatarQuery'

const TeamSetting = ({ action, subject }) => {
  const ability = useAbilityAccess({ action, subject })
  const dispatch = useDispatch()
  const { teamTable } = useSelector((s) => s.setting)

  let [page, setPage] = useState({ page: teamTable?.page })

  const teams = useGetTeamsAvatarQuery({ ...teamTable, ...page })

  const columns = [
    {
      name: 'Nama',
      cell: (row) => {
        return (
          <div className="flex-centered gap-2">
            <>
              <img
                src={row?.personAvatar ?? IPhoto}
                alt="pak tani"
                width={20}
                height={20}
                className="rounded-circle"
              />
              <span>{row?.name}</span>
            </>
          </div>
        )
      },
    },
    {
      name: 'Email',
      cell: (row) => <span>{row?.email}</span>,
    },
    {
      name: 'Peran',
      cell: (row) => <span>{row?.rolesName}</span>,
    },
    {
      name: 'Status',
      cell: (row) => {
        return !row?.active ? (
          <Badge className="badge-danger">Nonaktif</Badge>
        ) : (
          <Badge className="badge-verify">Aktif</Badge>
        )
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {},
    },
    rows: {
      style: {},
    },
  }

  if (!ability) {
    return <Navigate to={URL_406} replace={true} />
  }

  return (
    <div className="px-3">
      <h5 className="mx-3 mt-3">Tim Saya</h5>
      <p className="text-muted mx-3">
        Daftar anggota yang bergabung dengan tim
      </p>
      <hr className="mx-3 text-muted" />
      <CardBody>
        <DataTable
          columns={columns}
          data={teams?.data?.items}
          progressPending={teams?.isLoading}
          responsive
          persistTableHead
          paginationTotalRows={teams?.data?.totalItems}
          progressComponent={<LoadingAnimation />}
          sortIcon={<ChevronDown />}
          sortServer
          className="border p-0 border-1 rounded-top"
          customStyles={customStyles}
          noDataComponent={
            <span className="my-1">
              Belum ada data. Buat transaksi untuk memulai.
            </span>
          }
          pagination
          paginationServer
          paginationComponent={(props) => {
            return (
              <CardFooter
                className="border border-top-0"
                style={{ backgroundColor: 'transparent' }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-sm">
                    {`Halaman ${page.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                  </div>
                  <div className="flex-centered gap-1">
                    <ButtonIcon
                      btnTitle="Sebelumnya"
                      btnProps={{
                        disabled: page.page === 0,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          setPage((prev) => ({ page: prev.page - 1 }))
                          dispatch(setPageRedux({ page: page.page - 1 }))
                        },
                      }}
                      btnTitleProps={{
                        className: 'text-sm',
                      }}
                    />
                    <ButtonIcon
                      btnTitle="Selanjutnya"
                      btnProps={{
                        disabled:
                          page.page ===
                          Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          setPage((prev) => ({ page: prev.page + 1 }))
                          dispatch(setPageRedux({ page: page.page + 1 }))
                        },
                      }}
                      btnTitleProps={{
                        className: 'text-sm',
                      }}
                    />
                  </div>
                </div>
              </CardFooter>
            )
          }}
        />
      </CardBody>
    </div>
  )
}

export default TeamSetting
