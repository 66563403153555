import { createAppSlice } from 'app/createAppSlice'

export const initialState = {
  data: null,
}

export const connectAccountSlice = createAppSlice({
  name: 'connect-account',
  initialState,
  reducers: (create) => ({
    setData: create.reducer((state, { payload }) => {
      state.data = payload
    }),
  }),
  extraReducers: (builder) => {},
})
export const { resetAlert, setData } = connectAccountSlice.actions
export default connectAccountSlice.reducer
