import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, ModalBody } from 'reactstrap'
import { toggleModalDeleteFile } from './detailSlice'
import ICWarning from 'assets/icons/warning-icon.svg'
import { useLazyFetchFarmersDetailQuery } from '../farmers-list/farmersListApiSlice'
import { useDeleteMultimediaMutation } from './detailFarmersApiSlice'
import { useState } from 'react'
import { AlertCircle } from 'react-feather'

const ModalDeleteFile = ({ selectedFile, row, setIsFinishRemove }) => {
  const dispatch = useDispatch()
  const [errMsg, setErrMsg] = useState('')
  const { isOpenModalDeleteFile } = useSelector((s) => s.farmersDetail)
  const [fetchDetailFarmers] = useLazyFetchFarmersDetailQuery()
  const [deleteFile, { isLoading: laodingEdit }] = useDeleteMultimediaMutation()

  const handleConfirmDelete = async () => {
    try {
      const res = await deleteFile({ id: selectedFile?.id }).unwrap()
      if (res) {
        dispatch(toggleModalDeleteFile())
        fetchDetailFarmers({ id: row?.userId })
        setIsFinishRemove(true)
      }
    } catch (error) {
      setErrMsg(error?.data?.message)
      console.log(error)
    }
  }

  return (
    <Modal
      centered
      toggle={() => dispatch(toggleModalDeleteFile())}
      isOpen={isOpenModalDeleteFile}
    >
      <ModalBody>
        <img src={ICWarning} alt="Warning Icon" />
        <h6 className="mt-4 fw-600">Hapus file ini?</h6>
        <small>File yang sudah dihapus tidak dapat dikembalikan.</small>
        {errMsg && (
          <div>
            <small className="text-danger">
              <AlertCircle size={15} /> {errMsg}
            </small>
          </div>
        )}
        <div className="d-flex gap-3 mt-4">
          <Button
            onClick={() => dispatch(toggleModalDeleteFile())}
            block
            outline
            color="secondary"
          >
            Batal
          </Button>
          <Button onClick={handleConfirmDelete} block color="danger">
            Hapus
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalDeleteFile
