import axios from 'axios'
import ButtonIcon from 'components/Buttons/ButtonIcon'
import LoadingAnimation from 'components/LoadingAnimation'
import {
  logout,
  setAlertMsg,
  toggleAlert,
} from 'features/public-views/login/loginSlice'
import { useEffect, useState } from 'react'
import { AlertCircle, Eye, EyeOff } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  CardBody,
  CardText,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap'
import { apiEndpoint } from 'utility/Utils'

const PasswordSetting = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { token } = useSelector((s) => s.auth)
  const [showPassword, setShowPassword] = useState(false)
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isMatch, setIsMatch] = useState(false)
  const [hasType, setHasType] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword)
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleChangeOldPass = (e) => {
    setOldPassword(e.target.value)
    setErrMsg('')
  }

  const handleChangeNewPass = (e) => {
    setPassword(e.target.value)
    setErrMsg('')
  }

  const handleConfirmPass = (e) => {
    setConfirmPassword(e.target.value)
    setErrMsg('')
  }

  const handleChangePass = async () => {
    try {
      if (isMatch) {
        setLoading(true)
        const res = await axios.post(
          `${apiEndpoint}/api/v1/bussines/auth/changepassword`,
          {
            newpassword: password,
            oldpassword: oldPassword,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        )
        if (res.status === 200) {
          setPassword('')
          setOldPassword('')
          setConfirmPassword('')
          dispatch(logout())
          dispatch(toggleAlert(true))
          dispatch(
            setAlertMsg(
              'Anda telah mengganti password. Silakan login ulang untuk melanjutkan.',
            ),
          )
          navigate('/login')
        } else {
          setErrMsg('Terjadi kesalahan')
        }
      }
    } catch (error) {
      setErrMsg(error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (password === confirmPassword) {
      setIsMatch(true)
    } else {
      setIsMatch(false)
    }
    if (password !== '' && confirmPassword !== '') {
      setHasType(true)
    } else {
      setHasType(false)
    }
  }, [password, confirmPassword, oldPassword])

  return (
    <CardBody>
      <ListGroup flush>
        <ListGroupItem className="py-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <CardText tag="h4">Ganti password</CardText>
            </div>
            <div className="d-flex align-items-center gap-2">
              <ButtonIcon
                btnTitle="Batal"
                btnProps={{
                  className: 'w-auto',
                  size: 'sm',
                }}
              />
              <ButtonIcon
                btnTitle="Simpan"
                btnProps={{
                  className: 'w-auto',
                  outline: false,
                  size: 'sm',
                  disabled: loading || !hasType || !oldPassword || !isMatch,
                  onClick: handleChangePass,
                }}
              />
              {loading && <LoadingAnimation size="sm" />}
            </div>
          </div>
        </ListGroupItem>
        <ListGroupItem className="py-4">
          <Row>
            <Col sm="4">Password lama</Col>
            <Col sm="8">
              <FormGroup>
                <InputGroup>
                  <Input
                    style={{ maxWidth: '512px' }}
                    type={showOldPassword ? 'text' : 'password'}
                    onChange={(e) => {
                      handleChangeOldPass(e)
                    }}
                    value={oldPassword}
                    placeholder="Masukkan password lama"
                  />
                  <InputGroupText
                    style={{ borderLeft: 'none' }}
                    className="bg-transparent"
                  >
                    {showOldPassword ? (
                      <EyeOff
                        style={{ color: 'var(--grey-500)' }}
                        onClick={toggleOldPasswordVisibility}
                      />
                    ) : (
                      <Eye
                        style={{ color: 'var(--grey-500)' }}
                        onClick={toggleOldPasswordVisibility}
                      />
                    )}
                  </InputGroupText>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </ListGroupItem>
        <ListGroupItem className="py-4">
          <Row>
            <Col sm="4">Password baru</Col>
            <Col sm="8">
              <FormGroup>
                <InputGroup>
                  <Input
                    style={{ maxWidth: '512px' }}
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => {
                      handleChangeNewPass(e)
                    }}
                    value={password}
                    placeholder="Masukkan password baru"
                  />
                  <InputGroupText
                    style={{ borderLeft: 'none' }}
                    className="bg-transparent"
                  >
                    {showPassword ? (
                      <EyeOff
                        style={{ color: 'var(--grey-500)' }}
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <Eye
                        style={{ color: 'var(--grey-500)' }}
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </InputGroupText>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </ListGroupItem>
        <ListGroupItem className="py-4">
          <Row>
            <Col sm="4">Konfirmasi password baru</Col>
            <Col sm="8">
              <FormGroup>
                <InputGroup>
                  <Input
                    style={{ maxWidth: '512px' }}
                    type={showConfirmPassword ? 'text' : 'password'}
                    onChange={(e) => {
                      handleConfirmPass(e)
                    }}
                    value={confirmPassword}
                    valid={isMatch && hasType}
                    invalid={!isMatch && hasType}
                    placeholder="Masukkan ulang password baru"
                  />
                  <InputGroupText
                    style={{ borderLeft: 'none' }}
                    className="bg-transparent"
                  >
                    {showConfirmPassword ? (
                      <EyeOff
                        style={{ color: 'var(--grey-500)' }}
                        onClick={toggleConfirmPasswordVisibility}
                      />
                    ) : (
                      <Eye
                        style={{ color: 'var(--grey-500)' }}
                        onClick={toggleConfirmPasswordVisibility}
                      />
                    )}
                  </InputGroupText>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          {errMsg && (
            <FormGroup>
              <small className="text-danger">
                <AlertCircle size={15} /> {errMsg}
              </small>
            </FormGroup>
          )}
        </ListGroupItem>
      </ListGroup>
    </CardBody>
  )
}

export default PasswordSetting
