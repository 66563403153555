import { Input, InputGroup, InputGroupText } from 'reactstrap'
import { formatForFloatInput } from 'utils/formatForFloatInput'

const InputCurrencyWithLimit = ({
  id,
  name,
  value = '0',
  setValue,
  maxAmount,
  ...args
}) => {
  const handleChange = (e) => {
    let inputValue = e.target.value.trim()
    let newValue = inputValue === '' ? '0' : inputValue
    newValue = newValue.replace(/[^\d]/g, '')

    const formattedValue = (args) => {
      return new Intl.NumberFormat('id-ID').format(args)
    }

    let formatMaxAmount = formatForFloatInput(maxAmount, ',')

    if (parseInt(newValue) > parseInt(formatMaxAmount)) {
      setValue(formattedValue(formatMaxAmount))
    } else {
      setValue(formattedValue(newValue))
    }
  }

  return (
    <InputGroup>
      <InputGroupText
        style={{
          backgroundColor: 'transparent',
          borderRightColor: 'transparent',
        }}
        className="text-sm"
      >
        Rp
      </InputGroupText>
      <Input
        type="text"
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        style={{ borderLeftColor: 'transparent' }}
        autoComplete="off"
        {...args}
      />
    </InputGroup>
  )
}

export default InputCurrencyWithLimit
