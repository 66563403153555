import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  isOpenBanner: false,
  isOpenModalChangeProfile: false,
  isOpenModalUpload: false,
  isOpenModalDeleteFile: false,
}

export const detailFarmersSlice = createAppSlice({
  name: 'detailFarmers',
  initialState,
  reducers: (create) => ({
    toggleBannerDetail: create.reducer((state, { payload }) => {
      state.isOpenBanner = !state.isOpenBanner
    }),
    toggleModalChangeProfile: create.reducer((state, { payload }) => {
      state.isOpenModalChangeProfile = !state.isOpenModalChangeProfile
    }),
    toggleModalUpload: create.reducer((state, { payload }) => {
      state.isOpenModalUpload = !state.isOpenModalUpload
    }),
    toggleModalDeleteFile: create.reducer((state, { payload }) => {
      state.isOpenModalDeleteFile = !state.isOpenModalDeleteFile
    }),
  }),
  extraReducers: (builder) => {},
})

export const {
  toggleModalChangeProfile,
  toggleBannerDetail,
  toggleModalUpload,
  toggleModalDeleteFile,
} = detailFarmersSlice.actions

export default detailFarmersSlice.reducer
