import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiEndpoint } from 'configs'
import { logout } from 'features/public-views/login/loginSlice'

const baseQuery = fetchBaseQuery({
  baseUrl: apiEndpoint,
  prepareHeaders: (headers, { getState }) => {
    const { auth } = getState()
    if (auth.token) {
      headers.set('Authorization', `Bearer ${auth.token}`)
      // headers.set('ngrok-skip-browser-warning', `69420`)
    }
    return headers
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result?.error?.status === 403 || result?.error?.originalStatus === 403) {
    api.dispatch(logout())
  } else if (
    result?.error?.status === 406 ||
    result?.error?.originalStatus === 406
  ) {
    //
    console.log('not access')
  }

  return result
}

export const createApiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({}),
})
