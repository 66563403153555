/* eslint-disable eqeqeq */
export const formatForFloatInput = (value, decimalSymbol) => {
  let newValue = value.length > 0 ? value : '0'

  // const decimalSymbol = ((1.1).toLocaleString().indexOf('.') > 0) ? '.' : ','
  newValue = newValue.replace(new RegExp(`[^0-9${decimalSymbol}]`, 'g'), '')

  // remove unwanted decimal point symbol
  var valueArr = newValue.split(decimalSymbol)
  newValue =
    valueArr.shift() +
    (valueArr.length ? decimalSymbol + valueArr.join('') : '')

  // remove unwanted leading zero
  // const reLeadingZero = new RegExp(`(?:(-)(?![0.]+(?![\d.]))|-)?\d*?([1-9]\d*|0)(?:(?:(\.\d*[1-9])|\.)\d*)?(?![\d.])`, 'gm');
  // value = value.replace(reLeadingZero, '$1$2$3')

  // remove unwanted leading zero
  while (newValue.charAt(0) == '0') {
    if (newValue.length == 1 || newValue.charAt(1) == decimalSymbol) break
    newValue = newValue.substr(1)
  }
  return newValue
}
