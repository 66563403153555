import React, { useState } from 'react'
import { AlertCircle } from 'react-feather'
import { Button, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import { toggleModal } from './farmersListSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  useChangeGrupNameMutation,
  useFetchFarmersQuery,
} from './farmersListApiSlice'

const ModalChangeName = () => {
  const dispatch = useDispatch()
  const { isOpenModal, tag } = useSelector((s) => s.farmersList)
  let [page, setPage] = useState({ page: tag.page })
  const [name, setName] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [changeName, result] = useChangeGrupNameMutation()
  const { refetch } = useFetchFarmersQuery({
    page: page?.page,
    status: 'JOIN',
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { data } = await changeName({ name: name }).unwrap()
      if (data?.data?.meta?.error) {
        throw data
      } else {
        refetch()
        dispatch(toggleModal())
      }
    } catch (error) {
      setErrMsg(error?.data?.meta?.message)
    }
  }

  return (
    <Modal
      className="p-4"
      centered
      isOpen={isOpenModal}
      toggle={() => {
        dispatch(toggleModal())
        setErrMsg('')
      }}
      autoFocus={false}
    >
      <ModalBody>
        <h5>Masukkan nama grup</h5>
        <p className="text-muted">
          Anda bisa mengganti judul halaman ini dengan nama grup anda.
        </p>
        <FormGroup>
          <Label>Nama grup</Label>
          <Input
            autoFocus={true}
            value={name ?? ''}
            onChange={(e) => {
              setName(e.target.value)
              setErrMsg('')
            }}
            placeholder="Contoh: Anggota Jakarta Selatan"
          />
        </FormGroup>
        {errMsg && (
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        )}
        <div className="d-flex gap-3">
          <Button
            onClick={() => {
              dispatch(toggleModal())
              setErrMsg('')
            }}
            disabled={result?.isLoading}
            color="primary"
            outline
            block
          >
            Batal
          </Button>
          <Button
            disabled={result?.isLoading}
            onClick={handleSubmit}
            color="primary"
            block
          >
            Selesai
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalChangeName
