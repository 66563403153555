import { createApiSlice } from 'app/createApiSlice'

export const detailFarmersApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'farmers/detail',

  endpoints(builder) {
    return {
      uploadFile: builder.mutation({
        query({ url, userId, source, description }) {
          return {
            url: `/api/v1/bussines/upload-multimedia`,
            method: 'POST',
            body: {
              url,
              userId,
              source,
              description,
            },
          }
        },
      }),
      editDescription: builder.mutation({
        query({ description, userId }) {
          return {
            url: `api/v1/bussines/group/post-description/${userId}`,
            method: 'POST',
            body: {
              description,
            },
          }
        },
      }),
      deleteMultimedia: builder.mutation({
        query({ id }) {
          return {
            url: `api/v1/bussines/upload-multimedia/${id}`,
            method: 'DELETE',
          }
        },
      }),
    }
  },
})

export const {
  useUploadFileMutation,
  useEditDescriptionMutation,
  useDeleteMultimediaMutation,
} = detailFarmersApiSlice
