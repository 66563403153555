import { useEffect, useState } from 'react'
import { Edit2, Search, Trash2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  Spinner,
} from 'reactstrap'
import {
  toggleModalAdjustContactsReceivable,
  toggleModalDeleteContactReceivable,
} from '../receivable-notes/receivableNotesSlice'
import ModalAdjustSavedContact from './ModalAdjustSavedContact'
import ModalDeleteContact from './ModalDeleteContact'
import { useLazyGetRelationsQuery } from './payableNotesApiSlice'
import {
  toggleModalAdjustContacts,
  toggleModalDeleteContact,
} from './payableNotesSlice'

const ModalSavedContacts = ({ isOpen, toggle, cb, isReceivable }) => {
  const dispatch = useDispatch()
  const [contact, setContact] = useState(null)
  const [data, setData] = useState([])
  const [search, setSearch] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const { ownerData } = useSelector((s) => s.dashboard)
  const { isOpenModalAdjustContacts, isOpenModalDeleteContact } = useSelector(
    (state) => state.payableNotes,
  )
  const {
    isOpenModalAdjustContacts: isOpenModalAdjustContactsReceivable,
    isOpenModalDeleteContact: isOpenModalDeleteContactReceivable,
  } = useSelector((state) => state.receivableNotes)

  const [getRelations, res] = useLazyGetRelationsQuery()

  const handleEditCategory = () => {
    if (!isEdit) {
      setIsEdit(true)
      setContact(null)
    } else {
      setIsEdit(false)
      setContact(null)
    }
  }

  const handleSearch = async () => {
    try {
      const res = await getRelations({
        userId: ownerData?.userId,
        s: search ?? '',
      })
      setData(res?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (ownerData && isOpen) {
      handleSearch()
    }
  }, [ownerData, isOpen])

  useEffect(() => {
    if (search !== null) {
      const delayDebounceFn = setTimeout(() => {
        handleSearch()
      }, 1000)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [search])

  return (
    <>
      <ModalAdjustSavedContact
        isReceivable={isReceivable}
        isOpen={
          isReceivable
            ? isOpenModalAdjustContactsReceivable
            : isOpenModalAdjustContacts
        }
        toggle={() => {
          dispatch(
            isReceivable
              ? toggleModalAdjustContactsReceivable()
              : toggleModalAdjustContacts(),
          )
        }}
        row={contact}
        handleEdit={handleSearch}
      />
      <ModalDeleteContact
        isReceivable={isReceivable}
        isOpen={
          isReceivable
            ? isOpenModalDeleteContactReceivable
            : isOpenModalDeleteContact
        }
        toggle={() => {
          dispatch(
            isReceivable
              ? toggleModalDeleteContactReceivable()
              : toggleModalDeleteContact(),
          )
        }}
        row={contact}
        handleDelete={handleSearch}
      />
      <Modal
        centered
        isOpen={isOpen}
        toggle={() => {
          toggle()
          setErrMsg('')
        }}
      >
        <ModalBody>
          <div className="d-flex justify-content-between">
            <h5>Daftar tersimpan</h5>
            <div
              onClick={handleEditCategory}
              className="fw-600"
              style={{ cursor: 'pointer' }}
            >
              {isEdit ? (
                <span className="text-success">Selesai</span>
              ) : (
                <span className="text-primary">Ubah</span>
              )}
            </div>
          </div>
          <FormGroup>
            <InputGroup>
              <InputGroupText className="bg-transparent text-secondary">
                <Search size={20} />
              </InputGroupText>
              <Input
                id="search"
                name="search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
                placeholder="Cari nama atau nomor handphone"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <div
              className="d-flex flex-column gap-2"
              style={{ maxHeight: '400px', overflowY: 'auto' }}
            >
              {res?.isLoading || res?.status === 'pending' ? (
                <div className="w-100 d-flex justify-content-center">
                  <Spinner />
                </div>
              ) : (
                <>
                  {data?.length > 0 ? (
                    data?.map((e, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() => {
                            if (!isEdit) {
                              setContact(e)
                              cb(e)
                              toggle()
                            }
                          }}
                          className={`d-flex justify-content-between align-items-start border rounded p-3`}
                        >
                          <div className="d-flex gap-2">
                            {!isEdit ? (
                              <Input
                                type="radio"
                                id=""
                                value={contact ?? ''}
                                name="contacts"
                                checked={contact?.id === e?.id}
                                onChange={() => {
                                  setContact(e)
                                  cb(e)
                                  toggle()
                                }}
                              />
                            ) : (
                              <></>
                            )}
                            <div className="d-flex flex-column">
                              <span>{e?.relationName}</span>
                              <span>{e?.relationPhoneNumber}</span>
                            </div>
                          </div>
                          {isEdit ? (
                            <div className="d-flex gap-3">
                              <Edit2
                                onClick={() => {
                                  setContact(e)
                                  dispatch(
                                    isReceivable
                                      ? toggleModalAdjustContactsReceivable()
                                      : toggleModalAdjustContacts(),
                                  )
                                }}
                                style={{ cursor: 'pointer' }}
                                size={16}
                              />
                              <Trash2
                                onClick={() => {
                                  setContact(e)
                                  dispatch(
                                    isReceivable
                                      ? toggleModalDeleteContactReceivable()
                                      : toggleModalDeleteContact(),
                                  )
                                }}
                                style={{ cursor: 'pointer' }}
                                size={16}
                                className="text-danger"
                              />
                            </div>
                          ) : (
                            <>
                              {e?.ownVerifiedProfile ? (
                                <div className="badge rounded-pill flex-centered bg-success-50 text-success">
                                  Terdaftar
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </div>
                      )
                    })
                  ) : (
                    <>Tidak ada daftar tersimpan.</>
                  )}
                </>
              )}
            </div>
          </FormGroup>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModalSavedContacts
