import { useRef, useState } from 'react'
import { Alert, Button, Card, Col, Modal, ModalBody, Row } from 'reactstrap'

// components
import ICUpload from 'assets/icons/ic-upload.svg'
import ICXAlert from 'assets/icons/ic-x-alert.svg'

import CardPDF from 'features/private-views/commodity-repo/components/CardPDF'
import { useDispatch, useSelector } from 'react-redux'
import { useLazyFetchFarmersDetailQuery } from '../farmers-list/farmersListApiSlice'
import { useUploadFileMutation } from './detailFarmersApiSlice'
import { toggleModalUpload } from './detailSlice'
import usePartialUpload from 'hooks/usePartialUpload'

const ModalUploadFile = ({ row, ...args }) => {
  const fileInputRef = useRef(null)
  const dispatch = useDispatch()
  const [dataImg, setDataImg] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [fileNames, setFileNames] = useState([])

  const [visibleErrorMsg, setVisibleErrorMsg] = useState(false)
  const [visiblePreviewImg, setVisiblePreviewImg] = useState(false)
  const [progress, setProgress] = useState(0)

  const [upload, { isLoading: uploadLoading }] = useUploadFileMutation()
  const [fetchDetailFarmers] = useLazyFetchFarmersDetailQuery()
  const { isOpenModalUpload } = useSelector((s) => s.farmersDetail)
  const { uploadFile } = usePartialUpload()

  const resetState = () => {
    setDataImg(null)
    setPreviewImage(null)
    setErrorMessage(null)
    setVisibleErrorMsg(false)
    setVisiblePreviewImg(false)
    setFileNames([])
  }

  const onDismissPreviewImg = () => {
    setPreviewImage(null)
    setVisiblePreviewImg(false)
    setDataImg(null)
  }

  const fnCloseErrorMsg = () => {
    setVisibleErrorMsg(false)
    setErrorMessage(null)
  }

  const handleUploadClick = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = (event) => {
    fnCloseErrorMsg()
    onDismissPreviewImg()

    const file = event.target.files[0]

    if (file === undefined) {
      setDataImg(null)
      setErrorMessage('Anda belum menambahkan file.')
      setVisibleErrorMsg(true)
      return
    }

    const allowedTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      // 'application/pdf',
    ]
    if (!allowedTypes.includes(file.type)) {
      setVisibleErrorMsg(true)
      return setErrorMessage(`Hanya file JPG atau PNG yang diperbolehkan.`)
    }

    const maxSizeMB = 4
    const maxSizeBytes = maxSizeMB * 1024 * 1024
    if (file.size > maxSizeBytes) {
      setVisibleErrorMsg(true)
      return setErrorMessage(`Ukuran file melebihi batas ${maxSizeMB}MB.`)
    }

    const reader = new FileReader()
    reader.onload = () => {
      if (file.type === 'application/pdf') {
        setFileNames((prev) => [
          ...prev,
          { name: file.name, id: new Date().getTime() },
        ])
      } else {
        setPreviewImage(reader.result)
      }
      setVisiblePreviewImg(true)
    }
    reader.readAsDataURL(file)
    setDataImg(file)

    event.target.value = null
  }

  const fnUploadFile = async () => {
    if (dataImg === null) {
      setErrorMessage('Anda belum menambahkan file.')
      setVisibleErrorMsg(true)
      setPreviewImage(null)
      return
    }

    try {
      const data = await uploadFile(
        dataImg,
        (percentage) => setProgress(percentage),
        row?.userId,
        'PMB',
        true,
      )
      if (data?.url) {
        await upload({
          url: data?.url,
          userId: row?.userId,
          source: 'PMB',
          description: '',
        }).unwrap()
        fetchDetailFarmers({ id: row?.userId })
        resetState()
        setTimeout(() => dispatch(toggleModalUpload()), 300)
      }
    } catch (error) {
      console.log(error)
      setDataImg(null)
      setErrorMessage(error?.data?.message ?? error?.message)
      setVisibleErrorMsg(true)
      setPreviewImage(null)
      setFileNames([])
    }
  }

  return (
    <Modal
      isOpen={isOpenModalUpload}
      centered
      toggle={() => dispatch(toggleModalUpload())}
      {...args}
    >
      <ModalBody className="p-4">
        <div className="">
          <h5>Tambah file</h5>
        </div>
        {/* Error message */}
        {errorMessage !== null && (
          <Alert
            isOpen={visibleErrorMsg}
            toggle={fnCloseErrorMsg}
            color="danger"
            className="d-flex align-items-center gap-2 my-4"
          >
            <img src={ICXAlert} alt="alert icon" width={18} height={18} />
            <span>{errorMessage}</span>
          </Alert>
        )}
        {/* Image preview */}
        {previewImage !== null ? (
          <Alert
            className="p-3 my-4 d-flex justify-content-center align-items-center"
            isOpen={visiblePreviewImg}
            toggle={onDismissPreviewImg}
            style={{ backgroundColor: 'transparent' }}
            color="secondary"
          >
            <img
              src={previewImage}
              alt="Preview"
              style={{ maxWidth: '20rem', maxHeight: '20rem' }}
            />
          </Alert>
        ) : fileNames.length > 0 ? (
          <Row>
            {fileNames.map((data, i) => (
              <Col key={i} lg={12} md={12} sm={12} className="my-1">
                <CardPDF
                  name={data.name}
                  callBack={resetState}
                  fileIndex={data.id}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <Card
            className="p-3 my-4 d-flex justify-content-center align-items-center"
            onClick={handleUploadClick}
            style={{ cursor: 'pointer' }}
          >
            <img src={ICUpload} alt="icon" width={50} height={50} />
            <span className="text-primary fw-bolder">
              Klik untuk mengunggah
            </span>
            <span className="text-sm">{`JPG atau PNG (size maks. 4 MB)`}</span>
          </Card>
        )}

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept=".png, .jpeg, .jpg"
        />

        <div className="d-flex w-100 gap-2 mt-4">
          <Button
            color="secondary"
            outline
            className="w-50"
            onClick={() => {
              resetState()
              dispatch(toggleModalUpload())
            }}
            disabled={uploadLoading}
          >
            Batal
          </Button>{' '}
          <Button
            color="primary"
            className="w-50"
            onClick={fnUploadFile}
            disabled={uploadLoading || (progress < 100 && progress !== 0)}
          >
            Tambah File
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalUploadFile
