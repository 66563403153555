import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { Badge, Card, CardBody, CardFooter, CardText } from 'reactstrap'
import { ChevronDown } from 'react-feather'

import { ButtonIcon } from 'components/Buttons'
import BtnMakeTransaction from './BtnMakeTransaction'
import SidebarDashboard from '../SidebarDashboard'
import IPhoto from 'assets/icons/Pak-Tani.png'
import DataTable from 'react-data-table-component'
import LoadingAnimation from 'components/LoadingAnimation'
import {
  BUSINESS_TRANSACTION_PURCHASE,
  BUSINESS_TRANSACTION_SELL,
  READ,
} from 'utils/subjectActions'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { TRANSACTION_SELL_OR_BUY } from 'utils/tags'
import { useLocalStorageDashboard } from 'hooks/useLocalStorageDashboard'

import HeaderTable from './HeaderTable'
import useGetTrxDashboard from 'hooks/useGetTrxDashboard'

const TableDashboard = () => {
  const intl = useIntl()

  const { options, handlePrevPage, handleNextPage, handleType } =
    useLocalStorageDashboard(TRANSACTION_SELL_OR_BUY)

  const abilityBuy = useAbilityAccess({
    action: READ,
    subject: BUSINESS_TRANSACTION_PURCHASE,
  })

  const abilitySell = useAbilityAccess({
    action: READ,
    subject: BUSINESS_TRANSACTION_SELL,
  })

  const [isOpen, setIsOpen] = useState(false)
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [isRefetch, setIsRefetch] = useState(false)
  const handleCallback = () => {
    setIsRefetch(true)
  }
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const toggleSidebar = () => {
    setIsOpenSidebar(!isOpenSidebar)
  }

  const trx = useGetTrxDashboard(options, {
    skip: abilitySell && !abilityBuy ? true : false,
  })

  const columns = [
    {
      name: options?.type === 'SELL' ? 'Pembeli' : 'Penjual',
      cell: (row) => {
        return (
          <div className="flex-centered gap-2">
            {options?.type === 'BUY' && (
              <>
                <img
                  src={row?.sellerOrBuyAvatar ?? IPhoto}
                  className="rounded-circle"
                  alt="pak tani"
                  width={20}
                  height={20}
                />
                <span>{row.sellerDetail?.fullName}</span>
              </>
            )}
            {options?.type === 'SELL' && (
              <>
                <img
                  src={row?.sellerOrBuyAvatar ?? IPhoto}
                  className="rounded-circle"
                  alt="pak tani"
                  width={20}
                  height={20}
                />
                <span>{row.buyerDetail?.fullName}</span>
              </>
            )}
          </div>
        )
      },
    },
    {
      name: 'Komoditas',
      cell: (row) => (
        <div className="d-flex flex-column">
          <span className="flex-row">{`${row?.itemDetail?.[0]?.nameItem} • ${intl.formatNumber(
            row?.itemDetail?.[0]?.amount,
            {
              useGrouping: 'always',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            },
          )} ${row?.itemDetail?.[0]?.unit}`}</span>
          <span className="fw-light">
            {row?.itemDetail?.length > 1
              ? `dan ${intl.formatNumber(row?.itemDetail?.length - 1, {
                  useGrouping: 'always',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} komoditas lainnya`
              : ''}
          </span>
        </div>
      ),
    },
    {
      name: 'Total Transaksi',
      cell: (row) => (
        <span>
          Rp
          {intl.formatNumber(row?.totalAmount, {
            useGrouping: 'always',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </span>
      ),
    },
    {
      name: 'Dibuat oleh',
      cell: (row) => {
        return (
          <div className="flex-centered gap-2">
            <img
              src={row?.requesterAvatar ?? IPhoto}
              alt="pak tani"
              width={20}
              height={20}
              className="rounded-circle"
            />
            <span>
              {' '}
              {row?.requesterName
                ? row?.requesterName
                : row?.orderDetail?.type === 'BUY'
                  ? row?.buyerDetail?.fullName
                  : row?.sellerDetail?.fullName}
            </span>
          </div>
        )
      },
    },
    {
      name: 'Status',
      cell: (row) => {
        if (row.status?.toLowerCase() === 'selesai') {
          return (
            <Badge size="sm" disabled className="badge-verify">
              {row.status}
            </Badge>
          )
        }

        if (row.status?.toLowerCase() === 'menunggu proses penjual') {
          return (
            <Badge size="sm" disabled className="badge-warning">
              {row.status}
            </Badge>
          )
        }
        if (row.status?.toLowerCase() === 'menunggu approval admin') {
          return (
            <Badge size="sm" disabled className="badge-warning">
              {row.status}
            </Badge>
          )
        }

        if (row.status?.toLowerCase() === 'diproses') {
          return (
            <Badge size="sm" disabled className="badge-process">
              {row.status}
            </Badge>
          )
        }

        if (row.status?.toLowerCase() === 'dibatalkan') {
          return (
            <Badge size="sm" disabled className="badge-cancel">
              {row.status}
            </Badge>
          )
        }
        if (row.status?.toLowerCase() === 'menunggu pembayaran pembeli') {
          return (
            <Badge size="sm" disabled className="badge-warning">
              {row.status}
            </Badge>
          )
        }
      },
    },
    {
      name: '',
      selector: (row) => (
        <div
          onClick={() => {
            toggleSidebar()
            setSelectedRow(row)
          }}
          style={{ cursor: 'pointer' }}
        >
          Lihat Detail
        </div>
      ),
      center: true,
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {},
    },
    rows: {
      style: {},
    },
  }

  useEffect(() => {
    if (isRefetch) {
      trx?.refetch()
      setIsRefetch(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetch])

  return (
    <>
      <SidebarDashboard
        isOpen={isOpenSidebar}
        toggle={toggleSidebar}
        toggleModal={toggle}
        row={selectedRow}
        isOpenModal={isOpen}
        handleCallback={handleCallback}
        options={options}
      />
      <Card>
        <CardBody className="py-0 px-3 d-flex justify-content-between mt-4">
          <CardText tag="h4">Transaksi</CardText>
          <BtnMakeTransaction />
        </CardBody>
        <CardBody>
          {(abilityBuy || abilitySell) && (
            <DataTable
              customStyles={customStyles}
              columns={columns}
              data={trx?.data?.items}
              progressPending={trx?.isLoading || trx?.isFetching}
              responsive
              persistTableHead
              paginationTotalRows={trx?.data?.totalItems}
              progressComponent={<LoadingAnimation />}
              sortIcon={<ChevronDown />}
              sortServer
              className="border p-0 border-1 rounded-top"
              noDataComponent={
                <span className="my-1">
                  Belum ada data. Buat transaksi untuk memulai.
                </span>
              }
              pagination
              paginationServer
              paginationComponent={(props) => {
                return (
                  <CardFooter
                    className="border border-top-0"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="text-sm">
                        {`Halaman ${options?.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                      </div>
                      <div className="flex-centered gap-1">
                        <ButtonIcon
                          btnTitle="Sebelumnya"
                          btnProps={{
                            disabled: options?.page === 0,
                            size: 'sm',
                            color: 'secondary',
                            onClick: () => handlePrevPage(),
                          }}
                          btnTitleProps={{
                            className: 'text-sm',
                          }}
                        />
                        <ButtonIcon
                          btnTitle="Selanjutnya"
                          btnProps={{
                            disabled:
                              options?.page ===
                              Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                            size: 'sm',
                            color: 'secondary',
                            onClick: () => handleNextPage(),
                          }}
                          btnTitleProps={{
                            className: 'text-sm',
                          }}
                        />
                      </div>
                    </div>
                  </CardFooter>
                )
              }}
              subHeader={true}
              subHeaderAlign={'center'}
              subHeaderComponent={
                <HeaderTable
                  abilityBuy={abilityBuy}
                  abilitySell={abilitySell}
                  handleType={handleType}
                  type={options?.type}
                />
              }
            />
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default TableDashboard
