import ApprovalPage from 'features/private-views/approval'
import PurchaseCommodity from 'features/private-views/purchase-commodity'
import DashboardView from 'features/private-views/dashboard'
import SellCommodityView from 'features/private-views/sell-commodity'
import SuccessTrx from 'features/private-views/sell-commodity/SuccessTrx'
import TalanginInvoice from 'features/private-views/talangin-invoice'
import ConfirmRollover from 'features/private-views/talangin-invoice/rollover/ConfirmRollover'
import Rollover from 'features/private-views/talangin-invoice/rollover/Rollover'
import Topup from 'features/private-views/topup'
import TransferBank from 'features/private-views/topup/TransferBank'
import Withdraw from 'features/private-views/withdraw'
import SettingView from 'features/private-views/settings'

import Transactions from 'private-views/transactions'
import AddComodity from 'private-views/transactions/AddComodity'
import SuccesTransaction from 'private-views/transactions/SuccesTransaction'
import ConfirmPayment from 'private-views/transactions/confirmPayment'
import Payment from 'private-views/transactions/payment'
import TalanginConfirm from 'private-views/transactions/talanginConfirm'

import {
  BUSINESS_MYAPPROVAL,
  BUSINESS_TALANGIN,
  BUSINESS_TOPUP,
  READ,
  BUSINESS_TRANSACTION_PURCHASE,
  BUSINESS_TRANSACTION_SELL,
  BUSINESS_WITHDRAWAL,
  MANAGE,
  BUSINESS_AUTOREPO,
} from 'utils/subjectActions'
import { settingRoutes } from './settingRoutes'
import CommodityRepo from 'features/private-views/commodity-repo'
import SubmissionRepo from 'features/private-views/commodity-repo/SubmissionRepo'
import ListRepo from 'features/private-views/commodity-repo/ListRepo'
import ApprovalTable from 'features/private-views/approval/ApprovalTable'
import TableRequestSell from 'features/private-views/approval/TableRequestSell'
import {
  ARUS_KAS,
  ARUS_KAS_FORM,
  URL_APPROVAL_BUY,
  URL_APPROVAL_SELL,
  URL_APPROVAL_WITHDRAWAL,
  URL_ARUS_KAS,
  URL_CHECK_FARMERS,
  URL_FARMERS,
  URL_FARMERS_DETAIL,
  URL_FARMERS_PENDING,
  URL_FARMERS_REGIST,
  URL_FORM_UTANG_PIUTANG,
  URL_PIUTANG,
  URL_TNC_PRIVATE,
  URL_UTANG,
  URL_UTANG_APPROVAL,
} from 'utils/pathUrl'
import TableRequestWithdrawal from 'features/private-views/approval/TableRequestWithdrawal'
import FarmersWeb from 'features/private-views/farmers-web'
import FarmersTable from 'features/private-views/farmers-web/farmers-list'
import FormRegis from 'features/private-views/farmers-web/forms'
import DetailFarmers from 'features/private-views/farmers-web/detail'
import { ManageStock } from 'features/public-views/manageStock'
import Spatial from 'features/public-views/spatial'
import CheckFarmers from 'features/private-views/farmers-web/check-farmers'
import FarmersPendingList from 'features/private-views/farmers-web/farmers-pending-list'
import ConfirmCashPayment from 'private-views/transactions/confirmCashPayment'
import CashFlow from 'features/private-views/cash-flow'
import FormCashFlow from 'features/private-views/cash-flow/FormCashFlow'
import CashFlowNotes from 'features/private-views/cash-flow-notes'
import PayableNotes from 'features/private-views/payable-notes'
import ReceivableNotes from 'features/private-views/receivable-notes'
import FormPayableNotes from 'features/private-views/payable-notes/FormPayableNotes'
import ApprovalList from 'features/private-views/payable-notes/ApprovalList'
import Tnc from 'features/private-views/tnc'

export const privateRouter = [
  {
    path: '/',
    element: <DashboardView />,
    action: READ,
    subject:
      BUSINESS_TALANGIN ||
      BUSINESS_TOPUP ||
      BUSINESS_TRANSACTION_PURCHASE ||
      BUSINESS_TRANSACTION_SELL ||
      BUSINESS_WITHDRAWAL,
    name: 'Dashboard',
  },
  {
    element: <Transactions />,
    children: [
      {
        path: '/transaksi-pembelian-tambah-komoditas',
        element: <AddComodity />,
      },
      {
        path: '/transaksi-pembelian-pembayaran',
        element: <Payment />,
      },
      {
        path: '/transaksi-pembelian-pembayaran-uang-belanja-konfirmasi',
        element: <ConfirmPayment />,
      },
      {
        path: '/transaksi-pembelian-pembayaran-talangin',
        element: <TalanginConfirm />,
      },
    ],
  },
  {
    path: '/transaksi-pembelian-cash',
    element: <ConfirmCashPayment />,
  },
  {
    path: '/transaksi-pembelian-sukses',
    element: <SuccesTransaction />,
  },
  {
    path: '/purchase-commodity',
    element: <PurchaseCommodity />,
  },
  {
    path: '/sell-commodity',
    element: <SellCommodityView />,
  },
  {
    path: '/sell-commodity-success',
    element: <SuccessTrx />,
  },
  {
    path: '/topup',
    element: <Topup action={MANAGE} subject={BUSINESS_TOPUP} />,
  },
  {
    path: '/topup-instruksi',
    element: <TransferBank action={READ} subject={BUSINESS_TOPUP} />,
  },
  {
    path: '/tagihan-talangin',
    element: <TalanginInvoice action={READ} subject={BUSINESS_TALANGIN} />,
  },
  {
    path: '/penarikan-uang',
    element: <Withdraw action={READ} subject={BUSINESS_WITHDRAWAL} />,
  },
  {
    path: '/perpanjang-tempo-pembayaran',
    element: <Rollover action={MANAGE} subject={BUSINESS_TALANGIN} />,
  },
  {
    path: '/perpanjang-tempo-pembayaran-konfirmasi',
    element: <ConfirmRollover action={MANAGE} subject={BUSINESS_TALANGIN} />,
  },
  {
    path: URL_APPROVAL_BUY,
    element: <ApprovalPage />,
    action: READ,
    subject: BUSINESS_MYAPPROVAL,
    name: 'Approval',
    children: [
      {
        index: true,
        element: <ApprovalTable />,
      },
      {
        path: URL_APPROVAL_SELL,
        element: <TableRequestSell />,
      },
      {
        path: URL_APPROVAL_WITHDRAWAL,
        element: <TableRequestWithdrawal />,
      },
    ],
  },
  {
    path: URL_FARMERS,
    element: <FarmersWeb />,
    children: [
      {
        index: true,
        element: <FarmersTable />,
      },
      {
        path: URL_FARMERS_REGIST,
        element: <FormRegis />,
      },
      {
        path: URL_FARMERS_DETAIL,
        element: <DetailFarmers />,
      },
      {
        path: URL_CHECK_FARMERS,
        element: <CheckFarmers />,
      },
      {
        path: URL_FARMERS_PENDING,
        element: <FarmersPendingList />,
      },
    ],
  },
  {
    element: <SettingView />,
    children: settingRoutes,
  },
  {
    element: <CommodityRepo />,
    action: MANAGE,
    subject: BUSINESS_AUTOREPO,
    name: 'Repo Komoditas',
    pathUrl: '/repo-komoditas/pengajuan',
    path: '/repo-komoditas',
    children: [
      {
        index: true,
        element: <SubmissionRepo />,
      },
      {
        path: 'list',
        element: <ListRepo />,
      },
    ],
  },
  {
    path: '/spatial',
    element: <Spatial />,
  },
  {
    path: '/manage-stock',
    element: <ManageStock />,
  },
  {
    path: ARUS_KAS,
    element: <CashFlow />,
  },
  {
    path: ARUS_KAS_FORM,
    element: <FormCashFlow />,
  },
  {
    path: URL_ARUS_KAS,
    element: <CashFlowNotes />,
  },
  {
    path: URL_UTANG,
    element: <PayableNotes />,
  },
  {
    path: URL_UTANG_APPROVAL,
    element: <ApprovalList />,
  },
  {
    path: URL_FORM_UTANG_PIUTANG,
    element: <FormPayableNotes />,
  },
  {
    path: URL_PIUTANG,
    element: <ReceivableNotes />,
  },
  {
    path: URL_TNC_PRIVATE,
    element: <Tnc />,
  },
]
