import { createApiSlice } from 'app/createApiSlice'

export const connectAccountApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'connect-account/api',

  endpoints(builder) {
    return {
      postValidateKey: builder.mutation({
        query(payload) {
          const { email, key } = payload
          return {
            url: `/api/v1/bussines/check-login-key`,
            method: 'POST',
            body: {
              email,
              key,
            },
          }
        },
      }),
    }
  },
})

export const { usePostValidateKeyMutation } = connectAccountApiSlice
