import { createAppSlice } from 'app/createAppSlice'
import { getFarmersDetail } from './farmersListAction'

const initialState = {
  isOpenSidebar: false,
  tag: {
    size: 10,
    page: 0,
  },
  isOpenBanner: false,
  details: null,
  isOpenModal: false,
}

export const farmersListSlice = createAppSlice({
  name: 'farmersList',
  initialState,
  reducers: (create) => ({
    toggleSidebarWithdraw: create.reducer((state, { payload }) => {
      state.isOpenSidebar = !state.isOpenSidebar
    }),
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size
      state.tag.page = payload.page
    }),
    toggleBanner: create.reducer((state, { payload }) => {
      state.isOpenBanner = !state.isOpenBanner
    }),
    toggleModal: create.reducer((state, { payload }) => {
      state.isOpenModal = !state.isOpenModal
    }),
  }),
  extraReducers: (builder) => {
    builder
      .addCase(getFarmersDetail.pending, (state) => {
        state.loading = true
      })
      .addCase(getFarmersDetail.fulfilled, (state, { payload }) => {
        state.loading = false
        state.details = payload
      })
      .addCase(getFarmersDetail.rejected, (state, { payload }) => {
        state.loading = false
      })
  },
})

export const {
  setFetchOption,
  toggleSidebarWithdraw,
  toggleBanner,
  toggleModal,
} = farmersListSlice.actions

export default farmersListSlice.reducer
