export const filterLatLong = (data) => {
  return data
    .map((location) => {
      if (location.plottingPoint && location.plottingPoint.coordinates) {
        return {
          lat: location.plottingPoint.coordinates[1],
          lng: location.plottingPoint.coordinates[0],
        }
      } else {
        console.error('Invalid location data:', location)
        return null
      }
    })
    .filter(Boolean)
}

export function getFileExtension(url) {
  const dotIndex = url.lastIndexOf('.')
  const queryIndex = url.indexOf('?', dotIndex)

  if (dotIndex === -1) {
    return null
  }
  if (queryIndex === -1) {
    return url.substring(dotIndex)
  } else {
    return url.substring(dotIndex, queryIndex)
  }
}

export function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}
