import { createApiSlice } from 'app/createApiSlice'

export const cashFlowApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'catatan/arus-kas',
  endpoints(builder) {
    return {
      getArusKas: builder.query({
        query({ startdate, enddate, filter, page }) {
          return {
            url: '/api/v2/bussines/cashflow',
            params: {
              startdate,
              page,
              enddate,
              filter,
              sortby: 'ts',
              sortdir: 'desc',
            },
          }
        },
      }),
      getCategoriesCashFlow: builder.query({
        query({ userId, type, active }) {
          return {
            url: '/api/v1/bussines/cashflow/categories',
            params: {
              userId,
              type,
              active,
            },
          }
        },
      }),
      postCashFlow: builder.mutation({
        query(payload) {
          return {
            url: `/api/v2/bussines/cashflow`,
            method: 'POST',
            body: payload,
          }
        },
      }),
      postCategorCashFlow: builder.mutation({
        query(payload) {
          return {
            url: `/api/v1/bussines/cashflow/customcategories`,
            method: 'POST',
            body: payload,
          }
        },
      }),
      deleteCashFlow: builder.mutation({
        query(payload) {
          return {
            url: `/api/v1/bussines/cashflow/delete`,
            method: 'POST',
            body: payload,
          }
        },
      }),
    }
  },
})

export const {
  useGetArusKasQuery,
  useLazyGetArusKasQuery,
  usePostCashFlowMutation,
  usePostCategorCashFlowMutation,
  useDeleteCashFlowMutation,
  useGetCategoriesCashFlowQuery,
  useLazyGetCategoriesCashFlowQuery,
} = cashFlowApiSlice
