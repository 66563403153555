import { useEffect, useState } from 'react'
import axios from 'axios'
import { apiEndpoint } from '../../utility/Utils'
import { Button, Container, Input } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import LoadingAnimation from '../../components/LoadingAnimation'
import LogoPasarMikro from 'assets/images/pmg-logo-beta.png'

const TncView = () => {
  const [htmlContent, setHtmlContent] = useState('')
  const [link, setLink] = useState('')
  const [email, setEmail] = useState('')
  const [agree, setAgree] = useState(false)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const fetchData = async () => {
    try {
      const dataRes = await axios.get(
        `${apiEndpoint}/api/v1/public/tnc?link=${link}&email=${encodeURIComponent(email)}`,
        {
          headers: {
            Accept: 'application/json',
            'User-Agent': 'PasarMIKRO',
            // 'ngrok-skip-browser-warning': '69420',
          },
        },
      )
      setHtmlContent(dataRes.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleVerif = async ({ userConsent }) => {
    try {
      setLoading(true)
      const resp = await axios.get(
        `${apiEndpoint}/api/v1/bussines/email-verification?link=${link}&userconsent=${userConsent}`,
      )
      if (resp.status === 200) {
        if (resp?.data?.status === 'VERIFIED') {
          navigate('/sukses-verifikasi')
        } else {
          fetchData()
        }
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const url = new URL(window.location)
    const params = new URLSearchParams(url.search)
    const linkValue = params.get('link')
    const emailValue = params.get('email')

    if (linkValue) {
      setLink(linkValue)
    }
    if (emailValue) {
      setEmail(emailValue)
    }
  }, [])

  useEffect(() => {
    if (link && email) {
      handleVerif({ userConsent: 0 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link, email])

  return (
    <>
      <div
        className="flex-centered flex-column m-0 m-auto"
        style={{
          overflow: 'auto',
          paddingLeft: '15px',
          paddingRight: '15px',
          height: '100vh',
        }}
      >
        <div className="d-flex justify-content-center align-items-center mt-4">
          <img
            width="177px"
            height="30px"
            src={LogoPasarMikro}
            // LogoPasarMikro
            alt="PasarMIKRO logo"
          />
        </div>
        <Container
          className="w-75 overflow-auto my-2"
          style={{ maxHeight: '50vh' }}
        >
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </Container>
        {htmlContent ? (
          <Container className="w-75">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center my-auto flex-row gap-2 mb-3">
                <Input
                  type="checkbox"
                  onChange={() => {
                    setAgree(!agree)
                  }}
                />{' '}
                <small>
                  Saya <span style={{ fontWeight: 'bold' }}>({email}) </span>
                  menyetujui syarat dan ketentuan yang berlaku.
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-start flex-row">
                <Button
                  color="primary"
                  onClick={() => {
                    handleVerif({ userConsent: 1 })
                  }}
                  disabled={!agree || loading}
                >
                  VERIFIKASI
                </Button>
                {loading ? <LoadingAnimation size="sm" /> : null}
              </div>
            </div>
          </Container>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default TncView
