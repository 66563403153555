import ICWarning from 'assets/icons/warning-icon.svg'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, ModalBody } from 'reactstrap'
import {
  useDeleteCashFlowMutation,
  useLazyGetCategoriesCashFlowQuery,
} from './cashFlowApiSlice'
import { toggleSidebarCashflow } from './cashFlowSlice'
import { useDeleteCategoryMutation } from '../payable-notes/payableNotesApiSlice'

const ModalDelete = ({
  row,
  isOpen,
  toggle,
  handleFinishedDelete,
  isCategory,
  isIncome,
}) => {
  const dispatch = useDispatch()
  const [errMsg, setErrMsg] = useState('')
  const [deleteCashFlow, result] = useDeleteCashFlowMutation()
  const [deleteCategory, result2] = useDeleteCategoryMutation()
  const [getCategories] = useLazyGetCategoriesCashFlowQuery()
  const { ownerData } = useSelector((s) => s.dashboard)

  const handleSubmit = async () => {
    try {
      let body = {}
      if (!isCategory) {
        body = {
          type: row?.type,
          offlineID: row?.offlineID,
        }
      } else {
        body = {
          id: row?.id,
        }
      }
      const resp = await (
        !isCategory ? deleteCashFlow(body) : deleteCategory(body)
      ).unwrap()
      if (!resp?.data?.error) {
        toggle()
        if (!isCategory) {
          dispatch(toggleSidebarCashflow())
          handleFinishedDelete()
        } else {
          getCategories({
            type: isIncome ? 'income' : 'expense',
            active: 1,
            userId: ownerData?.userId,
          })
        }
      } else {
        throw resp
      }
    } catch (error) {
      setErrMsg(error?.data?.message ?? error?.message)
      console.log(error)
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setErrMsg('')
    }
  }, [isOpen])

  return (
    <Modal centered toggle={toggle} isOpen={isOpen}>
      <ModalBody>
        <img src={ICWarning} alt="Warning Icon" />
        <h6 className="mt-4 fw-600">
          Hapus {isCategory ? 'kategori ini?' : 'catat manual'}
        </h6>
        <span>
          {!isCategory
            ? ' Apakah kamu yakin ingin menghapus pencatatan ini?. Jika kamu menghapus pencatatan ini, maka pencatatan akan hilang dan tidak bisa dikembalikan lagi.'
            : ''}
        </span>
        {errMsg && (
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        )}
        <div className="d-flex gap-3 mt-4">
          <Button onClick={toggle} block outline color="secondary">
            Batal
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={result?.isLoading || result2?.isLoading}
            block
            color="danger"
          >
            Hapus
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalDelete
