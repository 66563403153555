import CompanySetting from 'features/private-views/settings/CompanySetting'
import ProfileSetting from 'features/private-views/settings/ProfileSetting'
import PasswordSetting from 'features/private-views/settings/PasswordSetting'
import TeamSetting from 'features/private-views/settings/TeamSetting'
import { BUSINESS_COMPANY, MANAGE, READ } from 'utils/subjectActions'

export const settingRoutes = [
  {
    path: '/pengaturan-profile',
    element: <ProfileSetting />,
    name: 'Profil Saya',
    action: READ,
    subject: BUSINESS_COMPANY,
  },
  {
    path: '/pengaturan-perusahaan',
    element: <CompanySetting action={MANAGE} subject={BUSINESS_COMPANY} />,
    action: MANAGE,
    subject: BUSINESS_COMPANY,
    name: 'Perusahaan',
  },
  {
    path: '/pengaturan-tim',
    element: <TeamSetting action={MANAGE} subject={BUSINESS_COMPANY} />,
    action: MANAGE,
    subject: BUSINESS_COMPANY,
    name: 'Tim',
  },
  {
    path: '/pengaturan-password',
    element: <PasswordSetting />,
    name: 'Password',
    action: READ,
    subject: BUSINESS_COMPANY,
  },
]
