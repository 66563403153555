import { createAppSlice } from 'app/createAppSlice'
import {
  checkSellerInfo,
  createOrder,
  getComodities,
  uploadFile,
} from './sellCommodityAction'

const initialState = {
  addItemCount: [1],
  isOpenModal: false,
  orders: {
    isLoading: false,
    userId: null,
    item: [
      {
        orderItemId: 1,
        SKU: '',
        amount: '',
        imageUrl: '',
        nameItem: '',
        price: '',
        totalPrice: '',
        unit: {
          label: 'Kg',
          value: 'Kg',
        },
      },
    ],
    order: {
      type: 'SELL',
      notes: '',
      files: [],
    },
    buyer: null,
    seller: null,
    isSuccess: false,
    message: '',
  },
  comodities: [],
}

export const sellCommoditySlice = createAppSlice({
  name: 'sell/commodity',
  initialState,
  reducers: (create) => ({
    addItem: create.reducer((state) => {
      let count = state.addItemCount[state.addItemCount.length - 1] + 1
      state.orders.item = [
        ...state.orders.item,
        {
          orderItemId: count,
          SKU: '',
          amount: '',
          imageUrl: '',
          nameItem: '',
          price: '',
          totalPrice: '',
          unit: '',
        },
      ]
      state.addItemCount.push(count)
    }),
    removeItem: create.reducer((state, { payload }) => {
      const filteredArr = state.addItemCount.filter((item) => item !== payload)
      state.orders.item = state.orders.item.filter(
        (item) => item.orderItemId !== payload,
      )
      state.addItemCount = filteredArr
    }),
    toggleModal: create.reducer((state) => {
      state.isOpenModal = !state.isOpenModal
    }),
    stepOrder: create.reducer((state, { payload }) => {
      state.orders.order.type = payload.type
      state.orders.step = payload.step
    }),
    setOrdersPayload: create.reducer((state, { payload }) => {
      state.orders.item = payload
    }),
    setOrdersNote: create.reducer((state, { payload }) => {
      state.orders.notes = payload
    }),
    setOrderSeller: create.reducer((state, { payload }) => {
      state.orders.seller = payload
      state.orders.userId = payload.userId
    }),
    setRemoveDoc: create.reducer((state, { payload }) => {
      const filteredImg = state.orders.order.files.filter(
        (item, i) => i !== payload.id,
      )
      state.orders.order.files = filteredImg
    }),
    setInitStateSell: create.reducer((state) => {
      state.addItemCount = [1]
      state.isOpenModal = false
      state.orders = {
        ...state.orders,
        isLoading: false,
        userId: null,
        order: {
          type: 'SELL',
          notes: '',
          files: [],
        },
        buyer: null,
        seller: null,
        isSuccess: false,
        message: '',
      }
    }),
  }),
  extraReducers: (builder) => {
    builder
      .addCase(checkSellerInfo.pending, (state) => {
        state.orders.isLoading = true
      })
      .addCase(checkSellerInfo.fulfilled, (state, { payload }) => {
        state.orders.isLoading = false
        state.orders.buyer = payload
      })
      .addCase(checkSellerInfo.rejected, (state, { payload }) => {
        state.orders.isLoading = false
      })
      .addCase(createOrder.pending, (state) => {
        state.orders.isLoading = true
        state.orders.isSuccess = false
      })
      .addCase(createOrder.fulfilled, (state, { payload }) => {
        state.orders.isLoading = false
        state.orders.isSuccess = true
      })
      .addCase(createOrder.rejected, (state, { payloads }) => {
        state.orders.isLoading = false
        state.orders.isSuccess = false
      })
      .addCase(uploadFile.pending, (state) => {
        state.orders.isLoading = true
      })
      .addCase(uploadFile.fulfilled, (state, { payload }) => {
        let file = [...state.orders.order.files]
        file.push(payload.url)

        state.orders.isLoading = false
        state.orders.order.files = file
      })
      .addCase(uploadFile.rejected, (state, { payload }) => {
        state.orders.isLoading = false
      })
      .addCase(getComodities.fulfilled, (state, { payload }) => {
        state.comodities = payload
      })
  },
})

export const {
  addItem,
  removeItem,
  toggleModal,
  stepOrder,
  setOrdersPayload,
  setOrdersNote,
  setOrderBuyer,
  setOrderSeller,
  setRemoveDoc,
  setInitStateSell,
} = sellCommoditySlice.actions

export default sellCommoditySlice.reducer
