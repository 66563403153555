import React from 'react'
import { toggleModalPending } from './topupSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardBody, Modal } from 'reactstrap'
import { Phone } from 'react-feather'

const ModalPendingTopup = () => {
  const dispatch = useDispatch()
  const { isOpenModalPending } = useSelector((s) => s.topup)
  return (
    <Modal
      centered
      size="sm"
      isOpen={isOpenModalPending}
      toggle={() => dispatch(toggleModalPending())}
    >
      <Card>
        <CardBody className="d-flex flex-column align-items-center">
          <p className="fw-semibold">Transaksi Diproses</p>
          <p className="small">
            Transaksi anda sedang dalam proses. Kami akan memberi notifikasi
            apabila proses sudah selesai.
          </p>
          <Button
            block
            outline
            color="primary"
            onClick={() =>
              window.open('https://wa.me/+6285157570025', '_blank')
            }
          >
            <Phone size={17} /> <span> Hubungi CS</span>
          </Button>
        </CardBody>
      </Card>
    </Modal>
  )
}

export default ModalPendingTopup
