import RoundedComponent from 'components/Rounded'
import SidebarDetails from 'components/SidebarDetails'
import moment from 'moment-timezone'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Progress, Spinner } from 'reactstrap'
import { toggleModalAdjustReceivable } from '../receivable-notes/receivableNotesSlice'
import ModalAdjustPayable from './ModalAdjustPayable'
import { getDetailPayable } from './payableAction'
import { toggleModalAdjust } from './payableNotesSlice'

const SidebarPayableNotes = ({ row, isOpen, toggle, isReceivable }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { isOpenModalAdjust: isOpenModalAdjustReceivable } = useSelector(
    (state) => state.receivableNotes,
  )

  const {
    isOpenModalAdjust,
    detail: data,
    loading,
  } = useSelector((s) => s.payableNotes)

  useEffect(() => {
    if (row) {
      dispatch(getDetailPayable({ id: row?.id }))
    }
  }, [row])

  return (
    <>
      <ModalAdjustPayable
        row={data}
        isReceivable={isReceivable}
        isOpen={isReceivable ? isOpenModalAdjustReceivable : isOpenModalAdjust}
        toggle={() =>
          dispatch(
            isReceivable ? toggleModalAdjustReceivable() : toggleModalAdjust(),
          )
        }
      />
      <SidebarDetails
        width={400}
        open={isOpen}
        title={`Detail ${isReceivable ? 'Piutang' : 'Utang'}`}
        headerClassName="mb-1 justify-content-between"
        contentClassName="pt-0 w-100"
        toggleSidebar={toggle}
      >
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        ) : (
          <div className="d-flex gap-3 flex-column">
            {/* Status */}
            <div
              className={`rounded ${data?.manual ? 'badge-warning' : 'badge-process'} p-3 text-center d-block w-100 fw-600`}
            >
              {data?.manual ? 'Catat Manual' : 'Transaksi'}
            </div>

            {/* Kategori */}
            <div>
              <Card body>
                <span className="fw-600">Kategori</span>
                <span>{data?.category?.label}</span>
              </Card>
            </div>

            {/* Utang kepada */}
            <div>
              <Card body>
                <span className="fw-600">
                  {isReceivable ? 'Piutang' : 'Utang'} kepada
                </span>
                <span>
                  {data?.manual
                    ? isReceivable
                      ? data?.payerName
                      : data?.name
                    : isReceivable
                      ? data?.buyer?.name
                      : data?.seller?.name}
                </span>
              </Card>
            </div>

            {/* Sisa Utang */}
            <div>
              <Card body>
                <div className="d-flex justify-content-between">
                  <span className="fw-600">
                    Sisa {isReceivable ? 'piutang' : 'utang'}
                  </span>
                  <span>
                    Rp{' '}
                    {intl.formatNumber(
                      data?.amount ?? 0 - data?.amountPaid ?? 0,
                      {
                        useGrouping: 'always',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      },
                    )}
                  </span>
                </div>
                <div className="mt-3">
                  <span className="fw-500">
                    {isReceivable ? 'Sudah terima pembayaran' : 'Sudah Bayar'}
                  </span>
                  <Progress
                    color="success"
                    style={{ height: '8px' }}
                    className="my-1"
                    value={((data?.amountPaid ?? 0) * 100) / data?.amount ?? 0}
                  />
                  <span>
                    <span className="fw-600 text-gray-500">
                      Rp{' '}
                      {intl.formatNumber(data?.amountPaid ?? 0, {
                        useGrouping: 'always',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}{' '}
                    </span>
                    <span className="fw-400 text-gray-500">
                      dari Rp{' '}
                      {intl.formatNumber(data?.amount ?? 0, {
                        useGrouping: 'always',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  </span>
                  {(data?.amount ?? 0) === (data?.amountPaid ?? 0) && (
                    <div
                      style={{ backgroundColor: '#ECFDF3', color: '#027A48' }}
                      className="badge rounded-pill flex-centered mt-2 p-2"
                    >
                      Lunas
                    </div>
                  )}
                </div>
              </Card>
            </div>

            {/* Catatan */}
            <div>
              <Card body>
                <span className="fw-600">Catatan</span>
                <span>{data?.notes ? data?.notes : '-'}</span>
              </Card>
            </div>

            {/* Riwayat Pembayaran */}
            <div>
              <Card body>
                <span className="fw-600">Riwayat Pembayaran</span>
                {data?.history?.length > 0 ? (
                  data?.history?.map((e, i) => {
                    return (
                      <div
                        key={i}
                        className="d-flex flex-row justify-content-start align-items-center gap-2 my-2"
                      >
                        <RoundedComponent
                          childern={i + 1}
                          style={{
                            color: '#006386',
                            backgroundColor: '#C0E5F2',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                        <div className="d-flex flex-column">
                          <span>
                            {isReceivable ? 'Terima pembayaran' : 'Bayar'} Rp{' '}
                            {intl.formatNumber(e?.amountPaid ?? 0, {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                          </span>
                          <small className="text-secondary">
                            {' '}
                            {`(${moment(e?.createdAt).format('Do MMMM YYYY, HH:mm')})`}
                          </small>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <span>
                    Belum ada pembayaran {isReceivable ? 'yang diterima' : ''}
                  </span>
                )}
              </Card>
            </div>
            {data?.amount === data?.amountPaid ? (
              <></>
            ) : (
              <Button
                onClick={() =>
                  dispatch(
                    isReceivable
                      ? toggleModalAdjustReceivable()
                      : toggleModalAdjust(),
                  )
                }
                color="primary"
                block
              >
                {data?.manual
                  ? `Sesuaikan ${isReceivable ? 'Piutang' : 'Utang'}`
                  : isReceivable
                    ? 'Sesuaikan Piutang'
                    : 'Bayar Utang'}
              </Button>
            )}
          </div>
        )}
      </SidebarDetails>
    </>
  )
}

export default SidebarPayableNotes
