import { ButtonIcon } from 'components/Buttons'
import React from 'react'
import { CardFooter } from 'reactstrap'

export default function CustomPagination({ currentPage, totalPage, onClick }) {
  return (
    <CardFooter
      className="border border-top-0"
      style={{ backgroundColor: 'transparent' }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div>
          Halaman {currentPage + 1} of{' '}
          {totalPage === 0 ? totalPage + 1 : totalPage}
        </div>
        <div className="flex-centered gap-1">
          <ButtonIcon
            btnTitle="Sebelumnya"
            btnProps={{
              disabled: currentPage === 0,
              size: 'sm',
              color: 'secondary',
              onClick: () => onClick('back'),
            }}
            btnTitleProps={{
              className: 'fw-light fs-sm',
            }}
          />
          <ButtonIcon
            btnTitle="Selanjutnya"
            btnProps={{
              disabled:
                (currentPage + 1 === totalPage && totalPage === 1) ||
                currentPage === totalPage,
              size: 'sm',
              color: 'secondary',
              onClick: () => onClick('next'),
            }}
            btnTitleProps={{
              className: 'fw-light fs-sm',
            }}
          />
        </div>
      </div>
    </CardFooter>
  )
}
