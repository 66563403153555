import { useEffect, useRef, useState } from 'react'
import { getLocalStorage } from 'utils/getLocalStorage'

export const useLocalStorageTable = (tag) => {
  const tagInit = useRef(getLocalStorage(tag))

  const [page, setPage] = useState(tagInit.current?.page ?? 0)
  const [size] = useState(tagInit.current?.size ?? 10)

  const handlePrevPage = () => {
    setPage((prev) => prev - 1)
    tagInit.current = { ...tagInit.current, page: page - 1 }
  }

  const handleNextPage = () => {
    setPage((prev) => prev + 1)
    tagInit.current = { ...tagInit.current, page: page + 1 }
  }

  useEffect(() => {
    localStorage.setItem(
      tag,
      JSON.stringify({ page, size, ...tagInit.current }),
    )
  }, [tagInit, tag, page, size])

  return {
    options: { ...tagInit.current, page, size },
    handlePrevPage,
    handleNextPage,
  }
}
