import { useEffect, useState } from 'react'
import { ChevronDown, MapPin } from 'react-feather'
import { Button, Card, CardText, Input } from 'reactstrap'
import UploadImageCard from '../Card/UploadImageCard'

export default function AddStockSidebar({
  onChangeDataStock,
  dataStock,
  onShowItem,
  onMergeStock,
  loading,
  locationBlocked,
}) {
  const [isButtonDisabled, setButtonDisabled] = useState(false)

  useEffect(() => {
    if (
      dataStock?.amount &&
      dataStock?.linkFoto &&
      dataStock?.itemId &&
      dataStock.unit
    ) {
      setButtonDisabled(false)
    } else {
      setButtonDisabled(true)
    }
  }, [dataStock])
  return (
    <>
      {locationBlocked ? (
        <div className="regular-padding-horizontal">
          <Card
            className="small-padding"
            style={{ backgroundColor: '#F3FEFF', borderColor: '#8ABBCC' }}
          >
            <div className="d-flex">
              <MapPin className="small-margin-right" color="#006386" />
              <CardText style={{ fontSize: 14 }}>
                Untuk mencatat lokasi dari stok yang ditambahkan, harap aktifkan
                layanan lokasi di perangkat anda.
              </CardText>
            </div>
          </Card>
        </div>
      ) : null}

      <div className="col-space-between">
        <Card className="small-padding mt-3">
          <div>
            <div>
              <CardText className="no-padding">
                Komoditas
                <span style={{ color: '#b42218' }}>*</span>
              </CardText>
              <div className="bordered mt-2 d-flex justify-content-between align-items-center">
                <Input
                  style={{ borderColor: 'white', caretColor: 'transparent' }}
                  className="border-input button"
                  placeholder="Pilih komoditas"
                  onClick={() => {
                    onShowItem('item', true)
                  }}
                  value={dataStock?.nameItem}
                />
                <ChevronDown color="#667085" style={{ paddingRight: 10 }} />
              </div>
            </div>
            {dataStock?.nameItem ? (
              <div className="mt-4">
                <UploadImageCard
                  onChangeDataStock={onChangeDataStock}
                  uploadedImage={dataStock?.linkFoto}
                />
              </div>
            ) : null}
            <div className="d-flex justify-content-between">
              <div className="mt-4" style={{ width: '49%' }}>
                <CardText className="no-padding">
                  Jumlah<span style={{ color: '#b42218' }}>*</span>
                </CardText>
                <div className="bordered mt-2 d-flex justify-content-between align-items-center">
                  <Input
                    style={{ borderColor: 'white' }}
                    placeholder="Masukkan jumlah"
                    onChange={(text) =>
                      onChangeDataStock('amount', text.target.value)
                    }
                  />
                </div>
              </div>
              <div className="mt-4" style={{ width: '49%' }}>
                <CardText className="no-padding">
                  Satuan<span style={{ color: '#b42218' }}>*</span>
                </CardText>
                <div className="bordered mt-2 d-flex justify-content-between align-items-center">
                  <Input
                    style={{ borderColor: 'white', caretColor: 'transparent' }}
                    className="border-input button"
                    placeholder="Kg"
                    value={dataStock?.unit}
                    onClick={() => {
                      onShowItem('itemUnit', true)
                    }}
                  />
                  <ChevronDown color="#667085" style={{ paddingRight: 10 }} />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <CardText className="no-padding">Catatan</CardText>
              <div className="d-flex align-items-center">
                <div className="bordered mt-2" style={{ width: '100%' }}>
                  <Input
                    style={{ borderColor: 'white', height: 200 }}
                    className="border-input"
                    type="textarea"
                    placeholder="Cth: Kopi ini dirawat dengan baik oleh anggota berkualitas"
                    onChange={(text) =>
                      onChangeDataStock('description', text.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
        <div className="mt-4">
          <Button
            onClick={onMergeStock}
            disabled={isButtonDisabled || loading}
            style={{
              backgroundColor: '#006386',
              fontSize: 14,
              width: '100%',
            }}
            className="fw-semibold"
          >
            Selesai
          </Button>
        </div>
      </div>
    </>
  )
}
