import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  isOpenBanner: false,
  isOpenSidebar: false,
  tag: {
    size: 10,
    page: 0,
  },
  isOpenModalCategory: false,
  isOpenModalDelete: false,
  isOpenModalAdjust: false,
  isOpenModalContacts: false,
  isOpenModalAdjustContacts: false,
  isOpenModalDeleteContact: false,
}

export const receivableNotes = createAppSlice({
  name: 'receivableNotes',
  initialState,
  reducers: (create) => ({
    toggleBannerReceivable: create.reducer((state) => {
      state.isOpenBanner = !state.isOpenBanner
    }),
    toggleSidebarReceivable: create.reducer((state) => {
      state.isOpenSidebar = !state.isOpenSidebar
    }),
    toggleModalCategoryReceivable: create.reducer((state) => {
      state.isOpenModalCategory = !state.isOpenModalCategory
    }),
    toggleModalDeleteReceivable: create.reducer((state) => {
      state.isOpenModalDelete = !state.isOpenModalDelete
    }),
    toggleModalAdjustReceivable: create.reducer((state) => {
      state.isOpenModalAdjust = !state.isOpenModalAdjust
    }),
    toggleModalContactsReceivable: create.reducer((state) => {
      state.isOpenModalContacts = !state.isOpenModalContacts
    }),
    toggleModalAdjustContactsReceivable: create.reducer((state) => {
      state.isOpenModalAdjustContacts = !state.isOpenModalAdjustContacts
    }),
    toggleModalDeleteContactReceivable: create.reducer((state) => {
      state.isOpenModalDeleteContact = !state.isOpenModalDeleteContact
    }),
    setFetchOptionReceivable: create.reducer((state, { payload }) => {
      state.tag.size = payload.size
      state.tag.page = payload.page
    }),
  }),
  extraReducers: (builder) => {},
})

export const {
  toggleBannerReceivable,
  toggleSidebarReceivable,
  setFetchOptionReceivable,
  toggleModalCategoryReceivable,
  toggleModalDeleteReceivable,
  toggleModalAdjustReceivable,
  toggleModalContactsReceivable,
  toggleModalAdjustContactsReceivable,
  toggleModalDeleteContactReceivable,
} = receivableNotes.actions

export default receivableNotes.reducer
