import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
// Persist
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// Api Slice
import { approvalApiSlice } from 'features/private-views/approval/approvalApiSlice'
import { dashboardApiSlice } from 'features/private-views/dashboard/dashboardApiSlice'
import { purchaseCommodityApiSlice } from 'features/private-views/purchase-commodity/purchaseCommodityApiSlice'
import { requestTransactionApiSlice } from 'features/private-views/request-transaction/requestTransactionApiSlice'
import { settingApiSlice } from 'features/private-views/settings/settingApiSlice'
import { talanginInvoiceApiSlice } from 'features/private-views/talangin-invoice/talanginInvoiceApiSlice'
import { topupApiSlice } from 'features/private-views/topup/topupApiSlice'
import {
  publicApiDevSlice,
  publicApiSlice,
} from 'features/public-api/publicApiSlice'
import { loginApiSlice } from 'features/public-views/login/loginApiSlice'
import { withdrawApiSlice } from 'features/private-views/withdraw/withdrawApiSlice'
import { farmersListApiSlice } from 'features/private-views/farmers-web/farmers-list/farmersListApiSlice'
import { sellCommodityApiSlice } from 'features/private-views/sell-commodity/sellCommodityApiSlice'
import { formFarmersApiSlice } from 'features/private-views/farmers-web/forms/formApiSlice'
import { commodityRepoApiSlice } from 'features/private-views/commodity-repo/commodityRepoApiSlice'
import { checkFarmersApiSlice } from 'features/private-views/farmers-web/check-farmers/checkFarmerApiSlice'
import { farmersPendingListSlice } from 'features/private-views/farmers-web/farmers-pending-list/farmersPendingSlice'
import { cashFlowApiSlice } from 'features/private-views/cash-flow/cashFlowApiSlice'
import { detailFarmersApiSlice } from 'features/private-views/farmers-web/detail/detailFarmersApiSlice'
import { payableNotesApiSlice } from 'features/private-views/payable-notes/payableNotesApiSlice'
import { receivableApiSlice } from 'features/private-views/receivable-notes/receivableNotesApiSlice'
import { connectAccountApiSlice } from 'features/public-views/connect-account/connectAccountApiSlice'

// Reducers
import approvalSlice from 'features/private-views/approval/approvalSlice'
import dashboardReducer from 'features/private-views/dashboard/dashboardSlice'
import purchaseCommodityReducer from 'features/private-views/purchase-commodity/purchaseCommoditySlice'
import sellCommodityReducer from 'features/private-views/sell-commodity/sellCommoditySlice'
import talanginInvoiceSlice from 'features/private-views/talangin-invoice/talanginInvoiceSlice'
import talanginTable from 'features/private-views/talangin-invoice/talanginSlice'
import withdrawSlice from 'features/private-views/withdraw/withdrawSlice'
import loginReducer from 'features/public-views/login/loginSlice'
import requestTransactionReducer from 'features/public-views/order/requestTransactionSlice'
import commodityPurchaseSlice from 'private-views/transactions/commodityPurchaseSlice'
import settingSlice from 'features/private-views/settings/settingSlice'
import topupSlice from 'features/private-views/topup/topupSlice'
import farmersListSlice from 'features/private-views/farmers-web/farmers-list/farmersListSlice'
import detailFarmersSlice from 'features/private-views/farmers-web/detail/detailSlice'
import formFarmersSlice from 'features/private-views/farmers-web/forms/formSlice'
import cashFlowSlice from 'features/private-views/cash-flow/cashFlowSlice'
import connectAccountSlice from 'features/public-views/connect-account/connectAccountSlice'
import payableNotesSlice from 'features/private-views/payable-notes/payableNotesSlice'
import receivableNotesSlice from 'features/private-views/receivable-notes/receivableNotesSlice'
import tncSlice from 'features/private-views/tnc/tncSlice'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'dashboard'],
}

const rootReducer = combineReducers({
  auth: loginReducer,
  [loginApiSlice.reducerPath]: loginApiSlice.reducer,
  dashboard: dashboardReducer,
  [dashboardApiSlice.reducerPath]: dashboardApiSlice.reducer,
  withdraw: withdrawSlice,
  [withdrawApiSlice.reducerPath]: withdrawApiSlice.reducer,
  talanginTable: talanginTable,
  setting: settingSlice,
  topup: topupSlice,

  farmersList: farmersListSlice,
  [farmersListApiSlice.reducerPath]: farmersListApiSlice.reducer,
  farmersDetail: detailFarmersSlice,
  farmersForm: formFarmersSlice,
  [formFarmersApiSlice.reducerPath]: formFarmersApiSlice.reducer,
  [checkFarmersApiSlice.reducerPath]: checkFarmersApiSlice.reducer,
  farmersPending: farmersPendingListSlice,
  cashflow: cashFlowSlice,
  [detailFarmersApiSlice.reducerPath]: detailFarmersApiSlice.reducer,

  purchaseCommmodity: purchaseCommodityReducer,
  approval: approvalSlice,
  talanginInvoice: talanginInvoiceSlice,
  sellCommodity: sellCommodityReducer,
  reqTrxSell: requestTransactionReducer,
  comodityPurchase: commodityPurchaseSlice,
  payableNotes: payableNotesSlice,
  receivableNotes: receivableNotesSlice,
  [topupApiSlice.reducerPath]: topupApiSlice.reducer,
  [settingApiSlice.reducerPath]: settingApiSlice.reducer,
  [topupApiSlice.reducerPath]: topupApiSlice.reducer,
  [talanginInvoiceApiSlice.reducerPath]: talanginInvoiceApiSlice.reducer,
  [settingApiSlice.reducerPath]: settingApiSlice.reducer,
  [sellCommodityApiSlice.reducerPath]: sellCommodityApiSlice.reducer,
  [settingApiSlice.reducerPath]: settingApiSlice.reducer,
  [purchaseCommodityApiSlice.reducerPath]: purchaseCommodityApiSlice.reducer,
  [publicApiDevSlice.reducerPath]: publicApiDevSlice.reducer,
  [publicApiSlice.reducerPath]: publicApiSlice.reducer,
  [approvalApiSlice.reducerPath]: approvalApiSlice.reducer,
  [requestTransactionApiSlice.reducerPath]: requestTransactionApiSlice.reducer,
  [commodityRepoApiSlice.reducerPath]: commodityRepoApiSlice.reducer,
  [cashFlowApiSlice.reducerPath]: cashFlowApiSlice.reducer,
  [payableNotesApiSlice.reducerPath]: payableNotesApiSlice.reducer,
  [receivableApiSlice.reducerPath]: receivableApiSlice.reducer,
  connectAccount: connectAccountSlice,
  [connectAccountApiSlice.reducerPath]: connectAccountApiSlice.reducer,
  tnc: tncSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const makeStore = (preloadedState) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(
        topupApiSlice.middleware,
        settingApiSlice.middleware,
        purchaseCommodityApiSlice.middleware,
        publicApiDevSlice.middleware,
        publicApiSlice.middleware,
        approvalApiSlice.middleware,
        withdrawApiSlice.middleware,
        talanginInvoiceApiSlice.middleware,
        sellCommodityApiSlice.middleware,
        requestTransactionApiSlice.middleware,
        dashboardApiSlice.middleware,
        loginApiSlice.middleware,
        farmersListApiSlice.middleware,
        formFarmersApiSlice.middleware,
        checkFarmersApiSlice.middleware,
        commodityRepoApiSlice.middleware,
        cashFlowApiSlice.middleware,
        payableNotesApiSlice.middleware,
        receivableApiSlice.middleware,
        detailFarmersApiSlice.middleware,
        payableNotesApiSlice.middleware,
        receivableApiSlice.middleware,
      )
    },
    preloadedState,
  })
  setupListeners(store.dispatch)
  return store
}

export const store = makeStore()
export let persistor = persistStore(store)
