import { createApiSlice } from 'app/createApiSlice'

export const talanginInvoiceApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'talanginInvoice/hooks',
  refetchOnMountOrArgChange: true,
  endpoints(builder) {
    return {
      fetchTalanginInvoice: builder.query({
        query(payload) {
          let url = `/api/v1/bussines/talangin`

          if (payload.status) {
            url = `/api/v1/bussines/talangin?${payload.status}=1`
          }

          return {
            url,
            params: {
              page: payload.page,
              size: 10,
              sortBy: payload.sortBy,
              sortDir: payload.sortDir,
            },
          }
        },
      }),
      fetchTalanginInvoiceDetail: builder.query({
        query({ id }) {
          return `/api/v1/bussines/talangin/detail?userpinjamanId=${id}`
        },
      }),
      postPayTalangin: builder.mutation({
        query(payload) {
          return {
            url: `/api/v1/bussines/talangin`,
            method: 'POST',
            body: payload,
          }
        },
      }),
      postCheckPass: builder.mutation({
        query(payload) {
          const { password } = payload
          return {
            url: `/api/v1/bussines/checkpasswordtrx`,
            method: 'POST',
            body: {
              password,
            },
          }
        },
      }),
    }
  },
})

export const {
  useFetchTalanginInvoiceQuery,
  usePostPayTalanginMutation,
  usePostCheckPassMutation,
  useFetchTalanginInvoiceDetailQuery,
} = talanginInvoiceApiSlice
