import { Button, Modal, ModalBody } from 'reactstrap'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { URL_UTANG } from 'utils/pathUrl'
import {
  useApprovePendingMutation,
  useRejectPendingMutation,
} from './payableNotesApiSlice'
import {
  setBannerMsg,
  toggleBanner,
  toggleModalConfirm,
} from './payableNotesSlice'
import { getPendingPayable } from './payableAction'

const ModalConfirm = ({ row, isDelete }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [errMsg, setErrMsg] = useState('')
  const { isOpenModalConfirm } = useSelector((s) => s.payableNotes)
  const [rejectPayable, result] = useRejectPendingMutation()
  const [approvePayable, resultApprove] = useApprovePendingMutation()

  const handleSubmit = async () => {
    try {
      const body = {
        id: row?.id,
      }
      const resp = (await isDelete)
        ? rejectPayable(body).unwrap()
        : approvePayable(body).unwrap()
      if (!resp?.data?.error) {
        dispatch(toggleModalConfirm())
        navigate(URL_UTANG)
        dispatch(
          isDelete
            ? setBannerMsg('Catatan utang berhasil ditolak.')
            : setBannerMsg('Utang berhasil dikonfirmasi dan dicatat.'),
        )
        dispatch(toggleBanner())
        dispatch(getPendingPayable())
      } else {
        throw resp
      }
    } catch (error) {
      setErrMsg(error?.data?.message ?? error?.message)
      console.log(error)
    }
  }

  useEffect(() => {
    if (!isOpenModalConfirm) {
      setErrMsg('')
    }
  }, [isOpenModalConfirm])

  return (
    <Modal
      centered
      toggle={() => dispatch(toggleModalConfirm())}
      isOpen={isOpenModalConfirm}
    >
      <ModalBody>
        <h6 className="fw-600">
          {isDelete ? 'Tolak' : 'Terima'} catatan utang
        </h6>
        <span>
          {isDelete
            ? 'Utang akan dihapus dari catatan anda. Lanjutkan?'
            : 'Utang ini akan masuk ke catatan anda. Lanjutkan?'}
        </span>
        {errMsg && (
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        )}
        <div className="d-flex gap-3 mt-4">
          <Button
            onClick={() => dispatch(toggleModalConfirm())}
            block
            outline
            color="secondary"
            disabled={result?.isLoading || resultApprove?.isLoading}
          >
            Batal
          </Button>

          <Button
            onClick={handleSubmit}
            block
            color="primary"
            disabled={result?.isLoading || resultApprove?.isLoading}
          >
            {isDelete ? 'Tolak' : 'Terima'}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalConfirm
