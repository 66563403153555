import { Outlet } from 'react-router-dom'
import { Container } from 'reactstrap'
import NavigationBar from './NavigationBar'

const Transactions = () => {
  return (
    <div className="w-100">
      <Container fluid className="custom-container">
        <NavigationBar />
      </Container>
      <Outlet />
    </div>
  )
}

export default Transactions
