import React from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useRejectAutoRepoByUserMutation } from '../commodityRepoApiSlice'

const ModalConfirmReject = ({ isOpen, toggle, setSuccessMsg, ...args }) => {
  const [rejectByUser, { isLoading: rejectLoading }] =
    useRejectAutoRepoByUserMutation()

  async function rejectRepoByUser() {
    try {
      await rejectByUser({ userautorepoId: args?.data?.id }).unwrap()
      toggle()
      args.refetch()
      setSuccessMsg(`Penawaran Repo Anda Tolak`)
    } catch (error) {
      console.log(error?.response)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      style={{ maxWidth: '400px' }}
      centered={true}
      keyboard={false}
      {...args}
    >
      <ModalHeader className="d-flex column border-0 w-100">
        <div className="w-100">Tolak Penawaran Repo</div>
      </ModalHeader>

      <ModalBody className="mb-1 d-flex align-items-center w-100">
        <span className="text-sm">
          Jika kamu menolak penawaran repo ini, maka seluruh pengajuan penawaran
          kamu akan dibatalkan dan kamu tidak bisa melanjutkannya lagi.
        </span>
      </ModalBody>
      <div className="p-2 d-flex">
        <Button
          className="w-100 me-1"
          color="secondary"
          onClick={toggle}
          style={{ backgroundColor: '#fff', color: '#344054' }}
          disabled={rejectLoading}
        >
          Periksa Lagi
        </Button>
        <Button
          className="w-100"
          color="danger"
          onClick={() => rejectRepoByUser()}
          disabled={rejectLoading}
          style={{ cursor: rejectLoading ? 'not-allowed' : 'pointer' }}
        >
          Tolak
        </Button>
      </div>
    </Modal>
  )
}

export default ModalConfirmReject
