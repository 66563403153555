import { sortStock } from 'constants'
import { X } from 'react-feather'
import { Sheet } from 'react-modal-sheet'
import { CardText, Input } from 'reactstrap'
import '../../styles.css'

export default function SortModal({
  isOpen,
  onClose,
  selectedSortId,
  onSelect,
}) {
  return (
    <Sheet
      isOpen={isOpen}
      initialSnap={1}
      snapPoints={[400, 300, 200, 100, 0]}
      onClose={onClose}
    >
      <Sheet.Container>
        <Sheet.Content>
          <div className="small-padding border-bottom d-flex justify-content-between align-items-center">
            <CardText className="fw-semibold no-padding">
              Urutkan berdasarkan
            </CardText>
            <X onClick={onClose} className="button" />
          </div>
          {sortStock?.map((el, i) => {
            return (
              <div className="d-flex justify-content-between align-items-center pl-2 pr-2 mt-4 mb-2 button">
                <CardText className="no-padding">{el?.title}</CardText>
                <Input
                  className="no-padding button"
                  onChange={() => onSelect(el)}
                  value={selectedSortId}
                  checked={selectedSortId === el?.id}
                  type="radio"
                  style={{ borderColor: 'gray', borderWidth: 2 }}
                />
              </div>
            )
          })}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  )
}
