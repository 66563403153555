import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { ArrowRight, CheckCircle } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Progress,
  Spinner,
} from 'reactstrap'
import { URL_FORM_UTANG_PIUTANG, URL_UTANG_APPROVAL } from 'utils/pathUrl'
import {
  toggleBannerReceivable,
  toggleSidebarReceivable,
} from '../receivable-notes/receivableNotesSlice'
import SidebarPayableNotes from './SidebarPayableNotes'
import { getPendingPayable } from './payableAction'
import { useGetPayableQuery } from './payableNotesApiSlice'
import { toggleBanner, toggleSidebar } from './payableNotesSlice'

const PayableNotes = ({
  dataReceivable,
  isOpenSidebarReceivable,
  isOpenBannerReceivable,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()

  const [selectedRow, setSelectedRow] = useState(null)
  const {
    tag,
    isOpenBanner,
    isOpenSidebar,
    bannerMsg,
    pendingPayable,
    loading,
  } = useSelector((s) => s.payableNotes)

  const { data, isLoading } = useGetPayableQuery({
    type: dataReceivable ? 'receivable' : 'payable',
  })

  useEffect(() => {
    if (!dataReceivable) {
      dispatch(getPendingPayable())
    }
  }, [dataReceivable])

  return (
    <div className="w-100 px-3">
      <SidebarPayableNotes
        row={selectedRow}
        isReceivable={dataReceivable ? true : false}
        isOpen={dataReceivable ? isOpenSidebarReceivable : isOpenSidebar}
        toggle={() =>
          dispatch(dataReceivable ? toggleSidebarReceivable() : toggleSidebar())
        }
      />
      <Card className="my-4">
        <CardBody className="d-flex flex-column">
          <div className="d-flex justify-content-between mb-2 align-items-center">
            <h5 className="fw-semibold">
              {dataReceivable ? 'Piutang' : 'Utang'}
            </h5>
            <Button
              onClick={() =>
                navigate(URL_FORM_UTANG_PIUTANG, {
                  state: { isReceivable: dataReceivable ? true : false },
                })
              }
              disabled={isLoading || loading}
              size="sm"
              color="primary"
            >
              + Catat {dataReceivable ? 'Piutang' : 'Utang'}
            </Button>
          </div>
          <div>
            <Alert
              isOpen={dataReceivable ? isOpenBannerReceivable : isOpenBanner}
              toggle={() =>
                dispatch(
                  dataReceivable ? toggleBannerReceivable() : toggleBanner(),
                )
              }
              color="success"
              className="d-flex align-items-center mt-4 w-100"
            >
              <span>
                <CheckCircle size={17} /> {bannerMsg}
              </span>
            </Alert>
          </div>
          {!dataReceivable && pendingPayable?.totalCount > 0 ? (
            <>
              {' '}
              <Card
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(URL_UTANG_APPROVAL)}
                className="d-flex flex-row justify-content-between rounded border p-3 bg-primary-25"
              >
                <span>
                  Ada {pendingPayable?.totalCount} catatan yang butuh konfirmasi
                </span>
                <ArrowRight size={24} />
              </Card>
            </>
          ) : (
            <></>
          )}
          <div>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner />{' '}
              </div>
            ) : data?.length > 0 ? (
              data?.map((e, i) => {
                return (
                  <div key={i}>
                    <Card
                      className="mt-4"
                      onClick={() => {
                        dispatch(
                          dataReceivable
                            ? toggleSidebarReceivable()
                            : toggleSidebar(),
                        )
                        setSelectedRow(e)
                      }}
                    >
                      <CardBody>
                        <div className="d-flex justify-content-between">
                          {!e?.userId ||
                          !e?.payerUserId ||
                          !e?.escrowpaymentId ? (
                            <Badge className="badge-warning">
                              Catat Manual
                            </Badge>
                          ) : (
                            <Badge className="badge-verify">Transaksi</Badge>
                          )}
                          {e?.amount === e?.amountPaid && (
                            <div
                              style={{
                                backgroundColor: '#ECFDF3',
                                color: '#027A48',
                              }}
                              className="badge rounded-pill flex-centered"
                            >
                              Lunas
                            </div>
                          )}
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                          <span className="fw-600">
                            {!e?.userId ||
                            !e?.payerUserId ||
                            !e?.escrowpaymentId
                              ? dataReceivable
                                ? e?.payerName
                                : e?.name
                              : dataReceivable
                                ? e?.buyerName
                                : e?.sellerName}
                          </span>
                          <span className="text-primary fw-700">
                            Rp{' '}
                            {intl.formatNumber(e?.amount ?? 0, {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                          </span>
                        </div>
                        <div className="d-flex gap-2">
                          <div className="text-gray-500">
                            {moment(e?.createdAt).format('Do MMMM YYYY, HH:mm')}
                          </div>
                          <div className="text-gray-500">•</div>
                          <div className="text-primary-600 fw-500">
                            Kategori: {e?.categoryName}
                          </div>
                        </div>
                        <div className="mt-3">
                          <span className="fw-500">
                            {dataReceivable
                              ? 'Sudah terima pembayaran'
                              : 'Sudah Bayar'}
                          </span>
                          <Progress
                            color="success"
                            style={{ height: '8px' }}
                            className="my-1"
                            value={
                              ((e?.amountPaid ?? 0) * 100) / (e?.amount ?? 0)
                            }
                          />
                          <span>
                            <span className="fw-600 text-gray-500">
                              Rp{' '}
                              {intl.formatNumber(e?.amountPaid ?? 0, {
                                useGrouping: 'always',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}{' '}
                            </span>
                            <span className="fw-400 text-gray-500">
                              dari Rp{' '}
                              {intl.formatNumber(e?.amount ?? 0, {
                                useGrouping: 'always',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}
                            </span>
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                )
              })
            ) : (
              <div className="border rounded p-2 text-center">
                Belum ada data yang tersimpan.{' '}
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default PayableNotes
