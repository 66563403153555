import { useEffect, useMemo, useState } from 'react'
import { useGetUserAvatarQuery as getAva } from 'features/private-views/settings/settingApiSlice'
import { useFetchTransactionQuery as getTrx } from 'features/private-views/dashboard/dashboardApiSlice'

const useGetTrxDashboard = (options, args) => {
  let [userId, setUserId] = useState([])

  const trxResult = getTrx(options, { args })
  const avaResult = getAva(userId, { skip: userId.length === 0 })

  const data = useMemo(() => {
    const generateAvaRequester = (optTrx, trx) => {
      let result = null
      if (optTrx?.toLowerCase() === 'buy') {
        for (let i = 0; i < avaResult.data?.data?.length; i++) {
          const avaUser = avaResult.data?.data[i]
          if (
            Number(avaUser.userId) ===
            (Number(trx?.buyerDetail?.makerUserId) ||
              Number(trx?.buyerDetail?.requesterUserId))
          ) {
            result = avaUser.link
          }
        }
      }

      if (optTrx?.toLowerCase() === 'sell') {
        for (let i = 0; i < avaResult.data?.data?.length; i++) {
          const avaUser = avaResult.data?.data[i]
          if (
            Number(avaUser.userId) ===
            (Number(trx?.sellerDetail?.makerUserId) ||
              Number(trx?.sellerDetail?.requesterUserId))
          ) {
            result = avaUser.link
          }
        }
      }
      return result
    }

    const getSellerOrBuyer = (optTrx, trx) => {
      let result = null
      if (optTrx?.toLowerCase() === 'sell') {
        for (let i = 0; i < avaResult.data?.data?.length; i++) {
          const avaUser = avaResult.data?.data[i]
          if (Number(avaUser.userId) === Number(trx?.buyerDetail?.userId)) {
            result = avaUser.link
          }
        }
      }

      if (optTrx?.toLowerCase() === 'buy') {
        for (let i = 0; i < avaResult.data?.data?.length; i++) {
          const avaUser = avaResult.data?.data[i]
          if (Number(avaUser.userId) === Number(trx?.sellerDetail?.userId)) {
            result = avaUser.link
          }
        }
      }
      return result
    }

    const getCheckerAva = (trx) => {
      let result = null

      for (let i = 0; i < avaResult.data?.data?.length; i++) {
        const avaUser = avaResult.data?.data[i]
        if (
          Number(avaUser.userId) === Number(trx?.pmbmakerchecker?.checkerUserId)
        ) {
          result = avaUser.link
        }
      }

      return result
    }

    const getMakerAva = (trx) => {
      let result = null

      for (let i = 0; i < avaResult.data?.data?.length; i++) {
        const avaUser = avaResult.data?.data[i]
        if (
          Number(avaUser.userId) === Number(trx?.pmbmakerchecker?.makerUserId)
        ) {
          result = avaUser.link
        }
      }

      return result
    }
    return {
      ...trxResult,

      data: {
        ...trxResult.data,
        items: trxResult?.data?.items.map((trx) => ({
          ...trx,
          requesterAvatar: generateAvaRequester(trx?.orderDetail?.type, trx),
          sellerOrBuyAvatar: getSellerOrBuyer(trx?.orderDetail?.type, trx),
          makerAvatar: getMakerAva(trx),
          checkerAvatar: getCheckerAva(trx),
        })),
      },
    }
  }, [trxResult, avaResult])

  useEffect(() => {
    function uniq_fast(a) {
      let seen = {}
      let out = []
      let len = a?.length
      let j = 0

      for (let i = 0; i < len; i++) {
        let buyerId =
          a[i]?.buyerDetail?.requesterUserId || a[i]?.buyerDetail?.makerUserId
        let sellerId =
          a[i]?.sellerDetail?.requesterUserId || a[i]?.sellerDetail?.makerUserId
        let buyerUserId = a[i]?.buyerDetail?.userId
        let sellerUserId = a[i]?.sellerDetail?.userId
        let makerUserId = a[i]?.pmbmakerchecker?.makerUserId
        let checkerUserId = a[i]?.pmbmakerchecker?.checkerUserId

        if (seen[buyerId] !== 1) {
          seen[buyerId] = 1
          out[j++] = buyerId
        }

        if (seen[sellerId] !== 1) {
          seen[sellerId] = 1
          out[j++] = sellerId
        }

        if (seen[buyerUserId] !== 1) {
          seen[buyerUserId] = 1
          out[j++] = buyerUserId
        }
        if (seen[sellerUserId] !== 1) {
          seen[sellerUserId] = 1
          out[j++] = sellerUserId
        }

        if (seen[makerUserId] !== 1) {
          seen[makerUserId] = 1
          out[j++] = makerUserId
        }

        if (seen[checkerUserId] !== 1) {
          seen[checkerUserId] = 1
          out[j++] = checkerUserId
        }
      }
      return out.filter((e) => e !== undefined)
    }

    let result = uniq_fast(trxResult?.data?.items).filter(
      (item) => item !== null,
    )

    setUserId(result.map((e) => Number(e)))
  }, [trxResult])
  return data
}

export default useGetTrxDashboard
