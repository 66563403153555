import { useIntl } from 'react-intl'
import { Card, CardBody, CardHeader } from 'reactstrap'

const CardTop = ({ data, callBack }) => {
  const intl = useIntl()
  return (
    <Card
      style={{ overflow: 'hidden', cursor: 'pointer' }}
      onClick={() => callBack(data)}
    >
      <CardHeader
        className="d-flex w-100 justify-content-between text-sm fw-bold"
        style={{ background: 'white', padding: '8px' }}
      >
        <span>{`${data?.name}`}</span>
        <span>{` `}</span>
      </CardHeader>
      <CardBody
        className="d-flex w-100 justify-content-between text-xs"
        style={{ background: 'white', padding: '1px 8px' }}
      >
        <span>Komoditas:</span>
        <span>{`${data?.item?.nameItem ?? data?.SKU}`}</span>
      </CardBody>
      <CardBody
        className="d-flex w-100 justify-content-between text-xs"
        style={{ background: 'white', padding: '1px 8px' }}
      >
        <span>Harga total komoditas:</span>
        <span className="fw-bold">
          Rp
          {`${intl.formatNumber(data?.amountInvoice, { useGrouping: 'always', maximumFractionDigits: 0 })}`}
        </span>
      </CardBody>
    </Card>
  )
}

export default CardTop
