import {
  CardBody,
  CardText,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap'
import { Navigate, useOutletContext } from 'react-router-dom'
import LoadingAnimation from 'components/LoadingAnimation'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { URL_406 } from 'utils/pathUrl'

const CompanySetting = ({ action, subject }) => {
  const [data, isLoading, isSuccess] = useOutletContext()
  const ability = useAbilityAccess({ action, subject })

  if (!ability) {
    return <Navigate to={URL_406} replace={true} />
  }

  return (
    <CardBody>
      <ListGroup flush>
        <ListGroupItem className="py-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <CardText tag="h4">Profil perusahaan</CardText>
              <CardText tag="p">Ubah profil perusahaan anda di sini</CardText>
            </div>
          </div>
        </ListGroupItem>
        <ListGroupItem className="py-4">
          <Row>
            <Col sm="4">Nama perusahaan</Col>
            <Col sm="8">
              {!isLoading && isSuccess ? (
                data?.ownerData?.companyName
              ) : (
                <LoadingAnimation size="sm" />
              )}
            </Col>
          </Row>
        </ListGroupItem>
        <ListGroupItem className="py-4">
          <Row>
            <Col sm="4">Deskripsi</Col>
            <Col sm="8">-</Col>
          </Row>
        </ListGroupItem>
        {/* new section */}
        <ListGroupItem className="py-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <CardText tag="h4">Akun bank</CardText>
              <CardText tag="p">
                Ubah akun bank yang anda gunakan di sini
              </CardText>
            </div>
          </div>
        </ListGroupItem>
        <ListGroupItem className="py-4">
          <Row>
            <Col sm="4">Akun bank</Col>
            <Col sm="8">
              {!isLoading && isSuccess ? (
                data?.ownerData?.bankLogo ? (
                  <span className="me-2">
                    <img
                      src={data?.ownerData?.bankLogo}
                      alt="logo-bca"
                      style={{ width: '30px', height: '30px' }}
                      className="me-2"
                    />
                    <span className="fw-medium">
                      {data?.ownerData?.bankName ?? '-'}
                    </span>
                  </span>
                ) : (
                  <span className="me-2">
                    <span>Logo Bank Tidak Ditemukan - </span>
                    <span className="fw-medium">
                      {data?.ownerData?.bankName ?? '-'}
                    </span>
                  </span>
                )
              ) : (
                <LoadingAnimation size="sm" />
              )}
            </Col>
          </Row>
        </ListGroupItem>
        <ListGroupItem className="py-4">
          <Row>
            <Col sm="4">No rekening</Col>
            <Col sm="8">
              {!isLoading && isSuccess ? (
                data?.ownerData?.bankAccountNumber
              ) : (
                <LoadingAnimation size="sm" />
              )}
            </Col>
          </Row>
        </ListGroupItem>
        <ListGroupItem className="py-4">
          <Row>
            <Col sm="4">Nama pemilik rekening</Col>
            <Col sm="8">
              {!isLoading && isSuccess ? (
                data?.ownerData?.bankAccountName
              ) : (
                <LoadingAnimation size="sm" />
              )}
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </CardBody>
  )
}

export default CompanySetting
