import {
  ArrowDownCircle,
  ArrowLeftCircle,
  ArrowRightCircle,
} from 'react-feather'
import { Card, CardText, Col, Container, List, Row } from 'reactstrap'

// css
import './style.scss'
import { useSelector } from 'react-redux'
import { tags } from 'utils/tags'

import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  URL_406,
  URL_APPROVAL_BUY,
  URL_APPROVAL_SELL,
  URL_APPROVAL_WITHDRAWAL,
} from 'utils/pathUrl'
import { BUSINESS_MYAPPROVAL, READ } from 'utils/subjectActions'

const ApprovalPage = () => {
  const nav = useNavigate()
  let location = useLocation()
  const access = useAbilityAccess({
    action: READ,
    subject: BUSINESS_MYAPPROVAL,
  })
  const { countData } = useSelector((s) => s.approval)

  if (!access) {
    return <Navigate to={URL_406} replace={true} />
  }

  return (
    <div className="w-100 px-3">
      <Row>
        <Col md="3">
          <Card body className="my-3">
            <CardText tag={'h5'} className="mb-3">
              Kategori
            </CardText>
            <List type="unstyled">
              <li
                className={`text-sm menu-categories-approval ${location.pathname === `/${URL_APPROVAL_BUY}` && 'active'}`}
                onClick={() => nav(`/${URL_APPROVAL_BUY}`)}
                style={{ cursor: 'pointer' }}
              >
                <ArrowLeftCircle size={16} className="ms-2 me-1" />
                Transaksi Pembelian {`(${countData[tags.request_buy] ?? 0})`}
              </li>

              <li
                className={`text-sm menu-categories-approval ${location.pathname === `/${URL_APPROVAL_BUY}/${URL_APPROVAL_SELL}` && 'active'}`}
                onClick={() => nav(`/${URL_APPROVAL_BUY}/${URL_APPROVAL_SELL}`)}
                style={{ cursor: 'pointer' }}
              >
                <ArrowRightCircle size={16} className="ms-2 me-1" />
                Transaksi Penjualan {`(${countData[tags.request_sell] ?? 0})`}
              </li>

              <li
                className={`text-sm menu-categories-approval ${location.pathname === `/${URL_APPROVAL_BUY}/${URL_APPROVAL_WITHDRAWAL}` && 'active'}`}
                onClick={() =>
                  nav(`/${URL_APPROVAL_BUY}/${URL_APPROVAL_WITHDRAWAL}`)
                }
                style={{ cursor: 'pointer' }}
              >
                <ArrowDownCircle size={16} className="ms-2 me-1" />
                Penarikan Uang {`(${countData[tags.request_withdrawal] ?? 0})`}
              </li>
            </List>
          </Card>
        </Col>
        <Col md="9">
          <Outlet />
        </Col>
      </Row>
    </div>
  )
}

export default ApprovalPage
