import React from 'react'

export const STATUS = {
  PENDING: 'PENDING',
  APPROVEDBYADMIN: 'APPROVEDBYADMIN',
  REJECTEDBYADMIN: 'REJECTEDBYADMIN',
  REJECTEDBYUSER: 'REJECTEDBYUSER',
  APPROVEDBYUSER: 'APPROVEDBYUSER',
  APPROVEDBYALL: 'APPROVEDBYALL',
}

const BadgeRepo = ({ status }) => {
  if (status === STATUS.PENDING) {
    return (
      <div className="badge-repo-pending d-flex align-items-center rounded">
        <span className="text-wrap text-center text-xs">Sedang Ditinjau</span>
      </div>
    )
  }

  if (status === STATUS.APPROVEDBYADMIN) {
    return (
      <div className="badge-repo-success d-flex align-items-center rounded">
        <span className="text-wrap text-center text-xs">
          Disetujui oleh PasarMIKRO
        </span>
      </div>
    )
  }

  if (status === STATUS.REJECTEDBYADMIN) {
    return (
      <div className="badge-repo-danger d-flex align-items-center rounded">
        <span className="text-wrap text-center text-xs">
          Ditolak oleh PasarMIKRO
        </span>
      </div>
    )
  }
  if (status === STATUS.REJECTEDBYUSER) {
    return (
      <div className="badge-repo-danger d-flex align-items-center rounded">
        <span className="text-wrap text-center text-xs">
          Penawaran ditolak oleh pengguna
        </span>
      </div>
    )
  }
  if (status === STATUS.APPROVEDBYUSER) {
    return (
      <div className="badge-repo-success d-flex align-items-center rounded">
        <span className="text-wrap text-center text-xs">
          Penawaran diambil oleh pengguna
        </span>
      </div>
    )
  }

  if (status === STATUS.APPROVEDBYALL) {
    return (
      <div className="badge-repo-success d-flex align-items-center rounded">
        <span className="text-wrap text-center">
          Penawaran diambil oleh pengguna
        </span>
      </div>
    )
  }

  return <div>-</div>
}

export default BadgeRepo
