import { createApiSlice } from 'app/createApiSlice'
import { API_CHECK_FARMERS, API_INVITE_FARMERS } from 'utils/apiList'

export const checkFarmersApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'farmers/check-user',

  endpoints(builder) {
    return {
      checkFarmer: builder.mutation({
        query({ identity }) {
          return {
            url: API_CHECK_FARMERS,
            method: 'POST',
            body: {
              identity,
            },
          }
        },
      }),
      inviteFarmer: builder.mutation({
        query({ identity }) {
          return {
            url: API_INVITE_FARMERS,
            method: 'POST',
            body: {
              identity,
            },
          }
        },
      }),
    }
  },
})

export const { useCheckFarmerMutation, useInviteFarmerMutation } =
  checkFarmersApiSlice
