import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiEndpoint } from 'configs'

const getInventory = createAsyncThunk(
  'manage/inventory',
  async ({ page, search, sortBy, asc }, { rejectWithValue, getState }) => {
    try {
      let url = `${apiEndpoint}/api/v1/bussines/inventory?page=${page}&size=10`
      if (search) {
        url += `&search=${search}`
      }
      if (sortBy) {
        url += `&sortby=${sortBy}&sortdir=${asc}`
      }

      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(url, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getItemsInventory = createAsyncThunk(
  'manage/items',
  async (_, { rejectWithValue, getState }) => {
    try {
      let currentPage = 0
      let allItems = []
      let totalPages = 0

      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      do {
        const url = `${apiEndpoint}/api/v1/cms/item?page=${currentPage}&size=50`
        const { data } = await axios.get(url, config)

        const { items, totalItems, totalPages: pages } = data
        if (!items || items.length === 0) break

        allItems.push(...items)
        totalPages = pages
        currentPage++
      } while (currentPage < totalPages)

      const responseData = {
        items: allItems,
        totalItems: allItems.length,
        totalPages: totalPages,
        currentPage: currentPage,
      }

      return Promise.resolve(responseData)
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const createStock = createAsyncThunk(
  'manage/create',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/inventory`,
        payload,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const updateInventory = createAsyncThunk(
  'manage/update',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/inventory/update`,
        payload,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const transformInventory = createAsyncThunk(
  'manage/transform',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/inventory/transform`,
        payload,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const splitInventory = createAsyncThunk(
  'manage/split',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/inventory/splittomany`,
        payload,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const mergeInventory = createAsyncThunk(
  'manage/split',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/inventory/merge`,
        payload,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const removeInventory = createAsyncThunk(
  'manage/split',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${apiEndpoint}/api/v1/bussines/inventory`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
        data: payload,
      }
      const { data } = await axios.request(config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export {
  getInventory,
  getItemsInventory,
  createStock,
  updateInventory,
  transformInventory,
  splitInventory,
  mergeInventory,
  removeInventory,
}
