import { useRef, useState } from 'react'
import { Alert, Button, Card, Modal, ModalBody } from 'reactstrap'

// components
import ICUpload from 'assets/icons/ic-upload.svg'
import ICXAlert from 'assets/icons/ic-x-alert.svg'

import './modalStyle.scss'
import {
  useUpdateAvatarUserMutation,
  useUploadImageMutation,
} from '../settingApiSlice'
import useGetMyProfileAvaQuery from 'hooks/useGetMyProfileAvaQuery'

const ModalAvatarUpload = ({
  modal,
  toggle,
  dataName,
  setIsSuccessUploadAva,
  refetchAvatar,
  ...args
}) => {
  const fileInputRef = useRef(null)
  const [dataImg, setDataImg] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const [visibleErrorMsg, setVisibleErrorMsg] = useState(false)
  const [visiblePreviewImg, setVisiblePreviewImg] = useState(false)

  const [upload, { isLoading: uploadLoading }] = useUploadImageMutation()
  const [updateAvatarUrl, { isLoading: avaLoading }] =
    useUpdateAvatarUserMutation()
  const profile = useGetMyProfileAvaQuery({ skip: false })

  const resetState = () => {
    setDataImg(null)
    setPreviewImage(null)
    setErrorMessage(null)
    setVisibleErrorMsg(false)
    setVisiblePreviewImg(false)
  }

  const onDismissPreviewImg = () => {
    setPreviewImage(null)
    setVisiblePreviewImg(false)
    setDataImg(null)
  }

  const fnCloseErrorMsg = () => {
    setVisibleErrorMsg(false)
    setErrorMessage(null)
  }

  const handleUploadClick = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = (event) => {
    fnCloseErrorMsg()
    onDismissPreviewImg()

    const file = event.target.files[0]

    if (file === undefined) {
      setDataImg(null)
      setErrorMessage('Anda belum menambahkan foto.')
    }

    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']
    if (!allowedTypes.includes(file.type)) {
      setVisibleErrorMsg(true)
      return setErrorMessage(`Hanya file JPG dan PNG yang diperbolehkan.`)
    }

    const maxSizeMB = 4
    const maxSizeBytes = maxSizeMB * 1024 * 1024
    if (file.size > maxSizeBytes) {
      setVisibleErrorMsg(true)
      return setErrorMessage(`Ukuran file melebihi batas ${maxSizeMB}MB.`)
    }

    const reader = new FileReader()
    reader.onload = () => {
      setPreviewImage(reader.result)
      setVisiblePreviewImg(true)
    }
    reader.readAsDataURL(file)
    setDataImg(file)

    event.target.value = null
  }

  const fnUploadFile = async () => {
    if (dataImg === null) {
      setErrorMessage('Anda belum menambahkan foto.')
      setVisibleErrorMsg(true)
      setPreviewImage(null)
    }

    try {
      const data = await upload(dataImg).unwrap()
      const updateUser = await updateAvatarUrl({
        profilepicUrl: data.url,
        name: dataName,
      }).unwrap()
      setIsSuccessUploadAva(updateUser?.message)
      profile.refetch()
      refetchAvatar()
      resetState()
      setTimeout(toggle, 300)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal isOpen={modal} toggle={toggle} {...args}>
      <ModalBody className="p-4">
        <div className="">
          <h5>Tambah foto</h5>
        </div>
        {/* Error message */}
        {errorMessage !== null && (
          <Alert
            isOpen={visibleErrorMsg}
            toggle={fnCloseErrorMsg}
            color="danger"
            className="d-flex align-items-center gap-2 my-4"
          >
            <img src={ICXAlert} alt="alert icon" width={18} height={18} />
            <span>{errorMessage}</span>
          </Alert>
        )}
        {/* Image previewImage */}
        {previewImage !== null ? (
          <Alert
            className="p-3 my-4 d-flex justify-content-center align-items-center"
            isOpen={visiblePreviewImg}
            toggle={onDismissPreviewImg}
            style={{ backgroundColor: 'transparent' }}
            color="secondary"
          >
            <img
              src={previewImage}
              alt="Preview"
              style={{ maxWidth: '20rem', maxHeight: '20rem' }}
              className="rounded-circle"
            />
          </Alert>
        ) : (
          <Card
            className="p-3 my-4 d-flex justify-content-center align-items-center"
            onClick={handleUploadClick}
            style={{ cursor: 'pointer' }}
          >
            <img src={ICUpload} alt="icon" width={50} height={50} />
            <span className="text-primary fw-bolder">
              Klik untuk mengunggah
            </span>
            <span className="text-sm">{`JPG atau PNG (ukuran maks. 4 MB)`}</span>
          </Card>
        )}

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept=".jpg, .png"
        />
        <div className="d-flex w-100 gap-2">
          <Button
            color="secondary"
            outline
            className="w-50"
            onClick={() => {
              resetState()
              setTimeout(toggle, 300)
            }}
            disabled={uploadLoading || avaLoading}
          >
            Batal
          </Button>{' '}
          <Button
            color="primary"
            className="w-50"
            onClick={fnUploadFile}
            disabled={uploadLoading || avaLoading}
          >
            Tambah Photo
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalAvatarUpload
