import ICWarning from 'assets/icons/warning-icon.svg'
import { Button, Modal, ModalBody } from 'reactstrap'
import {
  useDeleteCategoryMutation,
  useLazyGetCategoriesQuery,
} from './payableNotesApiSlice'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'

const ModalDelete = ({ row, isOpen, isReceivable, toggle }) => {
  const [errMsg, setErrMsg] = useState('')
  const [deleteCategory, result] = useDeleteCategoryMutation()
  const [getCategories] = useLazyGetCategoriesQuery()
  const { ownerData } = useSelector((s) => s.dashboard)

  const handleSubmit = async () => {
    try {
      const body = {
        id: row?.id,
      }
      const resp = await deleteCategory(body).unwrap()
      if (!resp?.data?.error) {
        toggle()
        getCategories({
          type: isReceivable ? 'receivable' : 'payable',
          active: 1,
          userId: ownerData?.userId,
        })
      } else {
        throw resp
      }
    } catch (error) {
      setErrMsg(error?.data?.message ?? error?.message)
      console.log(error)
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setErrMsg('')
    }
  }, [isOpen])

  return (
    <Modal centered toggle={toggle} isOpen={isOpen}>
      <ModalBody>
        <img src={ICWarning} alt="Warning Icon" />
        <h6 className="mt-4 fw-600">Hapus kategori ini?</h6>
        {errMsg && (
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        )}
        <div className="d-flex gap-3 mt-4">
          <Button onClick={toggle} block outline color="secondary">
            Batal
          </Button>
          <Button onClick={handleSubmit} block color="danger">
            Hapus
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalDelete
