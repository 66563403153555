import ICPemasukan from 'assets/icons/pemasukan-1.svg'
import ICPengeluaran from 'assets/icons/pengeluaran-1.svg'
import ICPemasukan2 from 'assets/icons/pemasukan-2.svg'
import ICPengeluaran2 from 'assets/icons/pengeluaran-2.svg'
import 'assets/scss/flatpickr/flatpickr.scss'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Chart from 'react-apexcharts'
import {
  ArrowDownCircle,
  ArrowUpCircle,
  Calendar,
  CheckCircle,
  ChevronDown,
  Filter,
} from 'react-feather'
import Flatpickr from 'react-flatpickr'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Alert,
  Badge,
  Card,
  CardBody,
  CardFooter,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown,
} from 'reactstrap'
import { ARUS_KAS_FORM } from 'utils/pathUrl'
import SidebarCashFlow from './SidebarCashFlow'
import { useLazyGetArusKasQuery } from './cashFlowApiSlice'
import {
  setDate,
  setFetchOption,
  toggleBanner,
  toggleSidebarCashflow,
} from './cashFlowSlice'
import Select from 'react-select'
import { ButtonIcon } from 'components/Buttons'

const CashFlow = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const date = new Date()
  const y = date.getFullYear()
  const m = date.getMonth()
  const firstDay = new Date(y, m, 1)
  const lastDay = new Date(y, m, date.getDate())
  const [dataSeries, setDataSeries] = useState({
    income: [],
    expense: [],
  })
  const [selectedDate, setSelectedDate] = useState([firstDay, lastDay])
  const [data, setData] = useState([])
  const [selectedFilter, setSelectedFilter] = useState({
    label: 'Semua catatan',
    value: 'all',
  })
  const { isOpenSidebarCashFlow, isOpenBanner, tag } = useSelector(
    (s) => s.cashflow,
  )
  const [getArusKas, res] = useLazyGetArusKasQuery()
  const [income, setIncome] = useState(0)
  const [expense, setExpense] = useState(0)
  const [selectedRow, setSelectedRow] = useState(null)
  let [page, setPage] = useState({ page: tag.page })

  const handleFinishedDelete = async () => {
    const resp = await getArusKas({
      startdate: moment(selectedDate[0]).format('YYYY-MM-DD'),
      enddate: moment(selectedDate[1]).add(1, 'days').format('YYYY-MM-DD'),
      filter: selectedFilter?.value,
      page: page?.page,
    })
    setData(resp?.data)
  }

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {},
    },
    rows: {
      style: {},
    },
  }

  const columns = [
    {
      name: <span>Kategori Transaksi</span>,
      wrap: true,
      grow: 2,
      cell: (row) => (
        <div className="d-flex gap-2 align-items-center fw-500">
          <img
            width={40}
            height={40}
            src={
              row?.incomeorexpense === 'income' ? ICPemasukan2 : ICPengeluaran2
            }
            alt={
              row?.incomeorexpense === 'income' ? 'Pemasukan' : 'Pengeluaran'
            }
          />
          <span>{row?.extradata?.label ?? row?.label}</span>
        </div>
      ),
    },
    {
      name: <span>Total Transaksi</span>,
      wrap: true,
      grow: 2,
      cell: (row) => (
        <span>
          Rp
          {intl.formatNumber(row?.amount, {
            useGrouping: 'always',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </span>
      ),
    },
    {
      name: <span>Jenis Pencatatan</span>,
      wrap: true,
      grow: 2,
      cell: (row) => {
        return (
          <Badge className={!row?.manual ? 'badge-verify' : 'badge-process'}>
            {!row?.manual ? 'Transaksi' : 'Catat Manual'}
          </Badge>
        )
      },
    },
    {
      name: <span>Tanggal Transaksi</span>,
      wrap: true,
      grow: 2,
      cell: (row) => (
        <span>
          {moment(row?.trxdate).locale('id').format('DD MMMM YYYY')}
          {', '}
          {row?.createdhour?.split(':', 2).join(':')}
        </span>
      ),
    },
    {
      name: <span>Catatan</span>,
      wrap: true,
      grow: 2,
      cell: (row) => <span className="truncate">{row?.notes ?? '-'}</span>,
    },
    {
      name: '',
      wrap: true,
      grow: 1,
      selector: (row) => (
        <div
          onClick={() => {
            dispatch(toggleSidebarCashflow())
            setSelectedRow(row)
          }}
          style={{ cursor: 'pointer' }}
          className="fw-600 text-primary"
        >
          Lihat Detail
        </div>
      ),
      center: true,
    },
  ]

  const filterOptions = [
    { label: 'Semua catatan', value: 'all' },
    { label: 'Catatan transaksi di PasarMikro', value: 'pasarmikro' },
    { label: 'Catatan manual', value: 'manual' },
  ]

  const options = {
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        columnWidth: '25px',
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'all',
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'top',
      horizontalAlign: 'right',
      markers: {
        shape: 'circle',
      },
    },
    chart: {
      zoom: {
        enabled: false,
      },
      stacked: true,
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return (
            'Rp' +
            intl.formatNumber(val ?? 0, {
              useGrouping: 'always',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
          )
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    colors: ['#039855', '#05603A', '#D92D20', '#912018'],
    xaxis: {
      type: 'category',
      title: {
        text: 'Tanggal',
      },
      labels: {
        formatter: (value) => value,
      },
    },
    yaxis: {
      title: {
        text: 'Jumlah',
      },
      labels: {
        formatter: (data) => {
          return data.toLocaleString('id-ID')
        },
      },
    },
  }

  const series = [
    {
      name: 'Pemasukan Manual',
      group: 'Pemasukan',
      data: dataSeries.incomeManual,
    },
    {
      name: 'Pemasukan Transaksi',
      group: 'Pemasukan',
      data: dataSeries.incomeTransaction,
    },
    {
      name: 'Pengeluaran Manual',
      group: 'Pengeluaran',
      data: dataSeries.expenseManual,
    },
    {
      name: 'Pengeluaran Transaksi',
      group: 'Pengeluaran',
      data: dataSeries.expenseTransaction,
    },
  ]

  useEffect(() => {
    dispatch(
      setDate({
        startdate: moment(selectedDate[0]).format('YYYY-MM-DD'),
        enddate: moment(selectedDate[1]).add(1, 'days').format('YYYY-MM-DD'),
      }),
    )
    getArusKas({
      startdate: moment(selectedDate[0]).format('YYYY-MM-DD'),
      enddate: moment(selectedDate[1]).add(1, 'days').format('YYYY-MM-DD'),
      filter: selectedFilter?.value,
      page: page?.page,
    })
      .unwrap()
      .then((resAPI) => {
        setData(resAPI)
        const rawData = resAPI.items
        const groupedData = {
          incomeManual: [],
          incomeTransaction: [],
          expenseManual: [],
          expenseTransaction: [],
        }

        const startDate = moment(selectedDate[0], 'YYYY-MM-DD')
        const endDate = moment(selectedDate[1], 'YYYY-MM-DD')

        let currentDate = startDate.clone()

        while (currentDate.isBefore(endDate)) {
          let nextDate = currentDate.clone().add(6, 'days')
          if (nextDate.isAfter(endDate)) nextDate = endDate

          const key = `${currentDate.format('DD-MM')} - ${nextDate.format('DD-MM')}`

          groupedData.incomeManual[key] = 0
          groupedData.incomeTransaction[key] = 0
          groupedData.expenseManual[key] = 0
          groupedData.expenseTransaction[key] = 0

          rawData.forEach((item) => {
            const itemDate = moment(item.createdDateTs, 'DD-MM-YYYY')
            if (itemDate.isBetween(currentDate, nextDate, 'days', '[]')) {
              const category =
                item.incomeorexpense === 'expense' ? 'expense' : 'income'
              const type = item.manual ? 'Manual' : 'Transaction'
              groupedData[`${category}${type}`][key] += item.amount
            }
          })

          currentDate = nextDate.clone().add(1, 'days')
        }

        const newDataSeries = {
          incomeManual: Object.keys(groupedData.incomeManual).map((key) => ({
            x: key,
            y: groupedData.incomeManual[key],
          })),
          incomeTransaction: Object.keys(groupedData.incomeTransaction).map(
            (key) => ({
              x: key,
              y: groupedData.incomeTransaction[key],
            }),
          ),
          expenseManual: Object.keys(groupedData.expenseManual).map((key) => ({
            x: key,
            y: groupedData.expenseManual[key],
          })),
          expenseTransaction: Object.keys(groupedData.expenseTransaction).map(
            (key) => ({
              x: key,
              y: groupedData.expenseTransaction[key],
            }),
          ),
        }

        setDataSeries(newDataSeries)
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.status === 403 &&
          typeof e.response.data.message !== 'undefined' &&
          e.response.data.message === 'access denied'
        ) {
          console.log(e)
        }
        console.warn(e)
      })
  }, [selectedDate, selectedFilter, page])

  useEffect(() => {
    setIncome(
      data?.items?.reduce((sum, e) => {
        return e.incomeorexpense === 'income' ? sum + e.amount : sum
      }, 0),
    )

    setExpense(
      data?.items?.reduce((sum, e) => {
        return e.incomeorexpense === 'expense' ? sum + e.amount : sum
      }, 0),
    )
  }, [data])

  return (
    <div className="w-100 px-3">
      <SidebarCashFlow
        handleFinishedDelete={handleFinishedDelete}
        row={selectedRow}
        isOpen={isOpenSidebarCashFlow}
        toggle={() => dispatch(toggleSidebarCashflow())}
      />
      <Card className="my-4">
        <CardBody className="d-flex flex-column">
          <Row className="mb-2 align-items-center">
            <Col xs={12} md={2} lg={4}>
              <h5 className="fw-semibold">Catatan Arus Kas</h5>
            </Col>
            <Col
              xs={12}
              md={10}
              lg={8}
              className="d-flex justify-content-md-end mt-2 mt-md-0"
            >
              <div className="d-flex flex-column flex-md-row align-items-center gap-2 w-100 justify-content-md-end">
                <div className="d-flex align-items-center justify-content-center border rounded">
                  <Calendar size={17} className="ms-3" />
                  <Flatpickr
                    options={{
                      mode: 'range',
                      dateFormat: 'd-m-Y',
                      defaultDate: [firstDay, lastDay],
                      onClose: (selectedDate, dateStr, instance) => {
                        setSelectedDate(selectedDate)
                      },
                    }}
                    className="form-control flat-picker bg-transparent border-0 shadow-none"
                  />
                </div>
                <Select
                  isSearchable={false}
                  onChange={(e) => setSelectedFilter(e)}
                  options={filterOptions}
                  value={selectedFilter}
                />
                <UncontrolledDropdown>
                  <DropdownToggle size="md" color="primary">
                    + Tambah Catatan
                  </DropdownToggle>
                  <DropdownMenu container="body">
                    <DropdownItem
                      onClick={() =>
                        navigate(ARUS_KAS_FORM, { state: { income: true } })
                      }
                    >
                      <ArrowDownCircle className="text-success" /> Catat
                      Pemasukan
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        navigate(ARUS_KAS_FORM, { state: { expense: true } })
                      }
                    >
                      <ArrowUpCircle className="text-danger" /> Catat
                      Pengeluaran
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </Row>

          <div>
            <Alert
              isOpen={isOpenBanner}
              toggle={() => dispatch(toggleBanner())}
              color="success"
              className="d-flex align-items-center mt-4 w-100"
            >
              <span>
                <CheckCircle size={17} />{' '}
                {state?.income ? 'Pemasukan' : 'Pengeluaran'} baru berhasil
                dicatat.
              </span>
            </Alert>
          </div>
          <div>
            <div className="row">
              <div className="col-md-4">
                <Card body>
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex gap-2 align-items-center">
                      <img
                        width={70}
                        height={70}
                        src={ICPemasukan2}
                        alt="Pemasukan"
                      />
                      <div className="d-flex flex-column">
                        <span>Pemasukan</span>
                        <span className="fw-semibold">
                          Rp
                          {intl.formatNumber(income ?? 0, {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                      <img
                        width={70}
                        height={70}
                        src={ICPengeluaran2}
                        alt="Pengeluaran"
                      />
                      <div className="d-flex flex-column">
                        <span>Pengeluaran</span>
                        <span className="fw-semibold">
                          Rp
                          {intl.formatNumber(expense ?? 0, {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-md-8">
                <Card>
                  <Chart
                    options={options}
                    series={series}
                    type="bar"
                    height={400}
                  />
                </Card>
              </div>
            </div>
          </div>

          <div className="py-3">
            <DataTable
              customStyles={customStyles}
              columns={columns}
              data={data?.items}
              progressPending={res?.isLoading || res?.status === 'pending'}
              responsive
              persistTableHead
              paginationTotalRows={data?.totalItems}
              progressComponent={<Spinner />}
              sortIcon={<ChevronDown />}
              sortServer
              className="border p-0 border-1 rounded-top"
              noDataComponent={
                <p className="my-3 fw-semibold">Belum ada catatan.</p>
              }
              pagination
              paginationServer
              paginationComponent={(props) => {
                return (
                  <CardFooter
                    className="border border-top-0"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="text-sm">
                        {`Halaman ${page.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                      </div>
                      <div className="d-flex flex-centered gap-1">
                        <ButtonIcon
                          btnTitle="Sebelumnya"
                          btnProps={{
                            disabled: page.page === 0,
                            size: 'sm',
                            color: 'secondary',
                            onClick: () => {
                              setPage((prev) => ({ page: prev.page - 1 }))
                              dispatch(
                                setFetchOption({
                                  page: page.page - 1,
                                }),
                              )
                            },
                          }}
                          btnTitleProps={{
                            className: 'text-sm',
                          }}
                        />
                        <ButtonIcon
                          btnTitle="Selanjutnya"
                          btnProps={{
                            disabled:
                              page.page ===
                              Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                            size: 'sm',
                            color: 'secondary',
                            onClick: () => {
                              setPage((prev) => ({ page: prev.page + 1 }))
                              dispatch(
                                setFetchOption({
                                  page: page.page + 1,
                                }),
                              )
                            },
                          }}
                          btnTitleProps={{
                            className: 'text-sm',
                          }}
                        />
                      </div>
                    </div>
                  </CardFooter>
                )
              }}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default CashFlow
