import axios from 'axios'
import { LoadingCenter } from 'components/Loading'
import Map from 'components/Map'
import { useFetchFarmersQuery } from 'features/private-views/farmers-web/farmers-list/farmersListApiSlice'
import { useEffect, useState } from 'react'
import { ChevronDown, ChevronUp, Menu, RefreshCw, User } from 'react-feather'
import { useSelector } from 'react-redux'
import { apiEndpoint } from 'utility/Utils'
import { API_FARMERS_PLOTTING } from 'utils/apiList'
import './spatial.css'

export default function Spatial() {
  const { token } = useSelector((e) => e.auth)
  const { data, isLoading } = useFetchFarmersQuery({
    size: 9999,
    status: 'JOIN',
  })
  const [plottingPoints, setPlottingPoints] = useState([])
  const [defaultCenterMap, setDefaultCenterMap] = useState({
    lat: -6.26055485,
    lng: 106.82934195,
  })
  const [farmerList, setFarmerList] = useState([])
  const [isMultiple, setIsMultiple] = useState(true)
  const [loadingPlotting, setLoadingPlotting] = useState(false)
  const [selectedCode, setSelectedCode] = useState('')
  const [isSidebarVisible, setSidebarVisible] = useState(true)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    combinedData()
  }, [data])

  const combinedData = async () => {
    if (data) {
      setIsMultiple(true)
      const promises = data?.data?.items?.map((el) => fetchPlot(el))
      const results = await Promise.all(promises)
      const flattenedData = flattenData(results)
      const updatedFlattenedData =
        await updateFlattenedDataWithApiResults(flattenedData)
      setPlottingPoints(updatedFlattenedData)
      const modifiedResults = results.map((result) => ({
        ...result,
        open: false,
      }))
      setFarmerList(modifiedResults)
    }
  }

  const updateFlattenedDataWithApiResults = async (flattenedData) => {
    const updatedData = []
    setLoadingPlotting(true)
    for (const item of flattenedData) {
      const updatedItem = await fetchDataForItem(item)
      updatedData.push(updatedItem)

      await new Promise((resolve) => setTimeout(resolve, 500))
    }
    setLoadingPlotting(false)
    return updatedData
  }

  const fetchPlot = async (item) => {
    try {
      const { data } = await axios.get(
        `${apiEndpoint}/${API_FARMERS_PLOTTING}?userId=${item?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      return { ...item, plots: data.data.items }
    } catch (error) {
      console.log(error)
      return { ...item, plots: [] }
    }
  }

  const getPlottingPoint = async (code, page) => {
    setLoadingPlotting(true)
    setSelectedCode(code)
    setIsMultiple(false)
    try {
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/landplotpoint?code=${code}&sortby=id&sortdir=asc&page=${page}&size=150`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      const newData = data?.items
      setPlottingPoints((prevData) =>
        Array.from(new Set([...prevData, ...newData].map(JSON.stringify))).map(
          JSON.parse,
        ),
      )
      if (data?.centerPoint?.coordinates?.length > 0) {
        setDefaultCenterMap({
          lat: data?.centerPoint?.coordinates[1],
          lng: data?.centerPoint?.coordinates[0],
        })
      }
      if (data?.currentPage < data?.totalPages && data?.totalPages > 1) {
        await getPlottingPoint(code, page + 1)
      } else {
        setLoadingPlotting(false)
      }
    } catch (error) {
      setLoadingPlotting(false)
    }
  }

  const fetchDataForItem = async (item) => {
    try {
      const code = item.code
      const page = 0
      const plottingPoints = []

      const fetchPlottingData = async (code, page) => {
        try {
          const { data } = await axios.get(
            `${apiEndpoint}/api/v1/bussines/landplotpoint?code=${code}&sortby=id&sortdir=asc&page=${page}&size=150`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )

          const newData = data?.items || []
          plottingPoints.push(...newData)

          if (data?.currentPage < data?.totalPages && data?.totalPages > 1) {
            await fetchPlottingData(code, page + 1)
          }
        } catch (error) {
          console.error('Error fetching plotting data:', error)
        }
      }

      await fetchPlottingData(code, page)

      return {
        ...item,
        plottingPoints,
      }
    } catch (error) {
      console.error('Error fetching data for item:', error.response.data)
      return item
    }
  }

  const flattenData = (data) => {
    const result = []
    data.forEach((farmer) => {
      if (farmer.plots && farmer.plots.length > 0) {
        farmer.plots.forEach((plot) => {
          result.push({
            userId: farmer.userId,
            name: farmer.name,
            phoneNumber: farmer.phoneNumber,
            status: farmer.status,
            code: plot.code,
            userLevel: farmer.userLevel,
            locationPoint: plot.locationPoint,
            landName: plot.landName,
            businessName: plot.businessName,
            location: plot.location,
            createdAt: plot.createdAt,
            startRecordTime: plot.startRecordTime,
            endRecordTime: plot.endRecordTime,
            size: plot.size,
          })
        })
      }
    })
    return result
  }

  const handleToggleOpen = (userId) => {
    setFarmerList((prevFarmerList) =>
      prevFarmerList.map((el, i) =>
        el?.userId === userId ? { ...el, open: !el.open } : el,
      ),
    )
  }

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible)
  }

  return (
    <div style={{ display: 'flex', height: '100vh', width: '100%' }}>
      <div
        style={{
          width: '100%',
          transition: 'width 0.1s ease',
          display: 'flex',
        }}
      >
        <div>
          <SideBar />
        </div>
        {loadingPlotting || isLoading ? <LoadingCenter /> : null}
        {plottingPoints?.length ? (
          <div
            style={{
              height: '100%',
              width: '100%',
              opacity: loadingPlotting ? 0.5 : 1,
            }}
          >
            <Map
              data={plottingPoints}
              center={defaultCenterMap}
              multiple={isMultiple}
              onClickMarker={(item) => {
                setPlottingPoints([])
                getPlottingPoint(item?.code, 0)
              }}
            />
          </div>
        ) : (
          <Map data={[]} center={defaultCenterMap} />
        )}
      </div>
    </div>
  )

  function SideBar() {
    return (
      <div>
        {isSidebarVisible ? (
          <div className="sidebar">
            {farmerList?.map((el, i) => {
              return (
                <div key={el?.code + i} className="farmer-card">
                  <div
                    className="title-navbar button"
                    onClick={() => handleToggleOpen(el?.userId)}
                  >
                    <div>
                      <User size={15} />
                      <small className="text-secondary fw-semibold ml-small">
                        {el?.name}
                      </small>
                    </div>
                    {el?.open ? (
                      <ChevronUp size={15} />
                    ) : (
                      <ChevronDown size={15} />
                    )}
                  </div>
                  {el?.plots?.length && el.open ? (
                    <div className="mt-3">
                      {el?.plots?.map((item, index) => {
                        return (
                          <div
                            className="plots-wrap mt-2 button"
                            key={item?.code}
                            onClick={() => {
                              setPlottingPoints([])
                              getPlottingPoint(item?.code, 0)
                              if (isMobile) {
                                setSidebarVisible(false)
                              }
                            }}
                          >
                            <small
                              className={
                                selectedCode === item?.code
                                  ? 'text-primary'
                                  : 'text-secondary'
                              }
                            >
                              {item?.landName}
                            </small>
                          </div>
                        )
                      })}
                    </div>
                  ) : el.open ? (
                    <div className="mt-3">
                      <small style={{ fontSize: 14, color: 'lightgrey' }}>
                        Lokasi tidak ditemukan
                      </small>
                    </div>
                  ) : null}
                </div>
              )
            })}
          </div>
        ) : null}

        <div className="container-refresh">
          <div
            className="refresh-button button"
            onClick={() => {
              setPlottingPoints([])
              combinedData()
            }}
          >
            <RefreshCw />
          </div>
          <div className="menu-button button" onClick={toggleSidebar}>
            <Menu />
          </div>
        </div>
      </div>
    )
  }
}
