import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiEndpoint } from 'configs'

const checkSellerInfo = createAsyncThunk(
  'cekSellerInfo/commodity',
  async ({ search, type }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/transaction/checkuser?search=${search}&type=${type}`,
        config,
      )
      let finalData = {
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        profileImg: data.profileImg,
        profileStatus: data.profileStatus ?? data.status,
        userId: data.userId,
      }

      return finalData
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const createOrder = createAsyncThunk(
  'sell/create/order/commodity',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      let result = {
        ...payload,
        item: payload.item?.map((i) => ({
          orderItemId: i.orderItemId,
          SKU: i.SKU,
          amount: i.amount,
          imageUrl: i.imageUrl,
          nameItem: i.nameItem,
          price: i.price,
          totalPrice: i.totalPrice,
          unit: i.unit.value,
        })),
      }

      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/transaction/sell`,
        result,
        config,
      )

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const uploadFile = createAsyncThunk(
  'sell/create/order/upload',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const formData = new FormData()
      formData.append('file', payload)

      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/upload`,
        formData,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getComodities = createAsyncThunk(
  'purchase/commodity/comodities',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/items?sortby=nameItem&sortdir=asc`,
        config,
      )
      if (data?.totalItems > data?.items.length) {
        const res = await axios.get(
          `${apiEndpoint}/api/v1/bussines/items?sortby=nameItem&sortdir=asc&size=${data?.totalItems}`,
          config,
        )
        const newData = res?.data?.items.map((e) => {
          return {
            value: e.id,
            label: e.nameItem,
            id: e.id,
            img: e.linkFoto,
            sku: e.SKU,
          }
        })
        return newData
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

// Export the functions
export { checkSellerInfo, createOrder, uploadFile, getComodities }
