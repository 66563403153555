import { createAppSlice } from 'app/createAppSlice'
import {
  createOrderStaff,
  getComodities,
  purchaseCommodityCheckUser,
} from './purchaseCommodityAction'

const variableState = {
  STEP: 'stepPO',
  ORDER_TYPE: 'orderType',
  SELLER: 'seller',
}

const initialState = {
  addItemCount: [1],
  isOpenModal: false,
  orders: {
    isLoading: false,
    step: parseInt(localStorage.getItem(variableState.STEP)) ?? 0,
    userId: null,
    item: [
      {
        orderItemId: 1,
        SKU: '',
        amount: '',
        imageUrl: '',
        nameItem: '',
        price: '',
        totalPrice: '',
        unit: {
          label: 'Kg',
          value: 'Kg',
        },
      },
    ],
    order: {
      type: localStorage.getItem(variableState.ORDER_TYPE) ?? null,
      notes: '',
    },
    buyer: null,
    seller: null,
  },
  comodities: [],
}

export const purchaseCommoditySlice = createAppSlice({
  name: 'purchase/commodity',
  initialState,
  reducers: (create) => ({
    addItem: create.reducer((state) => {
      let count = state.addItemCount[state.addItemCount.length - 1] + 1

      state.orders.item = [
        ...state.orders.item,
        {
          orderItemId: count,
          SKU: '',
          amount: '',
          imageUrl: '',
          nameItem: '',
          price: '',
          totalPrice: '',
          unit: '',
        },
      ]

      state.addItemCount.push(count)
    }),
    removeItem: create.reducer((state, { payload }) => {
      const filteredArr = state.addItemCount.filter((item) => item !== payload)

      state.orders.item = state.orders.item.filter(
        (item) => item.orderItemId !== payload,
      )
      state.addItemCount = filteredArr
    }),
    toggleModal: create.reducer((state) => {
      state.isOpenModal = !state.isOpenModal
    }),
    stepOrder: create.reducer((state, { payload }) => {
      state.orders.order.type = payload.type
      state.orders.step = payload.step

      localStorage.setItem(variableState.STEP, payload.step)
      localStorage.setItem(variableState.ORDER_TYPE, payload.type)
    }),
    setOrdersPayload: create.reducer((state, { payload }) => {
      state.orders.item = payload
    }),
    setOrdersNote: create.reducer((state, { payload }) => {
      state.orders.order.notes = payload ?? ''
    }),
    setOrderBuyer: create.reducer((state, { payload }) => {
      state.orders.buyer = payload
      state.orders.userId = payload.userId
    }),
    setInitStatePurchase: create.reducer((state) => {
      state.addItemCount = [1]
      state.isOpenModal = false
      state.orders = {
        ...state.orders,
        isLoading: false,
        step: parseInt(localStorage.getItem(variableState.STEP)) ?? 0,
        userId: null,
        order: {
          type: localStorage.getItem(variableState.ORDER_TYPE) ?? null,
        },
        buyer: null,
        seller: null,
        notes: '',
      }
    }),
    setNewSeller: create.reducer((state, { payload }) => {
      let data = {
        fullName: payload.fullName,
        phoneNumber: payload.phoneNumber,
        bankCode: payload?.bankData?.code,
        bankName: payload?.bankData?.value,
        bankAccountName: payload.bankAccountName,
        bankAccountNumber: payload.bankAccountNumber,
        bankLogo: payload?.bankData?.logo,
        profileStatus: 'Belum Terverifikasi',
      }
      Object.assign(state.orders.seller, data)
    }),
    resetErrorMsg: create.reducer((state) => {
      state.errorMessage = ''
    }),
  }),
  extraReducers: (builder) => {
    builder
      .addCase(purchaseCommodityCheckUser.pending, (state) => {
        state.orders.isLoading = true
      })
      .addCase(purchaseCommodityCheckUser.fulfilled, (state, { payload }) => {
        state.orders.isLoading = false

        let data = {
          fullName: payload.fullName,
          phoneNumber: payload.phoneNumber,
          bankCode: payload.bankCode,
          bankName: payload.bankName,
          bankAccountName: payload.bankAccountName,
          bankAccountNumber: payload.bankAccountNumber,
          bankLogo: payload.bankLogo,
          isLogin: payload.isLogin ?? false,
          profileImg: payload.profileImg,
          profileStatus: payload.profileStatus ?? payload.status,
          userId: payload.userId,
        }
        state.orders.seller = data
      })
      .addCase(purchaseCommodityCheckUser.rejected, (state, { payload }) => {
        state.orders.isLoading = false
        state.errorMessage = payload
      })

      .addCase(createOrderStaff.pending, (state) => {
        state.orders.isLoading = true
      })
      .addCase(createOrderStaff.fulfilled, (state, { payload }) => {
        state.orders.isLoading = false
      })
      .addCase(createOrderStaff.rejected, (state, { payload }) => {
        state.orders.isLoading = false
      })
      .addCase(getComodities.fulfilled, (state, { payload }) => {
        state.comodities = payload
      })
  },
})

export const {
  addItem,
  removeItem,
  toggleModal,
  stepOrder,
  setOrdersPayload,
  setOrdersNote,
  setOrderBuyer,
  setInitStatePurchase,
  setNewSeller,
  resetErrorMsg,
} = purchaseCommoditySlice.actions

export default purchaseCommoditySlice.reducer
