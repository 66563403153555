import axios from 'axios'
import imageCompression from 'browser-image-compression'
import { apiEndpoint } from 'configs'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const usePartialUpload = () => {
  const [chunkSize] = useState(65536 / 2)
  const [maxFileSize] = useState(2 * 1024 * 1024)

  const { token } = useSelector((e) => e.auth)

  const getFileSize = async (file) => {
    return file.size
  }

  const getChunks = async (file) => {
    const totalChunks = Math.ceil(file.size / chunkSize)
    const chunks = []
    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize
      const end = Math.min(file.size, start + chunkSize)
      const chunk = file.slice(start, end)
      const base64Chunk = await fileToBase64(chunk)
      chunks.push(base64Chunk)
    }
    return chunks
  }

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result.split(',')[1])
      reader.onerror = (error) => reject(error)
    })
  }

  const uploadChunk = async ({ params, data, headers }) => {
    const response = await axios.post(
      apiEndpoint + '/api/v2/upload/public',
      data,
      {
        params,
        headers,
      },
    )
    return response.data
  }

  const compressFile = async (file) => {
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(file, options)
      return compressedFile
    } catch (error) {
      console.log(error)
      return file
    }
  }

  const uploadFile = async (file, cbPercentage, isPublic = false) => {
    cbPercentage(0)
    const fileName = file.name

    const originalFileSize = await getFileSize(file)

    let fileToUpload = file
    if (originalFileSize > maxFileSize) {
      fileToUpload = await compressFile(file)
    }

    const chunkData = await getChunks(fileToUpload)

    const headers = {
      'Content-Type': 'application/octet-stream',
      Authorization: `Bearer ${token}`,
    }

    let idUpload = ''
    let expectedChunkIndex = 0
    let result = ''
    let shouldRetry = false
    let retriedCount = 0

    do {
      const source = axios.CancelToken.source()
      const timeout = setTimeout(() => {
        source.cancel()
        shouldRetry = true
      }, 8000)

      try {
        const params = {
          name: fileName,
          size: fileToUpload.size,
          totalChunks: chunkData.length,
          idUpload,
          currentChunkIndex: expectedChunkIndex,
        }

        const data = `data:application/octet-stream;base64,${chunkData[expectedChunkIndex]}`

        const response = await uploadChunk({
          params,
          data,
          headers,
          cancelToken: source.token,
        })

        if (shouldRetry && retriedCount < 3) {
          shouldRetry = false
          retriedCount++
          continue
        }

        clearTimeout(timeout)
        result = response
        expectedChunkIndex =
          response?.expectedChunk >= 0 ? response?.expectedChunk : -1

        if (response.expectedChunk) {
          cbPercentage(
            Math.floor((response.expectedChunk / chunkData.length) * 100),
          )
        }
        if (response.id) {
          idUpload = response.id
        }
        if (response.url) {
          cbPercentage(100)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          throw error
        }
      }
    } while (expectedChunkIndex >= 0)

    return result
  }

  return { uploadFile }
}

export default usePartialUpload
