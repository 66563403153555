import {
  Box,
  CheckCircle,
  FileText,
  Home,
  Layers,
  Map,
  Repeat,
  Users,
} from 'react-feather'
import {
  ARUS_KAS,
  URL_APPROVAL_BUY,
  URL_ARUS_KAS,
  URL_FARMERS,
  URL_MANAGE_STOCK,
  URL_PIUTANG,
  URL_SPATIAL,
  URL_TNC_PRIVATE,
  URL_UTANG,
} from 'utils/pathUrl'
import {
  BUSINESS_AUTOREPO,
  BUSINESS_FARMERS,
  BUSINESS_MYAPPROVAL,
  BUSINESS_TALANGIN,
  BUSINESS_TOPUP,
  BUSINESS_TRANSACTION_PURCHASE,
  BUSINESS_TRANSACTION_SELL,
  BUSINESS_WITHDRAWAL,
  MANAGE,
  READ,
} from 'utils/subjectActions'
import switchVerticalIcon from 'assets/icons/switch-vertical.svg'
import arusKasIcon from 'assets/icons/arus-kas.svg'
import piutangIcon from 'assets/icons/piutang.svg'
import utangIcon from 'assets/icons/utang.svg'

export const menus = [
  {
    headTitle: 'TRANSAKSI',
    action: READ,
    subject:
      BUSINESS_TALANGIN ||
      BUSINESS_TOPUP ||
      BUSINESS_TRANSACTION_PURCHASE ||
      BUSINESS_TRANSACTION_SELL ||
      BUSINESS_WITHDRAWAL ||
      BUSINESS_FARMERS ||
      BUSINESS_MYAPPROVAL ||
      BUSINESS_AUTOREPO,
    items: [
      {
        title: 'Beranda',
        icon: <Home size={22} id="beranda" />,
        path: '/',
        action: READ,
        subject:
          BUSINESS_TALANGIN ||
          BUSINESS_TOPUP ||
          BUSINESS_TRANSACTION_PURCHASE ||
          BUSINESS_TRANSACTION_SELL ||
          BUSINESS_WITHDRAWAL ||
          BUSINESS_FARMERS,
      },
      {
        title: 'Persetujuan',
        icon: <CheckCircle size={22} id="persetujuan" />,
        path: URL_APPROVAL_BUY,
        action: READ,
        subject: BUSINESS_MYAPPROVAL,
      },
      {
        title: 'Repo Komoditas',
        icon: <img src={switchVerticalIcon} id="repo-komoditas" alt="Repo" />,
        path: '/repo-komoditas',
        action: MANAGE,
        subject: BUSINESS_AUTOREPO,
      },
      {
        title: 'Atur Stok',
        icon: <Box size={22} id="atur-stok" />,
        path: URL_MANAGE_STOCK,
        action: MANAGE,
        subject:
          BUSINESS_TALANGIN ||
          BUSINESS_TOPUP ||
          BUSINESS_TRANSACTION_PURCHASE ||
          BUSINESS_TRANSACTION_SELL ||
          BUSINESS_WITHDRAWAL ||
          BUSINESS_FARMERS,
      },
    ],
  },
  {
    headTitle: 'CATATAN',
    action: READ,
    subject:
      BUSINESS_TALANGIN ||
      BUSINESS_TOPUP ||
      BUSINESS_TRANSACTION_PURCHASE ||
      BUSINESS_TRANSACTION_SELL ||
      BUSINESS_WITHDRAWAL ||
      BUSINESS_FARMERS ||
      BUSINESS_MYAPPROVAL ||
      BUSINESS_AUTOREPO,
    items: [
      {
        title: 'Arus Kas',
        icon: <img src={arusKasIcon} id="arus-kas" alt="Arus Kas" />,
        path: ARUS_KAS,
        action: READ,
        subject:
          BUSINESS_TALANGIN ||
          BUSINESS_TOPUP ||
          BUSINESS_TRANSACTION_PURCHASE ||
          BUSINESS_TRANSACTION_SELL ||
          BUSINESS_WITHDRAWAL ||
          BUSINESS_FARMERS,
      },
      {
        title: 'Utang',
        icon: <img src={utangIcon} id="utang" alt="Utang" />,
        path: URL_UTANG,
        action: READ,
        subject:
          BUSINESS_TALANGIN ||
          BUSINESS_TOPUP ||
          BUSINESS_TRANSACTION_PURCHASE ||
          BUSINESS_TRANSACTION_SELL ||
          BUSINESS_WITHDRAWAL ||
          BUSINESS_FARMERS,
      },
      {
        title: 'Piutang',
        icon: <img src={piutangIcon} id="piutang" alt="Piutang" />,
        path: URL_PIUTANG,
        action: READ,
        subject:
          BUSINESS_TALANGIN ||
          BUSINESS_TOPUP ||
          BUSINESS_TRANSACTION_PURCHASE ||
          BUSINESS_TRANSACTION_SELL ||
          BUSINESS_WITHDRAWAL ||
          BUSINESS_FARMERS,
      },
    ],
  },
  {
    headTitle: 'MANAJEMEN ANGGOTA',
    action: MANAGE,
    subject: BUSINESS_FARMERS,
    items: [
      {
        title: 'Daftar Anggota',
        icon: <Users size={22} id="daftar-anggota" />,
        path: URL_FARMERS,
        action: MANAGE,
        subject: BUSINESS_FARMERS,
      },
      {
        title: 'Persebaran Anggota',
        icon: <Map size={22} id="persebaran-anggota" />,
        path: URL_SPATIAL,
        action: MANAGE,
        subject: BUSINESS_FARMERS,
      },
    ],
  },
]
