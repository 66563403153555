// Path URL ERROR
export const URL_406 = '/unauthorized'

// Path URL PUBLIC
export const URL_FORGET_PASSWORD_STEP_1 = '/resetpassword'
export const URL_FORGET_PASSWORD_STEP_2 = '/resetpassword/email'
export const URL_FORGET_PASSWORD_STEP_4 = '/resetpassword/success'

export const URL_LOGIN = '/login'

// Path URL Private
export const HOME = '/'

// Path URL APPROVAL
export const URL_APPROVAL_BUY = 'approval-saya'
export const URL_APPROVAL_SELL = 'sell'
export const URL_APPROVAL_WITHDRAWAL = 'withdrawal'

// Path Url Farmers
export const URL_FARMERS = '/farmers'
export const URL_FARMERS_REGIST = '/farmers/registrasi'
export const URL_FARMERS_DETAIL = '/farmers/:id'
export const URL_SPATIAL = '/spatial'
export const URL_MANAGE_STOCK = '/manage-stock'
export const URL_CHECK_FARMERS = '/farmers/check'
export const URL_FARMERS_PENDING = '/farmers/pending'

// pathname repo-komoditas
export const REPO_KOMODITAS = '/repo-komoditas'
export const REPO_KOMODITAS_LIST = '/repo-komoditas/list'

//arus kas
export const ARUS_KAS = '/catatan-arus-kas'
export const ARUS_KAS_FORM = '/catatan-arus-kas-form'

//Path Url Catatan
export const URL_ARUS_KAS = '/catatan-arus-kas'
export const URL_UTANG = '/catatan-utang'
export const URL_UTANG_APPROVAL = '/catatan-utang-approval'
export const URL_FORM_UTANG_PIUTANG = '/catatan-utang-piutang/form'
export const URL_PIUTANG = '/catatan-piutang'

//url connect account
export const URL_CONNECT_ACCOUNT = '/sambungkan-akun'

//url tnc private
export const URL_TNC_PRIVATE = '/snk'
