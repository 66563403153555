import LandPlotView from 'features/public-views/plot'
import { Modal, ModalBody } from 'reactstrap'

const ModalLandPlot = ({ row, isOpen, toggle }) => {
  return (
    <Modal centered size="lg" toggle={toggle} isOpen={isOpen}>
      <ModalBody>
        <h6>{row?.landName}</h6>
        <LandPlotView
          code={row?.code}
          style={{ width: '100%', height: '60vh', display: 'flex' }}
        />
      </ModalBody>
    </Modal>
  )
}

export default ModalLandPlot
