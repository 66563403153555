import { useIntl } from 'react-intl'
import { Input } from 'reactstrap'
import { formatForFloatInput } from 'utils/formatForFloatInput'

const InputJumlah = ({ id, name, value, setValue }) => {
  const intl = useIntl()

  const handleChange = (e) => {
    let input = e.target.value.trim()
    const decimalSymbol =
      intl
        .formatNumber(1.1, {
          useGrouping: false,
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })
        .indexOf('.') > 0
        ? '.'
        : ','

    const formattedValue = (num) => {
      return new Intl.NumberFormat('id-ID').format(num)
    }

    let value = formatForFloatInput(input, decimalSymbol)

    const parts = value.split(',')
    if (parts[0] && parts[0].length > 0) parts[0] = formattedValue(parts[0])
    if (parts[1] && parts[1].length > 2) parts[1] = parts[1].slice(0, 2)

    value = parts.join(',')

    setValue(value)
  }

  return (
    <Input
      type="text"
      id={id}
      name={name}
      value={value}
      onChange={handleChange}
      placeholder="Masukan jumlah"
    />
  )
}

export default InputJumlah
