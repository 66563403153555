import { createApiSlice } from 'app/createApiSlice'

export const payableNotesApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'catatan/utang',

  endpoints(builder) {
    return {
      postPayable: builder.mutation({
        query(payload) {
          return {
            url: `/api/v1/bussines/payable/manual`,
            method: 'POST',
            body: payload,
          }
        },
      }),
      postPayPayable: builder.mutation({
        query({ payableId, manual, amountPaid, notes, receivableName }) {
          return {
            url: `/api/v1/bussines/payable/pay-manual`,
            method: 'POST',
            body: {
              payableId,
              amount: amountPaid,
              notes,
              manual,
              receivableName,
            },
          }
        },
      }),
      postCategory: builder.mutation({
        query({ label, type }) {
          return {
            url: `/api/v1/bussines/payable/category`,
            method: 'POST',
            body: {
              label,
              incomeorexpense: type,
            },
          }
        },
      }),
      deleteCategory: builder.mutation({
        query({ id }) {
          return {
            url: `/api/v1/bussines/payable-category`,
            method: 'DELETE',
            params: { id },
          }
        },
      }),
      editCategory: builder.mutation({
        query({ id, label }) {
          return {
            url: `/api/v1/bussines/payable-category`,
            method: 'PATCH',
            params: { id },
            body: {
              label,
            },
          }
        },
      }),
      getPayable: builder.query({
        query({ type }) {
          return {
            url: `/api/v1/bussines/payable`,
            params: { type },
          }
        },
      }),
      getCategories: builder.query({
        query({ active, userId, type }) {
          return {
            url: `/api/v1/bussines/payable-category`,
            params: { type, active, userId },
          }
        },
      }),
      getPayableDetail: builder.query({
        query({ id }) {
          return {
            url: `/api/v1/bussines/payable/${id}`,
          }
        },
      }),
      checkPhoneNumberPayable: builder.query({
        query({ search, type }) {
          return {
            url: '/api/v1/bussines/transaction/checkuser',
            params: {
              search,
              type,
            },
          }
        },
      }),
      getRelations: builder.query({
        query({ userId, s }) {
          return {
            url: `/api/v1/bussines/payable/relations`,
            params: {
              userId,
              s,
            },
          }
        },
      }),
      deleteRelation: builder.mutation({
        query({ id }) {
          return {
            url: `/api/v1/bussines/payable/relations/${id}`,
            method: 'DELETE',
          }
        },
      }),
      editRelation: builder.mutation({
        query({ relationName, relationPhoneNumber, id }) {
          return {
            url: `/api/v1/bussines/payable/relations/${id}`,
            method: 'PATCH',
            body: {
              relationName,
              relationPhoneNumber,
            },
          }
        },
      }),
      getPendingPayable: builder.query({
        query() {
          return {
            url: `/api/v1/bussines/payable/pending`,
          }
        },
      }),
      approvePending: builder.mutation({
        query({ id }) {
          return {
            url: `/api/v1/bussines/payable/accept/${id}`,
            method: 'POST',
          }
        },
      }),
      rejectPending: builder.mutation({
        query({ id }) {
          return {
            url: `api/v1/bussines/payable/reject/${id}`,
            method: 'POST',
          }
        },
      }),
    }
  },
})

export const {
  usePostPayableMutation,
  usePostPayPayableMutation,
  useEditCategoryMutation,
  usePostCategoryMutation,
  useDeleteCategoryMutation,
  useGetPayableQuery,
  useGetCategoriesQuery,
  useGetPayableDetailQuery,
  useLazyGetPayableQuery,
  useLazyGetCategoriesQuery,
  useCheckPhoneNumberPayableQuery,
  useLazyCheckPhoneNumberPayableQuery,
  useGetRelationsQuery,
  useLazyGetRelationsQuery,
  useDeleteRelationMutation,
  useEditRelationMutation,
  useGetPendingPayableQuery,
  useApprovePendingMutation,
  useRejectPendingMutation,
} = payableNotesApiSlice
