import { Input, InputGroup, InputGroupText } from 'reactstrap'

const InputCurrency = ({ id, name, value = '0', setValue }) => {
  const handleChange = (e) => {
    let inputValue = e.target.value.trim()
    let newValue = inputValue === '' ? '0' : inputValue
    newValue = newValue.replace(/[^\d]/g, '')

    const formattedValue = () => {
      return new Intl.NumberFormat('id-ID').format(newValue)
    }

    if (parseInt(newValue) < Number.MAX_SAFE_INTEGER) {
      setValue(formattedValue())
    }
  }

  return (
    <InputGroup>
      <InputGroupText
        style={{
          backgroundColor: 'transparent',
          borderRightColor: 'transparent',
        }}
        className="text-sm"
      >
        Rp
      </InputGroupText>
      <Input
        type="text"
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        style={{ borderLeftColor: 'transparent' }}
      />
    </InputGroup>
  )
}

export default InputCurrency
