import { createAppSlice } from 'app/createAppSlice'
import {
  pendingBca,
  pendingBcaVa,
  pendingBri,
  pendingDurianPayDisbursement,
} from './topupAction'

const initialState = {
  instructionsBca: [
    {
      title: 'ATM BCA',
      content: [
        'Masukkan kartu ATM dan PIN BCA kamu.',
        'Pilih menu "Transaksi Lainnya".',
        'Pilih menu "Transfer".',
        'Pilih "Ke Rek BCA".',
        'Masukkan nomor rekening tujuan.',
        'Masukkan nominal transfer.',
        'Konfirmasi transfer.',
      ],
    },
    {
      title: 'BCA Mobile',
      content: [
        'Login ke akun BCA mobile kamu.',
        'Pilih menu "m-Transfer".',
        'Pilih "Transfer Antar Rekening"',
        `Jika belum terdaftar, silakan daftarkan nomor rekeningnya terlebih dahulu.`,
        'Masukkan nominal transfer.',
        'Klik "Lanjut" dan selesaikan transfer.',
      ],
    },
    {
      title: 'myBCA',
      content: [
        'Login ke akun myBCA kamu.',
        'Pilih menu "Transaksi".',
        'Pilih submenu "Transfer".',
        'Masukkan nomor rekening tujuan.',
        'Masukkan nominal transfer.',
        'Klik "Lanjut".',
        'Pastikan data transfer sudah benar, lalu pilih "Ya" untuk memverifikasi transaksi.',
        'Tunggu notifikasi transaksi berhasil.',
      ],
    },
    {
      title: 'KlikBCA',
      content: [
        'Kunjungi website ini KlikBCA.',
        'Login ke akun KlikBCA kamu.',
        'Pilih menu "Transfer Dana".',
        'Pilih "Transfer ke Rekening BCA".',
        'Pilih nomor rekening dari daftar, daftarkan jika belum.',
        'Masukkan nominal transfer.',
        'Aktifkan KeyBCA, masukkan pin dan tekan angka 2.',
        'Masukkan 8 digit dari Respon KeyBCA APPLI 2.',
        'Klik Lanjutkan.',
        'Tunggu informasi rekening tujuan.',
        'Nyalakan KeyBCA kembali.',
        'Klik angka 1.',
        'Tunggu 8 digit di kotak Respon KeyBCA APPLI 1.',
        'Pilih "Kirim".',
      ],
    },
  ],
  instructionsBri: [
    {
      title: 'ATM BRI',
      content: [
        'Masukkan Kartu Debit Anda ke dalam mesin ATM.',
        'Pilih Bahasa. ',
        'Masukkan PIN. ',
        'PIlih "TRANSAKSI LAIN". ',
        'Pilih "PEMBAYARAN". ',
        'Pilih "LAINNYA". ',
        'Pilih "BRIVA". ',
        'Masukkan nomor Virtual Account Anda (10441 + No Handphone Anda, contoh: 104410812*****), kemudian tekan "BENAR". ',
        'Masukkan jumlah top up yang diinginkan. ',
        'Muncul layar konfirmasi transfer yang berisi\n* NO. BRIVA\n* INSTANSI\n* NAMA\n* KETERANGAN\n* JML PEMBAYARAN\n* PEMBAYARAN\nJika sudah benar, tekan "YA". ',
        'Transaksi sudah selesai, silakan melakukan konfirmasi di aplikasi',
      ],
    },
    {
      title: 'Mobile Banking BRI',
      content: [
        'Masukkan ke dalam Aplikasi BRI Mobile & Pilih Mobile Banking BRI.',
        'Pilih menu "Info".',
        'Pilih menu "Info BRIVA".',
        'Masukkan nomor BRIVA Anda (10441 + No Handphone Anda, contoh: 104410812*****), kemudian tekan "BENAR".',
        'Masukkan jumlah top up yang diinginkan',
        'Masukkan PIN.',
        'Transaksi sudah selesai, silakan melakukan konfirmasi di aplikasi',
      ],
    },
    {
      title: 'Internet Banking BRI',
      content: [
        'Login pada alamat Internet Banking BRI.',
        'Pilih menu "PEMBAYARAN & PEMBELIAN".',
        'Pilih menu "BRIVA".',
        'Isi kode bayar dengan nomor Virtual Account Anda (10441 + No Handphone Anda, contoh: 104410812*****), kemudian tekan "Kirim".',
        'Masukkan jumlah top up yang diinginkan.',
        'Muncul layar konfirmasi pembayaran BRIVA\nJika sudah benar, masukkan Password dan mToken',
        'Transaksi sudah selesai, silakan melakukan konfirmasi di aplikasi',
      ],
    },
    {
      title: 'Mini ATM BRI',
      content: [
        'Pilih menu "Mini ATM".',
        'Pilih menu "Pembayaran".',
        'Pilih menu "BRIVA".',
        'Swipe kartu ATM.',
        'Masukkan nomor BRIVA Anda (10441 + No Handphone Anda, contoh: 104410812*****).',
        'Masukkan PIN ATM.',
        'Muncul konfirmasi pembayaran.',
        'Transaksi sudah selesai, silakan melakukan konfirmasi di aplikasi',
      ],
    },
    {
      title: 'ATM Bank Lain',
      content: [
        'Masukkan kartu debit Anda ke dalam mesin ATM.',
        'Masukkan PIN.',
        'Pilih menu "TRANSAKSI LAINNYA"',
        'Pilih menu "TRANSFER".',
        'Pilih menu "KE REK BANK LAIN".',
        'Masukkan kode Bank BRI (002) kemudian tekan "BENAR".',
        'Masukkan jumlah topup yang diinginkan.',
        'Masukkan nomor BRIVA Anda (10441 + No Handphone Anda, contoh: 104410812*****).',
        'Muncul layar konfirmasi transfer yang berisi:\n* Nomor Virtual Account Anda\n* Nama Anda\n* Jumlah yang akan ditopup\nJika sudah benar, tekan "BENAR".',
        'Transaksi sudah selesai, silakan melakukan konfirmasi di aplikasi',
      ],
    },
  ],
  isOpenModalPending: false,
  pendingBcaData: null,
  pendingBriData: null,
  pendingBcaVaData: null,
  pendingDurianPay: null,
  isLoading: false,
}

export const topupSlice = createAppSlice({
  name: 'topup/slice',
  initialState,
  reducers: (create) => ({
    toggleModalPending: create.reducer((state, { payload }) => {
      state.isOpenModalPending = !state.isOpenModalPending
    }),
    setInitStateTopup: create.reducer((state, { payload }) => {
      state.pendingBcaData = null
      state.pendingBriData = null
      state.pendingBcaVaData = null
      state.pendingDurianPay = null
    }),
  }),
  extraReducers: (builder) => {
    builder
      .addCase(pendingBca.pending, (state) => {
        state.isLoading = true
      })
      .addCase(pendingBca.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.pendingBcaData = payload
      })
      .addCase(pendingBca.rejected, (state, { payload }) => {
        state.isLoading = false
      })
      .addCase(pendingBri.pending, (state) => {
        state.isLoading = true
      })
      .addCase(pendingBri.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.pendingBriData = payload
      })
      .addCase(pendingBri.rejected, (state, { payload }) => {
        state.isLoading = false
      })
      .addCase(pendingBcaVa.pending, (state) => {
        state.isLoading = true
      })
      .addCase(pendingBcaVa.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.pendingBcaVaData = payload
      })
      .addCase(pendingBcaVa.rejected, (state, { payload }) => {
        state.isLoading = false
      })
      .addCase(pendingDurianPayDisbursement.pending, (state) => {
        state.isLoading = true
      })
      .addCase(pendingDurianPayDisbursement.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.pendingDurianPay = payload
      })
      .addCase(pendingDurianPayDisbursement.rejected, (state, { payload }) => {
        state.isLoading = false
      })
  },
})

export const { toggleModalPending, setInitStateTopup } = topupSlice.actions

export default topupSlice.reducer
