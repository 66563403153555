import { formatNumber } from 'helpers'
import { useEffect, useState } from 'react'
import { Box, MapPin } from 'react-feather'
import { Button, Card, CardText } from 'reactstrap'
import SplitStockCard from '../Card/SplitStockCard'

export default function SplitStockModal({
  isOpen,
  onClose,
  selectedStock,
  splitItems,
  enableSplit,
  addSplitItem,
  onShowItem,
  setIndexSelectedSplitStock,
  onChangeValue,
  onSplitStock,
  loading,
}) {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)

  useEffect(() => {
    const checkAmounts = splitItems.every((item) => Number(item.amount) > 0)
    setIsButtonEnabled(checkAmounts)
  }, [splitItems])

  return (
    <>
      <CardText className="mt-2" style={{ fontSize: 14 }}>
        Stok berikut akan dipisah menjadi beberapa bagian
      </CardText>
      <Card>
        <div className="d-flex align-items-center regular-padding-horizontal mt-2 small-padding-bottom">
          <img
            src={selectedStock?.item?.linkFoto}
            style={{ width: 80, height: 80, borderRadius: 99 }}
          />
          <div className="pl-4">
            <CardText className="no-padding fw-semibold">
              {selectedStock?.item?.nameItem}
            </CardText>
            <div className="d-flex align-items-center mt-2">
              <Box size={15} />
              {selectedStock?.amount ? (
                <CardText
                  className="no-padding"
                  style={{ fontSize: 13, marginLeft: 10 }}
                >
                  {formatNumber(selectedStock?.amount)} {selectedStock?.unit}
                </CardText>
              ) : null}
            </div>
            {selectedStock?.city ||
            selectedStock?.district ||
            selectedStock?.province ? (
              <div className="d-flex align-items-center mt-2">
                <MapPin size={15} />
                <CardText className="pl-2" style={{ fontSize: 13 }}>
                  {selectedStock?.city ||
                    selectedStock?.district ||
                    selectedStock?.province}
                </CardText>
              </div>
            ) : null}
          </div>
        </div>
      </Card>

      <div className="regular-padding-horizontal regular-border-bottom mt-3" />
      <div className="scrollable-container">
        {splitItems?.map((el, i) => {
          return (
            <div className="mt-3">
              <SplitStockCard
                index={i}
                item={el}
                onShowItem={onShowItem}
                setIndexSelectedSplitStock={setIndexSelectedSplitStock}
                onChangeValue={onChangeValue}
              />
            </div>
          )
        })}
        <div className="regular-padding-top regular-padding-bottom d-flex">
          <Button
            disabled={!enableSplit}
            onClick={addSplitItem}
            outline
            className="fw-semibold"
            style={{ width: '100%', fontSize: 14, borderColor: '#D0D5DD' }}
          >
            Tambah Bagian
          </Button>
          <Button
            disabled={loading || !isButtonEnabled}
            onClick={onSplitStock}
            className="fw-semibold"
            style={{
              width: '100%',
              backgroundColor: '#006386',
              fontSize: 14,
              marginLeft: 10,
            }}
          >
            Selesai
          </Button>
        </div>
      </div>
    </>
  )
}
