import PropTypes from 'prop-types'
import { createContext } from 'react'
import { createMongoAbility } from '@casl/ability'
import { createContextualCan } from '@casl/react'
import { useSelector } from 'react-redux'
import { BUSINESS_AUTOREPO, MANAGE } from 'utils/subjectActions'

// ** Create Context
export const AbilityContext = createContext()

// ** Init Can Context
export const Can = createContextualCan(AbilityContext.Consumer)

export const AbilityProvider = ({ children }) => {
  const { abilityRole, serviceProvision } = useSelector((s) => s.auth)

  let customRole = []

  if (serviceProvision?.autorepo === true) {
    customRole.push({
      action: MANAGE,
      subject: BUSINESS_AUTOREPO,
    })
  }

  const mergeRole = [...abilityRole, ...customRole]

  return (
    <AbilityContext.Provider value={createMongoAbility(mergeRole)}>
      {children}
    </AbilityContext.Provider>
  )
}

AbilityProvider.propTypes = {
  children: PropTypes.node,
}
