import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiEndpoint } from 'configs'

export const getDetailCashFlow = createAsyncThunk(
  'catatan/arus-kas-detail',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/cashflow?id=${id}`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const getUnitList = createAsyncThunk(
  'catatan-arus-kas/unit',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/public/version`,
        config,
      )
      const newData = data?.unitList?.split(',')
      const newestData = newData.map((e) => ({
        value: e,
        label: e,
      }))
      return newestData
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const getComodities = createAsyncThunk(
  'catatan-arus-kas/comodity',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/items?sortby=nameItem&sortdir=asc`,
        config,
      )
      if (data?.totalItems > data?.items.length) {
        const res = await axios.get(
          `${apiEndpoint}/api/v1/bussines/items?sortby=nameItem&sortdir=asc&size=${data?.totalItems}`,
          config,
        )
        const newData = res?.data?.items.map((e) => {
          return {
            value: e.id,
            label: e.nameItem,
            id: e.id,
            img: e.linkFoto,
            sku: e.SKU,
          }
        })
        return newData
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
